import type { GetObjectByIdApiArg } from '@api/goose/dist/enhancedGooseClient';
import { getUrlConfig } from '@local/login/dist/util/getUrlConfig';
import { DISK_COLOR, DISK_DEFAULT_COLOR_BACK } from '@local/webviz/dist/context/snapshots';
import { generateEntity } from '@local/webviz/dist/context/snapshots/base';
import { ElementClass, toSuffixUid, ViewClass } from '@local/webviz/dist/xyz';
import type { UpdateSnapshot } from '@local/webviz/src/types';

import type { GeosciencePlanarDataPointsetObject } from 'src/visualization/types';
import { generateVisualizationServiceUrl } from 'src/visualization/utils/utils';

export function generatePlanarDataPointsetSnapshot(
    viewId: string,
    gooseObject: GeosciencePlanarDataPointsetObject,
    params: GetObjectByIdApiArg,
): UpdateSnapshot | undefined {
    const { object_id: objectId } = gooseObject;

    const elementId = toSuffixUid(objectId, ElementClass.Tileset3D);
    const { url: baseUrl } = getUrlConfig();
    const url = generateVisualizationServiceUrl(baseUrl, params);

    return {
        [elementId]: generateEntity(ElementClass.Tileset3D, {
            url,
        }),
        [viewId]: generateEntity(ViewClass.Structural, {
            id: viewId,
            element: elementId,
            color: DISK_COLOR,
            color_back: DISK_DEFAULT_COLOR_BACK,
        }),
    };
}

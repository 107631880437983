import type { GetObjectResponse } from '@api/goose/dist/enhancedGooseClient';
import Blocksync from '@local/web-design-system-2/dist/icons/Blocksync';
import Driver from '@local/web-design-system-2/dist/icons/Driver';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useParams } from 'react-router';

import { usePersistedState } from 'src/hooks/usePersistedState';
import { ID_PARAM, OPEN_IN_BLOCK_SYNC_ACTION, OPEN_IN_DRIVER_ACTION } from 'src/strings';
import {
    canOpenInBlockSync,
    canOpenInDriver,
    createNavigateToBlockSync,
    createNavigateToDriver,
} from 'src/utils/viewObjectUtils';

import { OpenInExternalAppButton } from './OpenInExternalAppButton';
import { OpenInExternalAppMenu } from './OpenInExternalAppMenu';

interface OpenInExternalAppOptionsProps {
    readonly objectDetail: GetObjectResponse;
}

export function OpenInExternalAppOptions({ objectDetail }: OpenInExternalAppOptionsProps) {
    const [id] = usePersistedState(ID_PARAM);

    const { hostname } = window.location;
    const { orgUuid, workspaceUuid, hubCode } = useParams();
    const featureFlags = useFlags();

    if (!id || !orgUuid || !workspaceUuid || !hubCode) {
        return null;
    }

    const MENU_OPTIONS = [
        {
            key: 'BlockSync',
            ItemIcon: Blocksync,
            action: createNavigateToBlockSync(
                hostname,
                orgUuid,
                workspaceUuid,
                hubCode,
                objectDetail.object.blocksync_uuid,
            ),
            shouldRender: canOpenInBlockSync(objectDetail, featureFlags),
            singleOpenLabel: OPEN_IN_BLOCK_SYNC_ACTION,
        },
        {
            key: 'Driver',
            ItemIcon: Driver,
            action: createNavigateToDriver(hostname, orgUuid, hubCode, workspaceUuid, id),
            shouldRender: canOpenInDriver(objectDetail.object.schema, featureFlags),
            singleOpenLabel: OPEN_IN_DRIVER_ACTION,
        },
    ];

    const options = MENU_OPTIONS.filter((option) => option.shouldRender);

    if (!options.length) {
        return null;
    }

    const [singleOption] = options.length === 1 ? options : [null];

    return singleOption ? (
        <OpenInExternalAppButton
            action={singleOption.action}
            label={singleOption.singleOpenLabel}
            ItemIcon={singleOption.ItemIcon}
        />
    ) : (
        <OpenInExternalAppMenu options={options} />
    );
}

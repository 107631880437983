import type { BreadcrumbSegment } from '@local/content-area/dist/Breadcrumbs/BreadcrumbsContext';
import { useBreadcrumbs } from '@local/content-area/dist/Breadcrumbs/BreadcrumbsContext';
import { BentleyGenericUserList } from '@local/user-manage/dist/components/GenericUserList/BentleyGenericUserList';
import { setDocumentTitle } from '@local/workspaces/dist/utils/setDocumentTitle';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';

import { PageContent } from 'src/components/pageContent/PageContent';
import { usersBreadcrumb } from 'src/utils/breadcrumbs';

import { ADMINISTRATORS_TITLE } from '../../strings';

export function ListUsersPage() {
    setDocumentTitle('Users');
    const navigate = useNavigate();

    const selectUser = (userUuid: string) => {
        navigate(`${userUuid}`);
    };

    const { setBreadcrumbs } = useBreadcrumbs();
    const segments: BreadcrumbSegment[] = [{ name: usersBreadcrumb.name }];
    useEffect(() => {
        setBreadcrumbs(segments);
    }, []);

    return (
        <PageContent pageTitle={ADMINISTRATORS_TITLE} enablePageTitle={false}>
            <BentleyGenericUserList service="evo" onClickRow={selectUser} addMessages />
        </PageContent>
    );
}

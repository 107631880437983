import throttle from 'lodash-es/throttle';
import { useCallback } from 'react';

import { getViewBounds } from '../Points/Clustering/Clustering';
import type { ViewBounds } from '../Points/Clustering/Clustering';
import { convertBoundingBox, SEARCH_BOUNDS } from '../Points/Utils/Bounds';

export function useUpdateBounds(
    cesiumWidget: any,
    setViewBounds: (bounds: ViewBounds | null) => void,
    setSearchBounds: (bounds: ViewBounds, restrict?: boolean) => void,
) {
    const throttledUpdateBounds = useCallback(
        throttle((isDrawing: boolean) => {
            const bounds = getViewBounds(cesiumWidget);
            if (!isDrawing && bounds) {
                setViewBounds(bounds);
                setSearchBounds(bounds as ViewBounds);
            } else if (!isDrawing) {
                setSearchBounds(convertBoundingBox(SEARCH_BOUNDS));
            }
        }, 200),
        [cesiumWidget, setViewBounds, setSearchBounds],
    );

    return throttledUpdateBounds;
}

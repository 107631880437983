import { ScreenSpaceEventHandler, ScreenSpaceEventType } from '@cesium/engine';
import { useEffect } from 'react';

export function useResetActiveObject(
    cesiumWidget: any,
    activeObject: any,
    setActiveObject: (object: any) => void,
    resetClusters: () => void,
    setHoveredBillboards: (billboards: any[]) => void,
    removeRectangleGeometries: () => void,
) {
    useEffect(() => {
        if (!cesiumWidget) return;

        if (!activeObject) {
            resetClusters();
            setHoveredBillboards([]);
            removeRectangleGeometries();
            return;
        }

        const handler = new ScreenSpaceEventHandler(cesiumWidget.scene.canvas);

        handler.setInputAction(() => {
            setActiveObject('');
            resetClusters();
            setHoveredBillboards([]);
            removeRectangleGeometries();
        }, ScreenSpaceEventType.LEFT_CLICK);

        // eslint-disable-next-line consistent-return
        return () => {
            handler.destroy();
        };
    }, [
        cesiumWidget,
        activeObject,
        setActiveObject,
        resetClusters,
        setHoveredBillboards,
        removeRectangleGeometries,
    ]);
}

import { Rectangle, type CesiumWidget, Math as CesiumMath } from '@cesium/engine';
import type { BreadcrumbSegment } from '@local/content-area/dist/Breadcrumbs/BreadcrumbsContext';
import { useBreadcrumbs } from '@local/content-area/dist/Breadcrumbs/BreadcrumbsContext';
import { CesiumMap } from '@local/map-viewer/dist/cesium/CesiumMap';
import { CesiumMapBoundingBox } from '@local/map-viewer/dist/cesium/CesiumMapBoundingBox/CesiumMapBoundingBox';
import { CesiumSearch } from '@local/map-viewer/dist/cesium/CesiumSearch/CesiumSearch';
import { setDocumentTitle } from '@local/workspaces/dist/utils/setDocumentTitle';
import { useEffect, useState } from 'react';

import { useDiscoverContext } from 'src/contexts/DiscoverContext';
import { searchBreadcrumb } from 'src/utils/breadcrumbs';

import { MetadataPanel } from './cesium/Menus/metadataPanel/MetadataPanel';
import { ResultsPanel } from './cesium/Menus/resultsPanel/ResultsPanel';
import { ObjectPoint } from './cesium/Points/ObjectPoint';
import { convertBoundingBox, SEARCH_BOUNDS } from './cesium/Points/Utils/Bounds';

export const CesiumSearchPage = () => {
    const [searchQuery, setSearchQuery] = useState('');
    const [isDrawing, setIsDrawing] = useState(false);
    const [flyToInProgress, setFlyToInProgress] = useState(false);
    const { setBreadcrumbs } = useBreadcrumbs();
    const { setSearchBounds } = useDiscoverContext();
    const segments: BreadcrumbSegment[] = [{ name: searchBreadcrumb.name }];
    useEffect(() => {
        setBreadcrumbs(segments);
    }, []);
    setDocumentTitle('Search');

    const ZOOM_FLY_DURATION = 0.5;

    return (
        <CesiumMap
            customComponents={(cesiumWidget: CesiumWidget) => [
                <>
                    <div style={{ position: 'absolute', top: 10 }}>
                        <div style={{ position: 'relative', zIndex: 1000 }}>
                            <CesiumSearch
                                query={searchQuery}
                                setQuery={setSearchQuery}
                                scene={cesiumWidget.scene}
                                camera={cesiumWidget.camera}
                                style={{ position: 'relative' }}
                            />
                            <div style={{ paddingLeft: 20 }}>
                                <ResultsPanel cesiumWidget={cesiumWidget} />
                            </div>
                            <CesiumMapBoundingBox
                                cesiumWidget={cesiumWidget}
                                enableEditAfterDraw={false}
                                boundingBoxCallbacks={{
                                    onDrawEnd: (boundingBox) => {
                                        setIsDrawing(true);
                                        setSearchBounds(convertBoundingBox(boundingBox));
                                        const adjustedBoundingBox = {
                                            minLat: boundingBox.minLat,
                                            minLng: boundingBox.minLng,
                                            maxLat: boundingBox.maxLat,
                                            maxLng: boundingBox.maxLng,
                                        };

                                        cesiumWidget.camera.flyTo({
                                            destination: Rectangle.fromDegrees(
                                                adjustedBoundingBox.minLng,
                                                adjustedBoundingBox.minLat,
                                                adjustedBoundingBox.maxLng,
                                                adjustedBoundingBox.maxLat,
                                            ),
                                            duration: ZOOM_FLY_DURATION,
                                        });
                                    },
                                    onClear: () => {
                                        setIsDrawing(false);

                                        const cameraBounds =
                                            cesiumWidget.camera.computeViewRectangle();
                                        if (cameraBounds) {
                                            const searchBounds = {
                                                minLat: CesiumMath.toDegrees(cameraBounds.south),
                                                minLng: CesiumMath.toDegrees(cameraBounds.west),
                                                maxLat: CesiumMath.toDegrees(cameraBounds.north),
                                                maxLng: CesiumMath.toDegrees(cameraBounds.east),
                                            };
                                            setSearchBounds(convertBoundingBox(searchBounds));
                                        } else {
                                            setSearchBounds(convertBoundingBox(SEARCH_BOUNDS));
                                        }
                                    },
                                }}
                            />
                        </div>
                    </div>
                    <MetadataPanel
                        cesiumWidget={cesiumWidget}
                        setFlyToInProgress={setFlyToInProgress}
                    />
                    <ObjectPoint
                        cesiumWidget={cesiumWidget}
                        isDrawing={isDrawing}
                        flyToInProgress={flyToInProgress}
                    />
                </>,
            ]}
            toggleSearch={false}
        />
    );
};

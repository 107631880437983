import type { GetObjectResponse } from '@api/goose/dist/enhancedGooseClient';
import { useMessagesContext } from '@local/messages-wds2/dist/MessagesContext';
import { Copy } from '@local/web-design-system-2';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { Header } from 'src/components/sidebar/Header';
import { PropertyDate } from 'src/components/sidebar/PropertyDate';
import {
    COPIED_TO_CLIPBOARD,
    CREATED_BY,
    CREATED_ON,
    ID,
    STAGE,
    VERSION_HISTORY,
    VERSION_WITH_ID,
} from 'src/strings';

import { Icon } from './Icon';
import { NameValueItem } from './Properties';

interface Props {
    data?: GetObjectResponse;
}

export const VersionHistory = ({ data }: Props) => {
    const { addMessage } = useMessagesContext();

    const handleCopy = (versionID: string) => {
        navigator.clipboard.writeText(versionID);
        addMessage({
            message: COPIED_TO_CLIPBOARD,
            severity: 'success',
        });
    };

    if (!data?.versions) {
        // We've historically shown this header regardless of whether there are versions
        return <Header text={VERSION_HISTORY} />;
    }

    // typescript gets mad if you try to mutate in place, so create a new list and reverse that
    const reversed = [...data.versions].reverse();

    return (
        <Stack spacing={2}>
            <Typography variant="h6">{VERSION_HISTORY}</Typography>
            <Divider />
            <Stack spacing={1.5}>
                {reversed.map((version, index) => (
                    <Stack key={version.version_id} spacing={1} style={{ marginLeft: '16px' }}>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Box width="24px" height="24px">
                                <Icon schema={data.object.schema} />
                            </Box>
                            <Typography
                                variant="subtitle1"
                                fontWeight={500}
                                automation-id="version-history-object-name"
                            >
                                {VERSION_WITH_ID.replace('{id}', String(reversed.length - index))}
                            </Typography>
                        </Stack>
                        <Stack direction="row" sx={{ paddingLeft: '10px' }}>
                            <Divider
                                orientation="vertical"
                                flexItem
                                sx={{
                                    borderWidth: '1px',
                                    visibility: index === reversed.length - 1 ? 'hidden' : 'unset',
                                }}
                            />
                            <Stack sx={{ paddingLeft: '20px', paddingRight: '8px' }}>
                                <Box
                                    sx={{
                                        rowGap: '2px',
                                        columnGap: '8px',
                                        wordWrap: 'break-word',
                                        display: 'inline-grid',
                                        wordBreak: 'break-all',
                                        paddingBottom: '12px',
                                        overflowWrap: 'break-word',
                                        gridTemplateColumns: 'min-content auto',
                                    }}
                                >
                                    <NameValueItem name={ID}>
                                        <Typography
                                            variant="body2"
                                            sx={{ textTransform: 'capitalize' }}
                                        >
                                            {version.version_id}
                                        </Typography>
                                    </NameValueItem>
                                    <NameValueItem name={CREATED_ON}>
                                        <PropertyDate
                                            timestamp={version.created_at}
                                            automationId={`version-created-on-v${
                                                reversed.length - index
                                            }`}
                                        />
                                    </NameValueItem>
                                    <NameValueItem name={CREATED_BY}>
                                        <Typography
                                            variant="body2"
                                            automation-id={`version-created-by-v${
                                                reversed.length - index
                                            }`}
                                        >
                                            {version.created_by?.name}
                                        </Typography>
                                    </NameValueItem>
                                    <NameValueItem name={STAGE}>
                                        <Typography
                                            variant="body2"
                                            automation-id={`version-stage-v${
                                                reversed.length - index
                                            }`}
                                        >
                                            {version.stage?.name || 'None'}
                                        </Typography>
                                    </NameValueItem>
                                </Box>
                            </Stack>
                            <IconButton
                                size="small"
                                automation-id="copy-action"
                                sx={{ height: '20px', width: '20px' }}
                                onClick={() => handleCopy(version.version_id)}
                            >
                                <Copy sx={{ height: '20px', width: '20px' }} />
                            </IconButton>
                        </Stack>
                    </Stack>
                ))}
            </Stack>
        </Stack>
    );
};

export const blockmodelUUID = 'e28ae98b-735a-475c-9353-39ea16b1ff0f';
export const designUUID = '6c5dbf9b-3c44-4343-a682-fe49e99796c2';
export const downholeUUID = 'a80e19bb-bad3-4838-b8fc-894110d3a27e';
export const ellipsoidUUID = '0540940d-c28f-41b1-ae61-3011859f47bc';
export const geophysicsUUID = '6136188c-4b22-4132-b185-49f4c390328f';
export const linesUUID = '608beb74-da1b-4b88-bd98-0ea58896568d';
export const geoStatisticsDistributionsUUID = '709df661-452e-4242-89b3-ecc265f891da';
export const pointsUUID = '6882b0ed-0b7b-4c81-83b3-e3dc4180ef1a';
export const gridsUUID = '4bb219de-3617-4eda-af79-1af2b29ba960';
export const meshUUID = '70390163-05e5-46ed-9b4d-6b35532e1a59';
export const geoStatisticsVariogramsUUID = '6e0995ed-938a-4645-a50e-c00c2478a6c5';
export const geologicalModelUUID = 'ab4988ce-2837-478d-be85-1612752b5869';
export const yesterdayUUID = '11e25216-c566-4944-9a9c-453904ac9f86';
export const lastWeekUUID = '0e2d69bf-3b1c-43ed-b777-c89a8c88aad1';
export const lastMonthUUID = 'd5b14b56-d1cb-43f0-a247-e2b40130d524';
export const olderUUID = '430a0323-7c3d-4e5c-8184-b85570323d5e';

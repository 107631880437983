import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton/Skeleton';
import TableCell from '@mui/material/TableCell/TableCell';
import TableRow from '@mui/material/TableRow/TableRow';

// We can use the base WDS2 table skeleton. However its dimensions do not match the actual rendered table dimensions at all.
// So instead we will create a custom skeleton that matches the actual table dimensions by setting width %'s.
export const CustomScaledTableSkeleton = ({ rows }: { rows: number }) => (
    <>
        {[...Array(rows)].map((_, index) => (
            <TableRow
                automation-id={`skeleton-row-${index}`}
                // eslint-disable-next-line react/no-array-index-key
                key={`skeleton-row-${index}`}
                sx={{ width: '100%' }}
            >
                <TableCell automation-id={`skeleton-row-${index}-link`} sx={{ width: '58%' }}>
                    <Skeleton
                        animation="wave"
                        variant="text"
                        width="100%"
                        sx={{ height: '32px' }}
                    />
                </TableCell>

                <TableCell
                    automation-id={`skeleton-row-${index}-permissions`}
                    sx={{ width: '32%' }}
                >
                    <Skeleton
                        animation="wave"
                        variant="text"
                        width="100%"
                        sx={{ height: '32px' }}
                    />
                </TableCell>

                <TableCell
                    align="right"
                    automation-id={`skeleton-row-${index}-action`}
                    sx={{ width: '10%' }}
                >
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                        <Skeleton animation="wave" variant="circular" width={32} height={32} />
                    </Box>
                </TableCell>
            </TableRow>
        ))}
    </>
);

export enum UploadStatus {
    Uploaded = 'uploaded',
    Uploading = 'uploading',
    Failed = 'failed',
    Cancelled = 'cancelled',
    Immutable = 'immutable', // State for which the file upload can no longer be cancelled, yet has not completed uploading
}

export interface FileStatus {
    fileName: string;
    uploadStatus: UploadStatus;
    size: number;
    fileId: string;
    percentCompleted: number;
    abortController: AbortController;
}

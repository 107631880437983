import type { ListCellComponentProps } from '@local/web-design-system-2/dist/components/GenericListing/types';
import Folder from '@local/web-design-system-2/dist/icons/Folder';
import FolderOpen from '@local/web-design-system-2/dist/icons/FolderOpen';
import { OverflowTooltip } from '@local/web-design-system/dist/components/OverflowTooltip';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { SchemaIcon } from './SchemaIcon';
import { fileNameExtensionRemover } from './utils';

export const EmptyCell = () => <Typography variant="body2" />;

export const FolderCell = ({ item, itemKey }: ListCellComponentProps) => {
    const folderName = item[itemKey];
    return (
        <Grid direction="row" container style={{ paddingLeft: `${item.depth * 30}px` }}>
            {item.isOpen ? <FolderOpen /> : <Folder />}
            <Typography variant="body2" style={{ paddingLeft: '10px', lineHeight: '28px' }}>
                {folderName}
            </Typography>
        </Grid>
    );
};

export const FileCell = ({ item }: ListCellComponentProps) => {
    const { schema, name } = item;
    const Icon = SchemaIcon(schema);
    return (
        <Box
            style={{
                paddingLeft: `${item.depth * 30}px`,
                display: 'flex',
                flexWrap: 'nowrap',
                width: '100%',
            }}
        >
            <div style={{ width: 24 }}>
                <Icon />
            </div>
            <Typography
                variant="body2"
                style={{
                    paddingLeft: '10px',
                    lineHeight: '28px',
                    width: 'calc(100% - 24px)', // Offsetting the width of the icon above
                }}
            >
                <OverflowTooltip title={fileNameExtensionRemover(name)} maxLines={1}>
                    {fileNameExtensionRemover(name)}
                </OverflowTooltip>
            </Typography>
        </Box>
    );
};

export const StageCell = ({ item }: ListCellComponentProps) => (
    <Typography variant="body2" style={{ lineHeight: '28px' }}>
        <OverflowTooltip title={item.stage?.name || ''} maxLines={1}>
            {item.stage?.name || ''}
        </OverflowTooltip>
    </Typography>
);

import { useLazyGetObjectByIdQuery } from '@api/goose/dist/enhancedGooseClient';
import { useLazyGetTilesetByObjectIdQuery } from '@api/visualization/dist/enhancedVisualizationClient';
import { trackError } from '@local/metrics/dist/src/metrics';
import { useBaseXyz } from '@local/webviz/dist/context';
import type {
    GenericObject,
    ListenersArray,
    MetadataEntry,
    MetadataState,
} from '@local/webviz/dist/types';
import { parsePrefixUid } from '@local/webviz/dist/utilities/uuidGenerators';
import { ElementClass } from '@local/webviz/dist/xyz';
import {
    getOrgUuidFromParams,
    getSelectedWorkspaceFromParams,
} from '@local/workspaces/dist/components/OrgRouteGuard/OrgRouteGuard';
import merge from 'lodash-es/merge';
import { useEffect } from 'react';
import { useParams } from 'react-router';

import { useAppDispatch } from 'src/store/store';
import type { TreeStructure } from 'src/store/visualization/visualizationSlice.types';
import { ROOT_TREE_ID } from 'src/strings';
import { createViewSnapshot } from 'src/visualization/context/snapshots/generateSnapshot';
import type { Tileset } from 'src/visualization/types';

import { generateAttributesMetadataSnapshot } from '../snapshots/attributeSnapshot';
import type { CreateViewSnapshot } from '../snapshots/generateSnapshot.types';
import { useAttributeColormapAssociation } from './useAttributeColormapAssociation';

export function useObjectLoader(treeItem: TreeStructure) {
    const dispatch = useAppDispatch();
    const { getEntityState, setStateFromSnapshot, addViewToPlotDirectly, addListener, getState } =
        useBaseXyz();

    const objectId = parsePrefixUid(treeItem.treeId);
    const handleAttributeColormapAssociations = useAttributeColormapAssociation();

    const params = useParams();
    const orgId = getOrgUuidFromParams(params);
    const workspaceId = getSelectedWorkspaceFromParams(params);

    const [getObjectByIdTrigger] = useLazyGetObjectByIdQuery();
    const [getTilesetByTrigger] = useLazyGetTilesetByObjectIdQuery();

    const listeners: ListenersArray = [];

    useEffect(() => {
        async function loadObjectData() {
            const snapshot = await getObjectViewSnapshot();
            if (snapshot) {
                await loadObjectAttributes(snapshot);
                await handleAttributeColormapAssociations(objectId);
            }
        }
        loadObjectData();

        return () => {
            listeners.forEach((removeListener) => {
                removeListener();
            });
        };
    }, []);

    async function getObjectViewSnapshot() {
        if (treeItem.parentId === ROOT_TREE_ID) {
            const gooseResponse = await getObjectByIdTrigger(
                {
                    objectId,
                    orgId,
                    workspaceId,
                },
                true,
            ).unwrap();

            return createViewSnapshot({
                treeItem,
                gooseResponse,
                params: {
                    objectId: gooseResponse.object_id,
                    workspaceId,
                    orgId,
                },
                dispatch,
            });
        }

        try {
            // assumption that if a parent object exists (i.e. the object is part of a composite), then the parent and tileset are passed to create a snapshot
            // if newly supported composites are rendered differently, a switch case will be needed here

            const parentId = parsePrefixUid(treeItem.parentId); // need for GMMs
            const parentGooseResponse = await getObjectByIdTrigger(
                {
                    objectId: parentId,
                    orgId,
                    workspaceId,
                },
                true,
            ).unwrap();

            const tileset: Tileset = (await getTilesetByTrigger(
                {
                    orgId,
                    workspaceId,
                    objectId: parentId,
                },
                true,
            ).unwrap()) as any;

            return createViewSnapshot({
                treeItem,
                gooseResponse: parentGooseResponse,
                params: {
                    objectId: treeItem.parentId,
                    workspaceId,
                    orgId,
                },
                tileset,
                dispatch,
            });
        } catch (error: any) {
            trackError(error);
            return undefined;
        }
    }

    async function downloadAttributesMetadata(
        attributesMetadata: GenericObject<MetadataEntry>,
    ): Promise<MetadataState> {
        const downloadMetadataRequests: Promise<void>[] = [];
        const downloadedMetadata: MetadataState = {};
        const attributeIds = Object.keys(attributesMetadata);

        attributeIds.forEach((attributeId) => {
            const { uri } = attributesMetadata[attributeId];
            if (uri) {
                downloadMetadataRequests.push(
                    fetch(uri)
                        .then(async (response) => {
                            if (!response.ok) {
                                throw new Error(
                                    `Failed to download attribute ${attributeId} with status code ${response.status}`,
                                );
                            }
                            const metadata = await response.json();
                            downloadedMetadata[attributeId] = metadata;
                            /* eslint-disable no-param-reassign */
                            delete attributesMetadata[attributeId].uri;
                        })
                        .catch((error) => trackError(error)),
                );
            }
        });

        await Promise.all(downloadMetadataRequests);
        return downloadedMetadata;
    }

    async function loadObjectAttributes(viewSnapshot: CreateViewSnapshot) {
        if (!viewSnapshot?.snapshot) return;
        const newSnapshot = viewSnapshot.snapshot;
        if ('status' in newSnapshot) {
            delete newSnapshot.status;
        }
        await setStateFromSnapshot(newSnapshot);
        addViewToPlotDirectly(viewSnapshot.viewId);

        Object.keys(viewSnapshot.snapshot).forEach((elementId) => {
            if (!elementId.includes(ElementClass.Tileset3D)) return;
            const removeElementMetadataListener = addListener(
                elementId,
                'metadata',
                async (attributes) => {
                    if (!attributes?.attributes_metadata) return;
                    let { attributes_metadata: attributesMetadata } = attributes;

                    const downloadedMetadata = await downloadAttributesMetadata(attributesMetadata);
                    attributesMetadata = merge(attributesMetadata, downloadedMetadata);

                    const xyzState = getState();
                    const attributesSnapshot = generateAttributesMetadataSnapshot(
                        attributesMetadata,
                        xyzState,
                        getEntityState,
                    );
                    setStateFromSnapshot(attributesSnapshot);
                },
            );
            listeners.push(removeElementMetadataListener);
        });
    }
}

import { extractObjectSchemaName } from '@api/goose/dist/utils/extractObjectSchemaName';
import type { ListCellComponentProps } from '@local/web-design-system-2/dist/components/GenericListing/types';
import OverflowTooltip from '@local/web-design-system-2/dist/components/OverflowTooltip/OverflowTooltip';
import Typography from '@mui/material/Typography';

export const SchemaCell = ({ item, itemKey }: ListCellComponentProps) => {
    const fullSchema = item[itemKey];
    const schema = extractObjectSchemaName(fullSchema);
    return (
        <Typography variant="body2">
            <OverflowTooltip title={schema} maxLines={1}>
                {schema}
            </OverflowTooltip>
        </Typography>
    );
};

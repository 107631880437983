import type { CesiumWidget } from '@cesium/engine';
import { Cartesian3, Cartographic } from '@cesium/engine';

/**
 * Calculate the point height for clipping preference,
 * based on the pitch of the camera. This ensures that when the camera is tilted,
 * the height of the point is adjusted to ensure it is clipped accordingly.
 * @param pitch
 * @returns height of the point, for clipping preference
 */
const calculateClippingPreference = (pitch: number, earthCenterToHorizon: number) => {
    // These numbers are tweaked from the range of the camera's pitch (in radians)
    // The range of the camera's pitch is between 0 and 3.14 radians (0 and 180 degrees)
    // But the camera's pitch is limited to -2 in order to fix some issues with the clipping when the camera is tilted
    const minPitch = -2;
    const maxPitch = 0;
    // These values simply help to clip the point when the camera is tilted,
    // by adjusting the height of the point, and changing it's distance from the camera.
    // Starts at the low height of 1 and goes up to the distance between the center of the
    // globe and the ellipsis horizon (clipping point).
    const minClippingHeight = 1;
    const maxClippingHeight = earthCenterToHorizon;

    const normalisedPitch = (pitch - minPitch) / (maxPitch - minPitch);

    // Equation to get the clipping height based on the camera's pitch
    const clippingHeight =
        minClippingHeight + normalisedPitch * (maxClippingHeight - minClippingHeight);

    return clippingHeight;
};

/**
 * Check if the current active point is in view.
 * Checks the views of both the canvas and the camera.
 * @param cesiumWidget
 * @param canvasPosition
 * @param position
 * @returns boolean indicating if the point is in view
 */
export const checkActivePointPosition = (
    cesiumWidget: CesiumWidget,
    canvasPosition: { x: number; y: number } | null,
    position: { x: number; y: number; z: number },
) => {
    // Approx. distance between center of the globe and the ellipsis horizon
    const EARTH_CENTER_TO_HORIZON = 7_400_000;
    const { pitch } = cesiumWidget.camera;
    const POINT_HEIGHT = calculateClippingPreference(pitch, EARTH_CENTER_TO_HORIZON);
    let inView = true;

    const bounds = cesiumWidget.scene.canvas.getBoundingClientRect();
    if (
        canvasPosition &&
        (canvasPosition.x < 0 ||
            canvasPosition.y < 0 ||
            canvasPosition.x > bounds.width ||
            canvasPosition.y > bounds.height)
    ) {
        inView = false;
    }

    const cartographicPosition = Cartographic.fromCartesian(
        {
            x: position.x,
            y: position.y,
            z: position.z,
        } as Cartesian3,
        cesiumWidget.scene.globe.ellipsoid,
    );
    const cartographicCamera = cesiumWidget.camera.positionCartographic;

    // Cartesian coords of where the camera is looking,
    // in relation to the surface of the globe
    const cartesianSurfaceViewPoint = Cartesian3.fromRadians(
        cartographicCamera.longitude,
        cartographicCamera.latitude,
        0,
    );
    // Cartesian reference point (using active position coords)
    const cartesianReferencePoint = Cartesian3.fromRadians(
        cartographicPosition.longitude,
        cartographicPosition.latitude,
        POINT_HEIGHT,
    );

    const distanceBetweenPointAndView: number = Math.round(
        cartesianReferencePoint && cartesianSurfaceViewPoint
            ? Cartesian3.distance(cartesianSurfaceViewPoint, cartesianReferencePoint)
            : 0,
    );

    if (distanceBetweenPointAndView > EARTH_CENTER_TO_HORIZON) {
        inView = false;
    }

    return inView;
};

import type { GetObjectResponse } from '@api/goose/dist/enhancedGooseClient';
import { useListStagesQuery, useUpdateMetadataMutation } from '@api/goose/dist/enhancedGooseClient';
import { useMessagesContext } from '@local/messages-wds2/dist/MessagesContext';
import { useTrace } from '@local/web-design-system-2/dist/utils/trace';
import { useGetCurrentUserRoleQuery } from '@local/workspaces/dist/apiClients/GENERATED_workspaceClientEndpoints';
import {
    getHubUrlForCurrentOrg,
    getOrgUuidFromParams,
    getSelectedWorkspaceFromParams,
} from '@local/workspaces/dist/components/OrgRouteGuard/OrgRouteGuard';
import { hasRoleOrHigher } from '@local/workspaces/dist/utils/permissions';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import type { SelectChangeEvent } from '@mui/material/Select';
import Select from '@mui/material/Select';
import Skeleton from '@mui/material/Skeleton';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { STAGE_DIALOG_FAILURE, STAGE_DIALOG_SUCCESS } from 'src/strings';

interface Props {
    objectData: GetObjectResponse;
}

export const PropertyStage = ({ objectData }: Props) => {
    const params = useParams();
    const orgId = getOrgUuidFromParams(params);
    const workspaceId = getSelectedWorkspaceFromParams(params);

    const { data: currentUserRoleData, isLoading: isRoleLoading } = useGetCurrentUserRoleQuery({
        hubUrl: getHubUrlForCurrentOrg(),
        orgId,
        workspaceId,
    });

    const { data: stagesData, isLoading: isStagesLoading } = useListStagesQuery({
        orgId,
    });

    const [stageId, setStageId] = useState<string | null>(null);
    useEffect(() => {
        setStageId(objectData.stage?.stage_id || null);
    }, [objectData]);
    const [updateStage] = useUpdateMetadataMutation();

    const { addMessage } = useMessagesContext();

    const handleChangeObjectStage = async (event: SelectChangeEvent) => {
        const newStage = event.target.value;
        const selectedStageId =
            stagesData?.stages.find(
                (evoStage) => evoStage.name.toLowerCase() === newStage.toLowerCase(),
            )?.stage_id || null;

        const response = await updateStage({
            objectId: objectData.object_id,
            orgId,
            workspaceId,
            metadataUpdateBody: { stage_id: selectedStageId },
        });

        if (response.error) {
            addMessage({
                message: STAGE_DIALOG_FAILURE,
                severity: 'error',
            });
        } else {
            addMessage({
                message: STAGE_DIALOG_SUCCESS,
                severity: 'success',
            });
            setStageId(selectedStageId!);
        }
    };

    const applyTrace = useTrace('stage-properties');

    if (isStagesLoading || isRoleLoading) {
        return <Skeleton />;
    }

    return (
        <FormControl
            fullWidth
            disabled={!hasRoleOrHigher(currentUserRoleData?.role, 'editor')}
            automation-id={applyTrace('form')}
            sx={{ mt: 4 }}
        >
            <Select
                value={
                    stageId
                        ? stagesData?.stages.find((evoStage) => evoStage.stage_id === stageId)?.name
                        : ''
                }
                displayEmpty
                sx={{ '.MuiSelect-select': { padding: '10px' } }}
                renderValue={(selected) => selected || 'Select stage'}
                onChange={handleChangeObjectStage}
                automation-id={applyTrace('select')}
            >
                <MenuItem value="">
                    <em>None</em>
                </MenuItem>
                {stagesData?.stages.map((stage) => (
                    <MenuItem
                        key={stage.stage_id}
                        value={stage.name}
                        automation-id={applyTrace(`item-${stage.stage_id}`)}
                    >
                        {stage.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

import { generateEntity } from '@local/webviz/dist/context/snapshots/base';
import type { GenericObject } from '@local/webviz/dist/types';
import type {
    ExternalAllEntityState,
    Maybe,
    MetadataEntry,
    Nullable,
    Snapshot,
    UpdateSnapshot,
} from '@local/webviz/dist/types/xyz';
import { UID_SUFFIXES } from '@local/webviz/dist/utilities';
import {
    ArrayClass,
    FilterClass,
    MappingClass,
    SceneClass,
    toSuffixUid,
} from '@local/webviz/dist/xyz';
import isFinite from 'lodash-es/isFinite';

import type { store } from 'src/store/store';
import { updateAttributeKeyMap } from 'src/store/visualization/visualizationSlice';
import { AttributeTypes } from 'src/visualization/constants';
import type { AttributeSchema, AttributeType } from 'src/visualization/types';
import { createAttributeId } from 'src/visualization/utils/utils';

import { categoryDataSnapshot } from './categoryDataSnapshot';

export const DEFAULT_GRADIENT = 'RAINBOW_BGYR';

export function generateAttributeListSnapshot(
    dispatch: typeof store.dispatch,
    attributes?: AttributeSchema[],
): [string, UpdateSnapshot] {
    let attributesSnapshot = {};
    let firstColorData = '';
    if (attributes) {
        attributesSnapshot = attributes.reduce(
            (accumulator: UpdateSnapshot, { values: { data }, attribute_type, name, key }) => ({
                ...accumulator,
                ...generateAttributesSnapshot(data, attribute_type, dispatch, name, key),
            }),
            {},
        );

        if (Object.keys(attributesSnapshot).length > 0) {
            const firstAttribute = Object.keys(attributesSnapshot).find((attr) =>
                attr.endsWith(UID_SUFFIXES.DATA),
            );

            if (firstAttribute) {
                firstColorData = firstAttribute;
            }
        }
    }
    return [firstColorData, attributesSnapshot];
}

function generateAttributesSnapshot(
    attribute: Nullable<string>,
    attributeType: AttributeType,
    dispatch: typeof store.dispatch,
    name?: string,
    attributeKey?: string,
): UpdateSnapshot {
    if (!attribute) {
        return {};
    }

    const attributeId = `${createAttributeId(attribute)}:${name}`;
    const dataId = toSuffixUid(attributeId, UID_SUFFIXES.DATA);
    const arrayId = toSuffixUid(attributeId, UID_SUFFIXES.ARRAY);
    const mappingId = toSuffixUid(attributeId, UID_SUFFIXES.MAPPING);
    const filterId = toSuffixUid(attributeId, UID_SUFFIXES.FILTER);
    const categoryMappingTitlesId = toSuffixUid(attributeId, UID_SUFFIXES.TITLES);
    const categoryMappingColorsId = toSuffixUid(attributeId, UID_SUFFIXES.COLORS);

    if (attributeKey) {
        dispatch(
            updateAttributeKeyMap({
                attributeKey,
                attributeId,
            }),
        );
    }

    const arraySnapshot = {
        [arrayId]: generateEntity(ArrayClass.PropertyTable, {
            propertyId: createAttributeId(attribute),
            id: arrayId,
        }),
    };
    let colorSnapshot = {};
    if (attributeType === AttributeTypes.Scalar) {
        /** it's necessary to update visibility with true values as without it the color mapping filter won't work
        and the length should be data_control_values + 1 (i.e 4 + 1 = 5) */
        colorSnapshot = {
            [mappingId]: generateEntity(MappingClass.Continuous, {
                gradient: DEFAULT_GRADIENT,
                data_control_values: [-Infinity, -Infinity, +Infinity, +Infinity],
                id: mappingId,
                visibility: [true, true, true, true, true],
            }),
        };
    } else if (attributeType === AttributeTypes.Category) {
        colorSnapshot = {
            [categoryMappingTitlesId]: generateEntity(ArrayClass.String, {
                id: categoryMappingTitlesId,
            }),
            [categoryMappingColorsId]: generateEntity(ArrayClass.Color, {
                id: categoryMappingColorsId,
            }),
            [mappingId]: generateEntity(MappingClass.Category, {
                categories: [categoryMappingColorsId, categoryMappingTitlesId],
                id: mappingId,
            }),
        };
    } else {
        // eslint-disable-next-line no-console
        console.warn(`Unsupported attribute type: ${attributeType}`);
        return {};
    }

    const filterSnapshot = {
        [filterId]: generateEntity(FilterClass.Numeric, {
            id: filterId,
            min: -Infinity,
            max: +Infinity,
        }),
    };

    const dataSnapshot = {
        [dataId]: generateEntity(SceneClass.Data, {
            id: dataId,
            mapping: mappingId,
            array: arrayId,
            filter: filterId,
        }),
    };

    return {
        ...arraySnapshot,
        ...colorSnapshot,
        ...filterSnapshot,
        ...dataSnapshot,
    };
}

export const generateAttributesMetadataSnapshot = (
    attributesMetadata: GenericObject<MetadataEntry>,
    xyzState: Snapshot,
    getEntityState: (entityId: string) => Maybe<ExternalAllEntityState>,
): UpdateSnapshot => {
    const attributeIds = Object.keys(attributesMetadata);

    return Object.keys(xyzState).reduce((outerAcc: UpdateSnapshot, entityId) => {
        const attributeSnapshotPerEntity = attributeIds.reduce(
            (innerAcc: UpdateSnapshot, attributeId) => {
                if (!entityId.includes(attributeId)) return innerAcc;
                if (entityId.endsWith(`:${UID_SUFFIXES.MAPPING}`)) {
                    const mappingEntity = getEntityState(entityId);
                    const mappingSnapshotUpdate = generateMappingSnapshotUpdate(
                        attributesMetadata,
                        mappingEntity,
                        getEntityState,
                    );
                    return { ...innerAcc, ...mappingSnapshotUpdate };
                }
                return innerAcc;
            },
            {},
        );
        return { ...outerAcc, ...attributeSnapshotPerEntity };
    }, {});
};

const generateMappingSnapshotUpdate = (
    attributesMetadata: GenericObject<MetadataEntry>,
    mappingEntity: Maybe<ExternalAllEntityState>,
    getEntityState: (entityId: string) => Maybe<ExternalAllEntityState>,
): UpdateSnapshot => {
    if (!mappingEntity) return {};

    return Object.keys(attributesMetadata).reduce((acc: UpdateSnapshot, attrId) => {
        if (mappingEntity.id.includes(attrId)) {
            const { metadata } = attributesMetadata[attrId];
            if ('min' in metadata && 'max' in metadata) {
                const { min, max } = metadata;
                const minValue = isFinite(min) ? min : -Infinity;
                const maxValue = isFinite(max) ? max : +Infinity;
                return {
                    ...acc,
                    [mappingEntity.id]: {
                        data_control_values: [minValue, minValue, maxValue, maxValue],
                    },
                };
            }
            if ('lookup_table' in metadata) {
                const { lookup_table: lookupTable } = metadata;
                return {
                    ...acc,
                    ...categoryDataSnapshot(
                        mappingEntity.id.replace(`:${UID_SUFFIXES.MAPPING}`, ''),
                        lookupTable,
                        getEntityState,
                    ),
                };
            }
        }
        return acc;
    }, {});
};

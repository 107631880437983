import { useGetWorkspaceAffinitySchemaQuery } from '@local/workspaces/dist/apiClients/workspaceClientEndpoints';
import { getOrgUuidFromParams } from '@local/workspaces/dist/components/OrgRouteGuard/OrgRouteGuard';
import Stack from '@mui/material/Stack';
import { useParams } from 'react-router';

import { DropDown } from 'src/pages/workspacePage/affinityFoldersContent/DropDown';
import { currentViewSelector, setCurrentView } from 'src/store/features/displaySettingSlice';
import { useAppDispatch, useAppSelector } from 'src/store/store';

export const AffinityFolderViews = () => {
    const params = useParams();

    const dispatch = useAppDispatch();
    const currentView: string = useAppSelector(currentViewSelector);

    const { data: gooseData, isLoading } = useGetWorkspaceAffinitySchemaQuery({
        hubUrl: 'https://workspace.dev-sqnt.com/workspace',
        orgId: getOrgUuidFromParams(params),
        workspaceId: 'talking-to-mocks-for-now',
    });

    const setSelectedSchema = (selectedSchema: string) => {
        dispatch(setCurrentView(selectedSchema as 'Data type' | 'Last updated'));
    };

    return (
        <Stack direction="row" flexGrow={1} justifyContent="right" alignItems="center">
            <DropDown
                schemas={gooseData?.schemas ?? []}
                setSelectedSchema={setSelectedSchema}
                selected={currentView}
                isLoading={isLoading}
            />
        </Stack>
    );
};

import { makeStyles } from 'tss-react/mui';

export const FOOTER_HEIGHT_UNITS = 3.5;
export const useStyles = makeStyles()((theme) => ({
    contentInner: {
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(5),
        height: '100%',
        overflow: 'hidden',
    },
    webVizContent: {
        display: 'grid',
        height: '100%',
        padding: 0,
    },
}));

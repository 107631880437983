import type { ListCellComponentProps } from '@local/web-design-system-2/dist/components/GenericListing/types';
import OverflowTooltip from '@local/web-design-system-2/dist/components/OverflowTooltip/OverflowTooltip';
import Typography from '@mui/material/Typography';

import { getFileExtension } from 'src/utils/fileUtils';

export const DataTypeCell = ({ item }: ListCellComponentProps) => (
    <Typography variant="body2" textTransform="uppercase">
        <OverflowTooltip title={getFileExtension(item.name)} maxLines={1}>
            {getFileExtension(item.name)}
        </OverflowTooltip>
    </Typography>
);

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid/Grid';
import Link from '@mui/material/Link';

import {
    CloudApplicationsIcon,
    ConnectedWorkflowsIcon,
    DeveloperToolsIcon,
} from '../../../assets/ConnectedWorkflowsIcon';
import { CONTACT_US, LEARN_MORE_LINK, OVERVIEW } from '../../../strings';
import { ENQUIRE_ID } from '../enquireForm/enquireform';
import { useStyles } from './Hero.styles';

const cardStrings = [
    {
        title: 'Simplify collaboration',
        description:
            'Integrate workflows, reuse past data, and automate tasks for stronger cross-functional teamwork and seamless collaboration across all geoscience data sources.',
        target: '_blank',
        learnMore: 'https://www.seequent.com/products-solutions/seequent-evo/',
    },
    {
        title: 'Make better decisions',
        description:
            'Access reliable data in one place to support confident decision-making and reduce risk. Utilise Evo’s advanced geospatial search, 3D visualisation, open data formats, and more for comprehensive insights.',
        target: '_blank',
        learnMore: 'https://www.seequent.com/products-solutions/seequent-evo/',
    },
    {
        title: 'Innovate continuously',
        description:
            'Stay agile with the latest solutions from Seequent, adopt new technology without barriers, leverage open APIs for seamless integration, and accelerate timelines with powerful cloud compute capabilities.',
        target: '_blank',
        learnMore: 'https://www.seequent.com/products-solutions/seequent-evo/',
    },
];

export const HeroCard = ({
    iconSrc,
    title,
    description,
    target,
    learnMore,
}: {
    iconSrc: any;
    title: string;
    description: string;
    target: string;
    learnMore: string;
}) => {
    const { classes } = useStyles();

    return (
        <div className={classes.card}>
            <h3>
                {iconSrc}
                <span>{title}</span>
            </h3>
            <p>{description}</p>
            <Link
                className={classes.learnMore}
                target={target}
                href={learnMore}
                color="inherit"
                rel="noopener"
            >
                {/* eslint-disable-next-line react/no-danger */}
                <span dangerouslySetInnerHTML={{ __html: LEARN_MORE_LINK }} />
            </Link>
        </div>
    );
};

export const Hero = () => {
    const { classes } = useStyles();

    const handleClickScroll = () => {
        const element = document.getElementById(ENQUIRE_ID);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <Grid container className={classes.hero}>
            <div className={classes.background}>
                {/* update preload link in apps/evo/index.html if this image changes */}
                <img src="Mesh-BK-Hero-x1.png" alt="" className={classes.backgroundImage} />
            </div>
            <Grid item className={classes.heroPrimary}>
                The future of geoscience is evolving with <em>Seequent Evo</em>
            </Grid>
            <Grid item className={classes.heroSecondary}>
                Simplify collaboration, enhance decision-making, and drive continuous innovation by
                integrating workflows, automating common tasks, while accessing all your geoscience
                data in one place.
            </Grid>
            <Grid container className={classes.cardHeader} justifyContent="space-between">
                <p>{OVERVIEW}</p>
                <Button onClick={handleClickScroll} className={classes.contact}>
                    {CONTACT_US}
                </Button>
            </Grid>
            <Grid container direction="row" justifyContent="center" className={classes.cardList}>
                <HeroCard
                    iconSrc={<CloudApplicationsIcon />}
                    title={cardStrings[0].title}
                    description={cardStrings[0].description}
                    target={cardStrings[0].target}
                    learnMore={cardStrings[0].learnMore}
                />
                <HeroCard
                    iconSrc={<ConnectedWorkflowsIcon />}
                    title={cardStrings[1].title}
                    description={cardStrings[1].description}
                    target={cardStrings[1].target}
                    learnMore={cardStrings[1].learnMore}
                />
                <HeroCard
                    iconSrc={<DeveloperToolsIcon />}
                    title={cardStrings[2].title}
                    description={cardStrings[2].description}
                    target={cardStrings[2].target}
                    learnMore={cardStrings[2].learnMore}
                />
            </Grid>
        </Grid>
    );
};

import { trackUserAction } from '@local/metrics/dist/src/metrics';
import {
    DRAG_TAB_HEIGHT,
    HEADER_HEIGHT,
    MAX_CONTENT_HEIGHT,
} from '@local/web-design-system/dist/components/DockableContainer';
import { useZIndexContext, ZIndexContext } from '@local/web-design-system/dist/hooks/useZIndex';
import SettingsIcon from '@local/web-design-system/dist/icons/Menu/SettingsIcon';
import { SliceIcon } from '@local/web-design-system/dist/icons/SliceTools';
import MeasureIcon from '@local/web-design-system/dist/icons/SliceTools/MeasureIcon';
import { useTrace } from '@local/web-design-system/dist/utils/trace';
import { MEASUREMENT_TITLE } from '@local/webviz/dist/components/Measurement/MeasurementDialog.constants';
import { Orientation } from '@local/webviz/dist/components/Orientation';
import { DEFAULT_COLORMAP_DIALOG_WIDTH } from '@local/webviz/dist/components/Properties/Colormaps/Colormap.constants';
import { Scalebar } from '@local/webviz/dist/components/Scalebar';
import { SETTINGS_TITLE } from '@local/webviz/dist/components/Settings';
import { SLICE } from '@local/webviz/dist/components/Slice/SliceDialog';
import Tool from '@local/webviz/dist/components/Tool/Tool';
import { useSelection } from '@local/webviz/dist/context/hooks/useSelection';
import Grid from '@mui/material/Grid';
import { useCallback, useEffect, useRef, useState } from 'react';

import { useSelectedObjectsAttributes } from 'src/hooks/useSelectedObjectsAttributes';
import { UserAction } from 'src/metrics.types';
import { useAppDispatch, useAppSelector } from 'src/store/store';
import {
    getColormapControlData,
    initialColormapControlState,
    loadedObjectsMap,
    orientationVisible,
    scalebarVisible,
    selectionListScenePanel,
} from 'src/store/visualization/selectors';
import {
    selectObjectScenePanel,
    updateColormapControl,
} from 'src/store/visualization/visualizationSlice';
import type { SelectObject } from 'src/store/visualization/visualizationSlice.types';

import { getObjectIdFromViewId } from '../context/snapshots/generateSnapshot';
import {
    DockableMeasureDialog,
    DockablePropertiesDialog,
    DockableSelectionPanel,
    DockableSettingsDialog,
    DockableSliceDialog,
    DraggableColormapControlDialog,
} from '../DockableDialogs/DockableDialogs';
import { useStyles } from './SideToolbar.styles';

function getDefaultPositions() {
    const baseY = 66;
    const yOffset = 58;
    const baseX = 80;
    const xOffset = -10;

    return {
        slicer: { x: -baseX, y: baseY },
        measure: { x: -baseX + xOffset, y: baseY + yOffset },
        settings: { x: -baseX + xOffset * 2, y: baseY + yOffset * 2 },
    };
}

export function SideToolbar() {
    const { classes } = useStyles();

    const dispatch = useAppDispatch();
    const zIndexContext = useZIndexContext();
    const applyTrace = useTrace('side-toolbar');

    const [showProperties, setShowProperties] = useState(false);
    const selectedObjectIds = useAppSelector(selectionListScenePanel);
    const { firstSelectedTreeItem } = useSelectedObjectsAttributes(selectedObjectIds);
    const hasSelectionFromList = Object.keys(selectedObjectIds).length > 0;
    const colormapControlState = useAppSelector(getColormapControlData);
    const loadedObjects = useAppSelector(loadedObjectsMap);

    const onAddSelectionListener = (viewId: string) => {
        if (!viewId) {
            return;
        }
        const objectId = getObjectIdFromViewId(viewId);
        dispatch(selectObjectScenePanel({ objectId } as SelectObject));
        setShowProperties(true);
    };
    useSelection({ onAddSelectionListener });

    useEffect(() => {
        setShowProperties(hasSelectionFromList);
    }, [selectedObjectIds]);

    useEffect(() => {
        if (!colormapControlState.viewId) {
            setShowColormapControlDialog(false);
            return;
        }

        const objectId = getObjectIdFromViewId(colormapControlState.viewId);
        if (objectId in loadedObjects) {
            setShowColormapControlDialog(true);
            dispatch(updateColormapControl({ selectedObjectName: firstSelectedTreeItem?.name }));
        } else {
            setShowColormapControlDialog(false);
            dispatch(updateColormapControl(initialColormapControlState));
        }
    }, [colormapControlState.viewId, loadedObjects]);

    const sideToolbarRef = useRef<HTMLDivElement>(null);
    const [sliceToolSelected, setSliceToolSelected] = useState(false);

    const handleSliceDialogClosed = useCallback(() => setSliceToolSelected(false), []);
    const handleSliceSelect = useCallback(() => {
        trackUserAction(UserAction.USER_CLICKED_SLICE_BUTTON);
        setSliceToolSelected(!sliceToolSelected);
    }, [sliceToolSelected]);

    const [showMeasurement, setShowMeasurement] = useState(false);
    const handleMeasurementClosed = useCallback(() => {
        setShowMeasurement(false);
    }, []);

    const handleMeasurementSelect = useCallback(() => {
        trackUserAction(UserAction.USER_CLICKED_MEASURE_BUTTON);
        setShowMeasurement(!showMeasurement);
    }, [showMeasurement]);

    const [showSettings, setShowSettings] = useState(false);
    const handleSettingsClosed = useCallback(() => {
        setShowSettings(false);
    }, []);
    const handleSettingsSelect = useCallback(() => {
        setShowSettings(!showSettings);
    }, [showSettings]);

    const handlePropertiesClosed = useCallback(() => {
        setShowProperties(false);
    }, []);

    const [showColormapControlDialog, setShowColormapControlDialog] = useState(false);

    const handleColormapControlDialogClosed = useCallback(() => {
        setShowColormapControlDialog(false);
        // reset colormap control state
        dispatch(updateColormapControl(initialColormapControlState));
    }, []);

    const getPlotWidth = useCallback(() => sideToolbarRef.current?.clientWidth ?? 0, []);
    const getPlotHeight = useCallback(() => sideToolbarRef.current?.clientHeight ?? 0, []);

    return (
        <>
            <ZIndexContext.Provider value={zIndexContext}>
                {sliceToolSelected && (
                    <DockableSliceDialog
                        onClose={handleSliceDialogClosed}
                        defaultPosition={getDefaultPositions().slicer}
                    />
                )}
                {showMeasurement && (
                    <DockableMeasureDialog
                        onClose={handleMeasurementClosed}
                        defaultPosition={getDefaultPositions().measure}
                    />
                )}
                {showSettings && (
                    <DockableSettingsDialog
                        onClose={handleSettingsClosed}
                        defaultPosition={getDefaultPositions().settings}
                    />
                )}
                {showProperties && (
                    <DockablePropertiesDialog
                        onClose={handlePropertiesClosed}
                        defaultPosition={computePropertiesPosition(getPlotWidth(), getPlotHeight())}
                    />
                )}
                {showColormapControlDialog && (
                    <DraggableColormapControlDialog
                        onClose={handleColormapControlDialogClosed}
                        defaultPosition={computeColormapControlPosition(
                            getPlotWidth(),
                            getPlotHeight(),
                        )}
                    />
                )}
                <DockableSelectionPanel />
                <Grid className={classes.sidebarPlaceholder} />
            </ZIndexContext.Provider>
            <Grid className={classes.toolbar} ref={sideToolbarRef}>
                <Grid item className={classes.tools}>
                    <Tool
                        icon={<SliceIcon {...applyTrace('slice')} />}
                        onClick={handleSliceSelect}
                        selected={sliceToolSelected}
                        label={SLICE}
                        wideIcon
                    />
                    <Tool
                        selected={showMeasurement}
                        icon={<MeasureIcon {...applyTrace('measurement')} />}
                        onClick={handleMeasurementSelect}
                        label={MEASUREMENT_TITLE}
                        wideIcon
                    />
                    <Tool
                        selected={showSettings}
                        icon={<SettingsIcon {...applyTrace('settings')} />}
                        onClick={handleSettingsSelect}
                        label={SETTINGS_TITLE}
                        wideIcon
                    />
                </Grid>
            </Grid>
            <OrientationWithState />
            <ScalebarWithState />
        </>
    );
}

export function OrientationWithState() {
    const { classes } = useStyles();
    const isOrientationVisible = useAppSelector(orientationVisible);
    return isOrientationVisible ? <Orientation className={classes.orientationArrows} /> : null;
}

export function ScalebarWithState() {
    const { classes } = useStyles();
    const isScalebarVisible = useAppSelector(scalebarVisible);
    return isScalebarVisible ? <Scalebar className={classes.scalebar} /> : null;
}

function computePropertiesPosition(plotWidth: number, plotHeight: number) {
    const MAX_HEIGHT_PROPERTIES = MAX_CONTENT_HEIGHT + DRAG_TAB_HEIGHT + HEADER_HEIGHT;
    return {
        x: -plotWidth,
        y:
            plotHeight < 2 * MAX_HEIGHT_PROPERTIES
                ? plotHeight - MAX_HEIGHT_PROPERTIES
                : plotHeight / 2 - 5,
    };
}

function computeColormapControlPosition(plotWidth: number, plotHeight: number) {
    const MAX_HEIGHT_COLORMAP_CONTROL = MAX_CONTENT_HEIGHT + DRAG_TAB_HEIGHT + HEADER_HEIGHT;
    return {
        x: (DEFAULT_COLORMAP_DIALOG_WIDTH - plotWidth) / 2,
        y: (plotHeight - MAX_HEIGHT_COLORMAP_CONTROL) / 2,
    };
}

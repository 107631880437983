import type {
    LegendMappingState,
    UniqueLegendsState,
} from '@local/webviz/dist/components/Legend/Legend.types';
import { useBaseXyz } from '@local/webviz/dist/context/hooks/useBaseXyz';
import { MappingClass } from '@local/webviz/dist/xyz';

import { useAppSelector } from 'src/store/store';
import { loadedObjectsMap } from 'src/store/visualization/selectors';

import { getViewIdFromObjectId } from '../snapshots/generateSnapshot';

/* eslint-disable no-underscore-dangle */
export function useLegends() {
    const loadedObjects = useAppSelector(loadedObjectsMap);
    const { getEntityState } = useBaseXyz();

    const loadedObjectKeys = Object.keys(loadedObjects);
    function getLegends() {
        return loadedObjectKeys.reduce((acc: UniqueLegendsState, key) => {
            const { treeId, schema, isLegendShown } = loadedObjects[key];
            const viewId = getViewIdFromObjectId(treeId, schema);
            const entity = getEntityState(viewId);

            if (!(entity && 'color_data' in entity)) {
                return acc;
            }
            const colorDataState = getEntityState(entity.color_data);

            if (!(colorDataState && 'array' in colorDataState && 'mapping' in colorDataState)) {
                return acc;
            }
            const arrayState = getEntityState(colorDataState.array);
            const mappingState = getEntityState(colorDataState.mapping);

            if (!arrayState || !mappingState) {
                return acc;
            }

            const arrayType = arrayState.__class__;

            // For holeId category data theres only title id with no colors.
            // Cannot display Legend for it
            if (
                mappingState.__class__ === MappingClass.Category &&
                mappingState.categories.length !== 2
            ) {
                return acc;
            }
            if ('id' in mappingState) {
                // manage unique legends
                const existingLegendState = acc[mappingState.id];
                // if any artifact.isLegendShown is true, enable the active mapping id Legend toggle
                if (existingLegendState && !existingLegendState?.enabled && isLegendShown) {
                    acc[mappingState.id] = {
                        ...existingLegendState,
                        enabled: isLegendShown,
                    };

                    return acc;
                }
                acc[mappingState.id] = {
                    mappingId: mappingState.id,
                    enabled: isLegendShown,
                    state: mappingState,
                    artifactId: treeId,
                    viewId,
                    attributeId: entity.color_data,
                    arrayType,
                } as LegendMappingState;
            }
            return acc;
        }, {});
    }

    return { getLegends };
}

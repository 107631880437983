import { Schemas, AttributeTypes, BlocksyncModelTypes } from 'src/visualization/constants';

export const SUPPORTED_ATTRIBUTE_TYPES = [AttributeTypes.Scalar, AttributeTypes.Category];
export const SUPPORTED_SCHEMAS = [
    Schemas.PointsetSchema,
    Schemas.DownholeIntervalsSchema,
    Schemas.DownholeCollectionSchema,
    Schemas.TriangleMeshSchema,
];
export const DRIVER_SUPPORTED_SCHEMAS = [
    Schemas.DownholeIntervalsSchema,
    Schemas.GlobalEllipsoidSchema,
    Schemas.LocalEllipsoidsSchema,
];

export const BLOCKSYNC_SUPPORTED_SCHEMAS = [Schemas.BlockSyncReferenceSchema];
export const BLOCKSYNC_SUPPORTED_MODEL_TYPES = [BlocksyncModelTypes.Regular];

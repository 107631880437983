import { trackError } from '@local/metrics';
import { ArrayTypes } from '@local/webviz/dist/xyz';
import type { Table } from 'apache-arrow';

import { getDB } from './init';
import type { TypedArray } from './types';

async function fetchParquetBuffer(downloadUrl: string): Promise<Uint8Array> {
    if (!downloadUrl) {
        throw new Error('No download URL provided');
    }

    try {
        const response = await fetch(downloadUrl);
        if (!response.ok) {
            throw new Error(`Failed to fetch file: ${response.statusText}`);
        }
        const buffer = await response.arrayBuffer();
        return new Uint8Array(buffer);
    } catch (err) {
        trackError('Error fetching parquet buffer', JSON.stringify(err));
        throw err;
    }
}

export async function parseParquetBuffer(downloadUrl: string): Promise<any> {
    if (!downloadUrl) {
        throw new Error('No download URL provided');
    }

    const buffer = await fetchParquetBuffer(downloadUrl);
    const db = await getDB();
    const connection = await db.connect();

    try {
        await db.registerFileBuffer('data.parquet', buffer);
        const query = await connection.query('SELECT * FROM data.parquet');
        return query;
    } catch (err) {
        trackError('Error reading parquet buffer', JSON.stringify(err));
        throw err;
    } finally {
        await connection.close();
    }
}
export function processQueryResult(query: Table<any>, dtype: ArrayTypes) {
    let result: TypedArray;
    switch (dtype) {
        case ArrayTypes.Float64:
            result = new Float64Array(query.numRows);
            break;
        default:
            throw new Error(`[processQueryResult] Unhandled dtype: ${dtype}`);
    }

    const { batches } = query;
    let idx = 0;
    const threshold = 1e-10; // Define a threshold to filter out near-zero values

    for (const batch of batches) {
        const { values } = batch.data.children[0];
        for (const value of values) {
            const numValue = Number(value);
            if (numValue && Number.isFinite(numValue) && Math.abs(numValue) > threshold) {
                result[idx] = numValue;
                idx += 1;
            }
        }
    }

    return result.subarray(0, idx);
}

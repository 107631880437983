import { generateEntity } from '@local/webviz/dist/context/snapshots/base';
import {
    DEFAULT_FLAT_COLOR,
    LINE_DEFAULT_WIDTH,
    TUBE_DEFAULT_RADIUS,
} from '@local/webviz/dist/context/snapshots/defaults';
import type { UpdateSnapshot } from '@local/webviz/dist/types/xyz';
import { ElementClass, LinesMode, ViewClass, toSuffixUid } from '@local/webviz/dist/xyz';

import type { store } from 'src/store/store';
import type { TreeStructure } from 'src/store/visualization/visualizationSlice.types';
import { extractTilesetUri } from 'src/visualization/utils/utils';

import type { DownholeCollectionType, Tileset } from '../../../types';
import { generateAttributeListSnapshot } from '../attributeSnapshot';

export function generateDownholeCollectionLinesSnapshot(
    treeItem: TreeStructure,
    tileset: Tileset,
    gooseObject: DownholeCollectionType,
    dispatch: typeof store.dispatch,
): UpdateSnapshot | undefined {
    const url = extractTilesetUri(tileset, treeItem.name);

    if (!url) {
        return undefined;
    }

    const elementId = toSuffixUid(treeItem.treeId, ElementClass.Tileset3D);
    const viewId = toSuffixUid(treeItem.treeId, ViewClass.Lines);

    const { collections } = gooseObject;
    const collectionObj = collections.find((collection) => collection.name === treeItem.name);
    const [firstColorData, attributesSnapshot] = generateAttributeListSnapshot(
        dispatch,
        collectionObj?.from_to?.attributes,
    );

    const snapshot = {
        [elementId]: generateEntity(ElementClass.Tileset3D, {
            id: elementId,
            url,
        }),
        [viewId]: generateEntity(ViewClass.Lines, {
            id: viewId,
            element: elementId,
            color: DEFAULT_FLAT_COLOR,
            mode: LinesMode.Tubes,
            radius: TUBE_DEFAULT_RADIUS,
            width: LINE_DEFAULT_WIDTH,
            color_data: firstColorData,
        }),
        ...attributesSnapshot,
    };

    return snapshot;
}

import type { MetadataEntry, ExternalAllEntityState, Maybe } from '@local/webviz/dist/types/xyz';
import {
    UID_SUFFIXES,
    buildCategoryColormapMap,
    CATEGORY_SEED,
} from '@local/webviz/dist/utilities';
import { generateRandomColors, toSuffixUid } from '@local/webviz/dist/xyz';

export function categoryDataSnapshot(
    attributeId: string,
    lookupTable: MetadataEntry['metadata']['lookup_table'],
    getEntityState: (entityId: string) => Maybe<ExternalAllEntityState>,
) {
    const mappingId = toSuffixUid(attributeId, UID_SUFFIXES.MAPPING);
    const categoryMappingTitlesId = toSuffixUid(attributeId, UID_SUFFIXES.TITLES);
    const categoryMappingColorsId = toSuffixUid(attributeId, UID_SUFFIXES.COLORS);
    const categoryMappingDataId = toSuffixUid(attributeId, UID_SUFFIXES.DATA);

    const categoryData = lookupTable.map((e: { key: number }) => e.key);
    const categoryTitles = lookupTable.map((e: { value: string }) => e.value);
    const categoryVisibility = new Array(categoryData.length).fill(true);

    let categoryColormapMappingId;
    const attributeDataEntity = getEntityState(categoryMappingDataId);
    if (attributeDataEntity && 'mapping' in attributeDataEntity) {
        // case where the attribute mapping points to a colormap
        if (attributeDataEntity.mapping !== mappingId) {
            categoryColormapMappingId = attributeDataEntity.mapping;
        }
    }

    const snapshot = {
        [categoryMappingTitlesId]: {
            array: categoryTitles,
        },
        [mappingId]: {
            data_values: categoryData,
            visibility: categoryVisibility,
        },
    };

    if (!categoryColormapMappingId) {
        const categoryColor = generateRandomColors(
            categoryData.length,
            `${attributeId}_${CATEGORY_SEED}`,
            false,
        );
        return {
            [categoryMappingColorsId]: {
                array: categoryColor,
            },
            ...snapshot,
        };
    }

    // must make sure the colormap has data_values and the correct category titles, otherwise it can't be applied to scene
    const categoryColormapTitlesId = categoryColormapMappingId.replace(
        UID_SUFFIXES.MAPPING,
        UID_SUFFIXES.TITLES,
    );
    const categoryColormapColorsId = categoryColormapMappingId.replace(
        UID_SUFFIXES.MAPPING,
        UID_SUFFIXES.COLORS,
    );

    const colormapTitlesEntity = getEntityState(categoryColormapTitlesId);
    let colormapTitles;
    if (colormapTitlesEntity && 'array' in colormapTitlesEntity) {
        colormapTitles = colormapTitlesEntity.array;
    }

    let colormapColors;
    const colormapColorEntity = getEntityState(categoryColormapColorsId);
    if (colormapColorEntity && 'array' in colormapColorEntity) {
        colormapColors = colormapColorEntity.array;
    }

    const categoryColormapMap = buildCategoryColormapMap(
        categoryData,
        categoryTitles,
        colormapColors,
        colormapTitles,
    );
    const colorData = categoryColormapMap.map((item) => item.color);

    return {
        ...snapshot,
        [categoryColormapMappingId]: {
            data_values: categoryData,
            visibility: categoryVisibility,
        },
        [categoryColormapColorsId]: {
            array: colorData,
        },
        [categoryColormapTitlesId]: {
            array: categoryTitles,
        },
    };
}

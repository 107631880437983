import { AttributeMetaDataType } from '@local/webviz/dist/components/Properties/AttributesControl/AttributesControl.constants';
import { useBaseXyz } from '@local/webviz/dist/context';
import { HOLE_ID } from '@local/webviz/dist/context/constants';
import type { MetadataEntry, MetadataState, SelectionKeyValues } from '@local/webviz/dist/types';
import { niceNumber, parsePrefixUid } from '@local/webviz/dist/utilities';

export function useSelectionKeyValues(viewId: string): SelectionKeyValues {
    const getAttributeMetadata = () => {
        const viewEntity = getEntityState(viewId);
        if (viewEntity && 'element' in viewEntity && viewEntity.element) {
            const elementEntity = getEntityState(viewEntity.element);
            if (elementEntity && 'metadata' in elementEntity) {
                const { metadata } = elementEntity;
                if (metadata && 'attributes_metadata' in metadata && metadata.attributes_metadata) {
                    const { attributes_metadata: attributesMetadata } = metadata;
                    return attributesMetadata;
                }
            }
        }
        return {};
    };

    const getAttributeMetadataMap = () => {
        const attributesMetadata: MetadataState = getAttributeMetadata();

        Object.values(attributesMetadata).forEach((attributeData: MetadataEntry) => {
            if (
                attributeData.metadata?.type === AttributeMetaDataType.Category &&
                attributeData.metadata?.lookup_table &&
                Array.isArray(attributeData.metadata.lookup_table)
            ) {
                const lookupTableMap = Object.fromEntries(
                    attributeData.metadata.lookup_table.map(
                        (data: { key: string; value: string }) => [data.key, data.value],
                    ),
                );
                // eslint-disable-next-line no-param-reassign
                attributeData.metadata.lookup_table = lookupTableMap;
            }
        });
        return attributesMetadata;
    };

    const getAttributeDetails = () => {
        const selectionAttributeData = getSelectionTool().getSelectionAttribute();

        const attributesKeyValues = Object.entries(selectionAttributeData).map(([key, value]) => {
            if (!key) {
                return null;
            }

            if (key === HOLE_ID) {
                return [HOLE_ID, value] as [string, string];
            }

            const attributeId = parsePrefixUid(key);
            const attributeMetadata = attributesMetadata[attributeId];
            const attributeName = attributeMetadata?.metadata?.name;
            const attributeType = attributeMetadata?.metadata?.type;
            if (!attributeName || !attributeType) {
                return null;
            }

            if (attributeType === AttributeMetaDataType.Category) {
                if (value === -1) {
                    return null;
                }
                // eslint-disable-next-line no-param-reassign
                value = attributeMetadata.metadata.lookup_table[value];
            }

            if (!value && value !== 0) {
                return null;
            }

            if (typeof value === 'number') {
                // eslint-disable-next-line no-param-reassign
                value = niceNumber(value);
            }

            return [attributeName, value] as [string, string];
        });

        return attributesKeyValues
            .filter((entry) => entry !== null)
            .sort(([keyA], [keyB]) => {
                if (keyA === HOLE_ID) return -1;
                if (keyB === HOLE_ID) return 1;
                return keyA.localeCompare(keyB);
            });
    };

    const { getSelectionTool, getEntityState } = useBaseXyz();
    if (!viewId) {
        return [];
    }

    const attributesMetadata = getAttributeMetadataMap();

    return getAttributeDetails();
}

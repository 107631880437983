import type { CesiumWidget } from '@cesium/engine';
import { useCallback } from 'react';

export function useResetClusters(
    cesiumWidget: CesiumWidget,
    setClusterReset: (reset: boolean) => void,
) {
    const resetClusters = useCallback(() => {
        if (!cesiumWidget) return;

        setClusterReset(true);
        const { entities } = cesiumWidget;
        entities.show = true;
    }, [cesiumWidget, setClusterReset]);

    return resetClusters;
}

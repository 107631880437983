import { trackError } from '@local/metrics';
import { parsePrefixUid } from '@local/webviz/dist/utilities';
import { ArrayTypes } from '@local/webviz/dist/xyz';
import type { TypedArray } from 'apache-arrow/interfaces';
import { useState } from 'react';

import { parseParquetBuffer, processQueryResult } from 'src/duckDB/duckDB';
import { getGooseObjectById } from 'src/store/goose/selectors';
import { useAppSelector } from 'src/store/store';
import { ATTRIBUTE_ID_PREFFIX } from 'src/visualization/constants';

function computeAttributeFrequencyPercent(data: TypedArray) {
    const frequencyMap = new Map<number, number>();
    data.forEach((value) => {
        frequencyMap.set(value, (frequencyMap.get(value) ?? 0) + 1 / data.length);
    });

    return frequencyMap;
}

export function useColormapHistogram(viewId: string, selectedAttributeId: string) {
    const objectId = parsePrefixUid(viewId);
    const selectedObject = useAppSelector(getGooseObjectById(objectId));
    const [isFetching, setIsFetching] = useState(false);
    const [frequencyMap, setFrequencyMap] = useState<Map<number, number>>(new Map());
    const [hasError, setHasError] = useState(false);

    const fetchAndParseParquetFile = async () => {
        if (!selectedObject) {
            return;
        }
        try {
            setIsFetching(true);
            setHasError(false);
            const attributeDataId = parsePrefixUid(selectedAttributeId).replace(
                ATTRIBUTE_ID_PREFFIX,
                '',
            );
            const attributeDataLink = selectedObject?.links.data?.find(
                (link) => link.name === attributeDataId,
            );

            if (attributeDataLink?.download_url) {
                const parquet = await parseParquetBuffer(attributeDataLink.download_url);
                const results = processQueryResult(parquet, ArrayTypes.Float64);
                const frequencyPercentMap = computeAttributeFrequencyPercent(results);
                setFrequencyMap(frequencyPercentMap);
            } else {
                trackError('Attribute data link not found');
                setHasError(true);
            }
        } catch (err) {
            trackError('Error fetching and parsing parquet file', JSON.stringify(err));
            setHasError(true);
        } finally {
            setIsFetching(false);
        }
    };

    return { isFetching, frequencyMap, hasError, fetchAndParseParquetFile };
}

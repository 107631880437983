import * as duckdb from '@duckdb/duckdb-wasm';
// eslint-disable-next-line import/extensions
import eh_worker from '@duckdb/duckdb-wasm/dist/duckdb-browser-eh.worker.js?url';
// eslint-disable-next-line import/extensions
import mvp_worker from '@duckdb/duckdb-wasm/dist/duckdb-browser-mvp.worker.js?url';
import duckdb_wasm_eh from '@duckdb/duckdb-wasm/dist/duckdb-eh.wasm?url';
import duckdb_wasm from '@duckdb/duckdb-wasm/dist/duckdb-mvp.wasm?url';

let dbSingleton: duckdb.AsyncDuckDB;

// Reuse the same db instance
export async function getDB() {
    if (dbSingleton === undefined) {
        dbSingleton = await initializeDuckDB();
    }
    return dbSingleton;
}

// Retrieve DuckDB bundle
// https://www.npmjs.com/package/@duckdb/duckdb-wasm
async function initializeDuckDB() {
    const MANUAL_BUNDLES: duckdb.DuckDBBundles = {
        mvp: {
            mainModule: duckdb_wasm,
            mainWorker: mvp_worker,
        },
        eh: {
            mainModule: duckdb_wasm_eh,
            mainWorker: eh_worker,
        },
    };

    // Select a bundle based on browser checks
    const bundle = await duckdb.selectBundle(MANUAL_BUNDLES);

    if (!bundle.mainWorker) {
        throw new Error('mainWorker is not defined');
    }

    // Instantiate the asynchronous version of DuckDB-wasm
    const worker = new Worker(bundle.mainWorker!);
    const logger = new duckdb.ConsoleLogger();
    const db = new duckdb.AsyncDuckDB(logger, worker);
    await db.instantiate(bundle.mainModule, bundle.pthreadWorker);

    return db;
}

import { useState } from 'react';

import { useFileHandler } from 'src/hooks/useFileHandler';

export const useFileDragAndDrop = (enabled: boolean = true) => {
    const [isHighlighted, setIsHighlighted] = useState(false);
    const { upsertFilesByPath } = useFileHandler();

    const onDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        if (enabled) {
            event.preventDefault();
            event.stopPropagation();
            if (!isHighlighted) {
                setIsHighlighted(true);
            }
        }
    };

    const onDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
        if (enabled) {
            event.preventDefault();
            event.stopPropagation();
            setIsHighlighted(true);
        }
    };

    const onDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
        if (enabled) {
            event.preventDefault();
            event.stopPropagation();
            setIsHighlighted(false);
        }
    };

    const onDrop = async (event: React.DragEvent<HTMLInputElement>) => {
        if (!enabled) {
            return;
        }

        event.preventDefault();
        event.stopPropagation();
        setIsHighlighted(false);
        const currentFiles = event.dataTransfer.files;
        if (!currentFiles) {
            return;
        }

        await upsertFilesByPath(currentFiles);
    };

    return { isHighlighted, onDrop, onDragEnter, onDragLeave, onDragOver };
};

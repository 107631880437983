import { checkIsAdmin } from '@local/login';
import type { RoleEnum } from '@local/workspaces/dist/apiClients/GENERATED_workspaceClientEndpoints';
import { fetchWorkSpaceData } from '@local/workspaces/src/apiClients/workspaceClientEndpoints';
import { getSelectedWorkspaceFromParams } from '@local/workspaces/src/components/OrgRouteGuard/OrgRouteGuard';
import { createContext, useContext, useMemo, useState } from 'react';
import { useParams } from 'react-router';

interface Context {
    workspaceId: string;
    workspaceUserRole?: RoleEnum;
    objectFilters: Record<string, string | undefined>;
    setObjectFilters: (filters: Record<string, string | undefined>) => void;
}

// Context used to store information for a specific workspace.
// When the workspace changes, the context values should be reset.
export const WorkspaceContext = createContext<Context>({
    workspaceId: '',
    objectFilters: {},
    setObjectFilters: () => {},
    workspaceUserRole: undefined,
});

export const useWorkspaceContext = () => useContext(WorkspaceContext);

interface Props {
    children: React.ReactNode;
}

export const WorkspaceProvider = ({ children }: Props) => {
    const params = useParams();
    const isAdmin = checkIsAdmin();
    const [objectFilters, setObjectFilters] = useState({});

    const { data: workspace } = fetchWorkSpaceData(params, isAdmin);
    const workspaceUserRole = workspace?.current_user_role ?? undefined;
    const workspaceId = getSelectedWorkspaceFromParams(params);

    return (
        <WorkspaceContext.Provider
            value={useMemo(
                () => ({ objectFilters, setObjectFilters, workspaceUserRole, workspaceId }),
                [objectFilters, setObjectFilters, workspaceUserRole, workspaceId],
            )}
        >
            {children}
        </WorkspaceContext.Provider>
    );
};

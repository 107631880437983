import {
    ScreenSpaceEventHandler,
    ScreenSpaceEventType,
    Label,
    Cartesian3,
    Cartographic,
    Entity,
    Billboard,
    Primitive,
} from '@cesium/engine';
import { useEffect } from 'react';

import { getClusterCount, removeClusterCount } from '../Points/Utils/ClusterUtil';

export function useHandlePrimitiveClick(
    cesiumWidget: any,
    resetClusters: () => void,
    setSelectedCluster: (cluster: any) => void,
    myClusters: any[],
) {
    const CLUSTER_SIZE_THRESHOLD = 7;
    const ZOOM_IN_THRESHOLD = 200000;
    const ZOOM_DURATION = 0.5;
    const ZOOM_FACTOR = 5;

    const clusterClick = (primitivePosition: Cartesian3) => {
        const offsetDistance = 0.5;

        const offset = Cartesian3.multiplyByScalar(
            Cartesian3.normalize(primitivePosition, new Cartesian3()),
            offsetDistance,
            new Cartesian3(),
        );
        const offsetPosition = Cartesian3.add(primitivePosition, offset, new Cartesian3());

        const offsetCartographicPosition = Cartographic.fromCartesian(offsetPosition);

        cesiumWidget.camera.flyTo({
            destination: Cartesian3.fromRadians(
                offsetCartographicPosition.longitude,
                offsetCartographicPosition.latitude,
                cesiumWidget.camera.positionCartographic.height * (1 / ZOOM_FACTOR),
            ),
            duration: ZOOM_DURATION,
            complete: () => {
                // eslint-disable-next-line no-param-reassign
                primitivePosition = cesiumWidget.camera.position;
            },
        });
    };

    useEffect(() => {
        if (!cesiumWidget) return;

        const handler = new ScreenSpaceEventHandler(cesiumWidget.scene.canvas);

        handler.setInputAction((click: any) => {
            const pickedObject = cesiumWidget.scene.pick(click.position);

            resetClusters();

            if (
                pickedObject?.primitive instanceof Primitive ||
                pickedObject?.primitive instanceof Label ||
                pickedObject?.primitive instanceof Entity ||
                pickedObject?.primitive instanceof Billboard
            ) {
                if (
                    Number(getClusterCount(pickedObject.id.id)) <= CLUSTER_SIZE_THRESHOLD &&
                    cesiumWidget.camera.positionCartographic.height < ZOOM_IN_THRESHOLD
                ) {
                    pickedObject.id.show = false;
                    setSelectedCluster(
                        myClusters.find(
                            (cluster) => cluster.id === removeClusterCount(pickedObject.id.id),
                        ),
                    );
                } else {
                    clusterClick(pickedObject.primitive.position);
                }
            } else {
                setSelectedCluster(null);
                resetClusters();
            }
        }, ScreenSpaceEventType.LEFT_CLICK);

        // eslint-disable-next-line consistent-return
        return () => {
            handler.destroy();
        };
    }, [cesiumWidget, resetClusters, setSelectedCluster, myClusters]);
}

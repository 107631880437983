import type { OrgListedObject } from '@api/goose/dist/enhancedGooseClient';
import { extractObjectSchemaName } from '@api/goose/dist/utils/extractObjectSchemaName';
import { Cartesian3, type CesiumWidget, Math as CesiumMath } from '@cesium/engine';
import { ArrowRight } from '@local/web-design-system-2/dist/icons';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import classnames from 'classnames';
import { useEffect, useState } from 'react';

import { useDiscoverContext } from 'src/contexts/DiscoverContext';
import { useSearchInteractionContext } from 'src/contexts/SearchInteractionContext';
import { OBJECTS, RESULTS } from 'src/strings';
import { formatObjectSchema } from 'src/utils/objectUtils';

import { useRemoveRectangleGeometries } from '../../Hooks/useRemoveRectangleGeometries';
import { findBoxCenter } from '../../Points/Clustering/Clustering';
import { ResultGroup } from './ResultGroup';
import { useStyles } from './ResultsPanel.styles';

interface ResultsPanelProps {
    cesiumWidget: CesiumWidget;
}

export function ResultsPanel({ cesiumWidget }: ResultsPanelProps) {
    const { classes } = useStyles();
    const [expanded, setExpanded] = useState('');
    const [showPanel, setShowPanel] = useState(true);
    const { objects, activeObject, setActiveObject } = useDiscoverContext();

    const { setHoveredObject } = useSearchInteractionContext();

    const toggleShowPanel = () => {
        setShowPanel((isHidden) => !isHidden);
    };

    const groupedObjects = objects.reduce(
        (acc, object) => {
            const schemaType = extractObjectSchemaName(object.schema);
            const collection = acc?.[schemaType] ?? [];
            return { ...acc, [schemaType]: [...collection, object] };
        },
        {} as Record<string, OrgListedObject[]>,
    );

    const onAccordionClick = (schemaType: string) => {
        setExpanded((prev) => (prev === schemaType ? '' : schemaType));
    };
    const resetBounding = useRemoveRectangleGeometries(cesiumWidget);

    const onObjectRowclick = (objectID: string) => {
        if (activeObject?.object_id === objectID) {
            setActiveObject('');
            return;
        }
        if (activeObject) {
            resetBounding();
            setActiveObject('');
        }

        const object = objects.find((obj) => obj.object_id === objectID);
        if (object && object.geojson_bounding_box) {
            const destination = Cartesian3.fromDegrees(
                findBoxCenter(object.geojson_bounding_box.coordinates[0]).lng,
                findBoxCenter(object.geojson_bounding_box.coordinates[0]).lat,
                cesiumWidget.camera.positionCartographic.height,
            );
            cesiumWidget.camera.flyTo({
                destination,
                orientation: {
                    heading: 0.0,
                    pitch: -CesiumMath.PI_OVER_TWO,
                    roll: 0.0,
                },
                complete: () => {
                    setActiveObject(objectID);
                },
                duration: 1,
            });
        }
    };

    const onObjectRowHover = (objectID: string, isHovering: boolean) => {
        setHoveredObject(isHovering ? objectID : '');
    };

    useEffect(() => {
        if (activeObject && showPanel) {
            const schemaType = formatObjectSchema(activeObject.schema);
            setExpanded(schemaType);
        }
    }, [activeObject]);

    return (
        <Grid container className={classes.base} direction="column" wrap="nowrap">
            {objects.length > 0 && (
                <>
                    <Grid
                        item
                        container
                        alignItems="center"
                        className={classnames(classes.resultsTitleRow, {
                            [classes.resultsTitleRowExpanded]: showPanel,
                        })}
                    >
                        <Grid item flexGrow={1}>
                            <Typography className={classes.resultsTitleText}>{RESULTS}</Typography>
                        </Grid>
                        <Grid item>
                            <Typography className={classes.resultCountText}>
                                {objects.length} {OBJECTS}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <IconButton className={classes.chevronButton} onClick={toggleShowPanel}>
                                <ArrowRight
                                    fontSize="small"
                                    sx={{
                                        transition: 'transform 0.3s',
                                        transform: showPanel ? 'rotate(+90deg)' : 'rotate(-90deg)',
                                        color: 'white',
                                    }}
                                />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Collapse in={showPanel} className={classes.collapseContainer}>
                        <Grid
                            item
                            container
                            className={classes.objectContainer}
                            direction="column"
                            wrap="nowrap"
                        >
                            {Object.keys(groupedObjects).map((schemaType) => (
                                <ResultGroup
                                    key={schemaType}
                                    schemaType={schemaType}
                                    onChange={onAccordionClick}
                                    onObjectHover={onObjectRowHover}
                                    onObjectClick={onObjectRowclick}
                                    expanded={expanded === schemaType}
                                    objects={groupedObjects[schemaType]}
                                    activeObjectID={activeObject?.object_id ?? ''}
                                />
                            ))}
                        </Grid>
                    </Collapse>
                </>
            )}
        </Grid>
    );
}

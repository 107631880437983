import { SideToolbar } from '../SideToolbar/SideToolbar';
import { LegendPanel } from './LegendPanel/LegendPanel';

export function Overlays() {
    return (
        <>
            <SideToolbar />
            <LegendPanel />
        </>
    );
}

import type { Schema } from '@local/workspaces/src/apiClients/workspaceClientEndpoints';

import {
    blockmodelUUID,
    designUUID,
    downholeUUID,
    ellipsoidUUID,
    geoStatisticsDistributionsUUID,
    geoStatisticsVariogramsUUID,
    geologicalModelUUID,
    geophysicsUUID,
    gridsUUID,
    lastMonthUUID,
    lastWeekUUID,
    linesUUID,
    meshUUID,
    olderUUID,
    pointsUUID,
    yesterdayUUID,
} from './mockUUIDs';

export const objectTypeAffinitySchema: Schema = {
    name: 'Data type',
    lastUpdated: 'Mon Aug 7 2023 14:10:24 GMT+1200 (New Zealand Standard Time)',
    folders: [
        {
            id: blockmodelUUID,
            name: 'Block Model',
            description: 'BlockSync reference objects',
            filters: [
                {
                    property: 'schema',
                    condition: 'equals',
                    comparison: ['blocksync-reference'],
                },
            ],
            subFolders: [],
        },
        {
            id: designUUID,
            name: 'Designs',
            description: '2D/3D geometry',
            filters: [
                {
                    property: 'schema',
                    condition: 'equals',
                    comparison: ['design-geometry'],
                },
            ],
            subFolders: [],
        },
        {
            id: downholeUUID,
            name: 'Downhole',
            filters: [
                {
                    property: 'schema',
                    condition: 'equals',
                    comparison: [
                        'downhole-collection',
                        'exploratory-location',
                        'downhole-intervals',
                        'interval-downholes',
                    ],
                },
            ],
            subFolders: [],
        },
        {
            id: ellipsoidUUID,
            name: 'Ellipsoid',
            description: 'Local and Global Ellipsoid Models',
            filters: [
                {
                    property: 'schema',
                    condition: 'equals',
                    comparison: ['local-ellipsoids', 'global-ellipsoid'],
                },
            ],
            subFolders: [],
        },
        {
            id: geophysicsUUID,
            name: 'Geophysics',
            filters: [
                {
                    property: 'schema',
                    condition: 'equals',
                    comparison: ['geophysical-records-1d'],
                },
            ],
            subFolders: [],
        },
        {
            id: linesUUID,
            name: 'Lines',
            filters: [
                {
                    property: 'schema',
                    condition: 'equals',
                    comparison: ['line-segments'],
                },
            ],
            subFolders: [],
        },
        {
            id: geoStatisticsDistributionsUUID,
            name: 'Geostatistics/Distributions',
            description: 'A description of a non parametric continuous cumulative distribution',
            filters: [
                {
                    property: 'schema',
                    condition: 'equals',
                    comparison: ['non-parametric-continuous-cumulative-distribution'],
                },
            ],
            subFolders: [],
        },
        {
            id: pointsUUID,
            name: 'Points',
            filters: [
                {
                    property: 'schema',
                    condition: 'equals',
                    comparison: ['pointset'],
                },
            ],
            subFolders: [],
        },
        {
            id: gridsUUID,
            name: 'Grids',
            filters: [
                {
                    property: 'schema',
                    condition: 'equals',
                    comparison: [
                        'regular-2d-grid',
                        'regular-3d-grid',
                        'regular-masked-3d-grid',
                        'tensor-2d-grid',
                        'tensor-3d-grid',
                        'unstructured-grid',
                        'unstructured-hex-grid',
                        'unstructured-quad-grid',
                        'unstructured-tet-grid',
                    ],
                },
            ],
            subFolders: [],
        },
        {
            id: meshUUID,
            name: 'Mesh',
            filters: [
                {
                    property: 'schema',
                    condition: 'equals',
                    comparison: ['triangle-mesh'],
                },
            ],
            subFolders: [],
        },
        {
            id: geoStatisticsVariogramsUUID,
            name: 'Geostatistics/Variograms',
            description:
                'Variogram model and associated metadata. The variogram model is defined by the nugget, and multiple structures using the leapfrog-convention rotation. See structure and rotation components for additional details.',
            filters: [
                {
                    property: 'schema',
                    condition: 'equals',
                    comparison: ['variogram'],
                },
            ],
            subFolders: [],
        },
        {
            id: geologicalModelUUID,
            name: 'Geological model',
            description: 'Geological model',
            filters: [
                {
                    property: 'schema',
                    condition: 'equals',
                    comparison: ['geological-model-meshes'],
                },
            ],
            subFolders: [],
        },
    ],
};

export const timeBasedAffinitySchema: Schema = {
    name: 'Last updated',
    lastUpdated: 'Mon Aug 7 2023 14:10:24 GMT+1200 (New Zealand Standard Time)',
    folders: [
        {
            id: yesterdayUUID,
            name: 'Last 24 hours',
            description: 'Objects uploaded or edited in the last 24 hours',
            filters: [
                {
                    property: 'created_at',
                    condition: 'greaterThan',
                    comparison: ['1d'], // time based filters work on d not w or m please use 1d 2d 30d etc
                },
            ],
            subFolders: [],
        },
        {
            id: lastWeekUUID,
            name: 'Last week',
            description: 'Objects uploaded or edited in the last 7 days',
            filters: [
                {
                    property: 'created_at',
                    condition: 'greaterThan',
                    comparison: ['7d'], // todo figure out how we're gonna handle dynamic filters like this
                },
            ],
            subFolders: [],
        },
        {
            id: lastMonthUUID,
            name: 'Last month',
            description: 'Objects uploaded or edited in the last month',
            filters: [
                {
                    property: 'created_at',
                    condition: 'greaterThan',
                    comparison: ['30d'], // todo figure out how we're gonna handle dynamic filters like this
                },
            ],
            subFolders: [],
        },
        {
            id: olderUUID,
            name: 'Older than a month',
            description: 'Objects uploaded or edited more than a month ago',
            filters: [
                {
                    property: 'created_at',
                    condition: 'lessThan',
                    comparison: ['30d'], // todo figure out how we're gonna handle dynamic filters like this
                },
            ],
            subFolders: [],
        },
    ],
};

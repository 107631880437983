import type { OrgListedObject } from '@api/goose/dist/enhancedGooseClient';
import { useGetObjectByIdQuery } from '@api/goose/dist/enhancedGooseClient';
import type { CesiumWidget } from '@cesium/engine';
import { Rectangle } from '@cesium/engine';
import { Close, Open } from '@local/web-design-system-2/dist/icons';
import {
    getHubForCurrentOrg,
    getOrgUuidFromParams,
} from '@local/workspaces/dist/components/OrgRouteGuard/OrgRouteGuard';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import { useFlags } from 'launchdarkly-react-client-sdk';
import * as L from 'leaflet';
import type { ReactNode } from 'react';
import { FormattedDate } from 'react-intl';
import { useNavigate, useParams } from 'react-router';

import { useDiscoverContext } from 'src/contexts/DiscoverContext';
import {
    METADATA_DESCRIPTION_TITLE,
    METADATA_FIELD_CREATED_BY,
    METADATA_FIELD_CREATED_ON,
    METADATA_FIELD_CRS,
    METADATA_FIELD_LAST_MODIFIED,
    METADATA_FIELD_MODIFIED_BY,
    METADATA_FIELD_TYPE,
    METADATA_FIELD_WORKSPACE,
    NO_ACCESS_DESCRIPTION,
    WEBVIZ_BUTTON_DISCOVERY,
} from 'src/strings';
import { WEBVIZ_VIEWER } from 'src/urls';
import { isObjectViewable } from 'src/utils/schemaUtils';

import { OverflowTooltip } from '../../../../../components/overflowTooltip/OverflowTooltip';
import { formatObjectName, formatObjectSchema } from '../../../../../utils/objectUtils';
import { useStyles, useToolTipStyles } from './MetadataPanel.styles';

interface MetadataFieldProps {
    title: string;
    value: string | ReactNode;
}

function MetadataField({ title, value }: MetadataFieldProps) {
    const { classes } = useStyles();
    return (
        <Grid item container direction="row" wrap="nowrap">
            <Grid item xs={4} flexShrink={0}>
                <Typography className={classes.metadataFieldText}>{title}:</Typography>
            </Grid>
            <Grid item flexGrow={1} wrap="nowrap" overflow="hidden">
                <Typography className={classes.metadataFieldText} noWrap>
                    {value}
                </Typography>
            </Grid>
        </Grid>
    );
}

function getRelativeEvoViewerPath(activeObject: OrgListedObject) {
    const currentHub = getHubForCurrentOrg();
    return `workspaces/${currentHub.code}/${activeObject.workspace_id}/${WEBVIZ_VIEWER}?id=${activeObject.object_id}`;
}

interface MetadataPanelProps {
    cesiumWidget: CesiumWidget;
    setFlyToInProgress: (flyToInProgress: boolean) => void;
}

export function MetadataPanel({ cesiumWidget, setFlyToInProgress }: MetadataPanelProps) {
    const { activeObject, setActiveObject } = useDiscoverContext();
    const { classes } = useStyles();
    const { classes: toolTipClasses } = useToolTipStyles();
    const params = useParams();
    const featureFlags = useFlags();

    const navigate = useNavigate();

    const { data: gooseData, isFetching } = useGetObjectByIdQuery(
        {
            objectId: activeObject?.object_id ?? '',
            orgId: getOrgUuidFromParams(params),
            workspaceId: activeObject?.workspace_id ?? '',
            includeVersions: false,
        },
        { skip: !activeObject },
    );

    if (!activeObject) {
        return null;
    }

    const zoomToExtents = () => {
        if (!activeObject?.geojson_bounding_box) {
            return;
        }
        // GOOSE API returns lng,lat, but Leaflet requires lat,lng
        const bboxLatLong: L.LatLngTuple[] = activeObject.geojson_bounding_box.coordinates[0].map(
            (lngLat) => [lngLat[1], lngLat[0]],
        );
        const latLongBounds = new L.LatLngBounds(bboxLatLong);
        setFlyToInProgress(true);
        cesiumWidget.camera.flyTo({
            destination: Rectangle.fromDegrees(
                latLongBounds.getWest(),
                latLongBounds.getSouth(),
                latLongBounds.getEast(),
                latLongBounds.getNorth(),
            ),
            complete: () => {
                setFlyToInProgress(false);
            },
        });
    };

    const objectDescription = activeObject.workspace_access
        ? (gooseData?.object?.description ?? 'No description available')
        : NO_ACCESS_DESCRIPTION;

    const hub = getHubForCurrentOrg();

    return (
        <Grid
            container
            className={classes.base}
            direction="column"
            automation-id="object-metadata-panel"
        >
            <Grid item container direction="row" wrap="nowrap" alignItems="center" gap={1}>
                <Grid item overflow="hidden" flexGrow={1}>
                    <OverflowTooltip
                        arrow
                        placement="top-start"
                        classes={toolTipClasses}
                        title={formatObjectName(activeObject.name)}
                    >
                        <Typography className={classes.objectNameText} noWrap>
                            {formatObjectName(activeObject.name)}
                        </Typography>
                    </OverflowTooltip>
                </Grid>
                <Grid item>
                    <IconButton
                        size="small"
                        classes={{ root: classes.closeButton }}
                        onClick={() => setActiveObject('')}
                    >
                        <Close className={classes.closeIcon} />
                    </IconButton>
                </Grid>
            </Grid>
            <Grid item container className={classes.detailsBase} gap={2} direction="column">
                <Grid item container direction="column" gap={2}>
                    <Grid
                        item
                        container
                        className={classes.descriptionContainer}
                        direction="column"
                        flexWrap="nowrap"
                    >
                        <Grid item>
                            <Typography className={classes.descriptionTitleText}>
                                {METADATA_DESCRIPTION_TITLE}
                            </Typography>
                        </Grid>
                        <Grid item className={classes.descriptionTextContainer}>
                            <Typography
                                variant="body1"
                                className={classes.descriptionText}
                                automation-id="object-metadata-panel-description"
                            >
                                {activeObject.workspace_access && isFetching ? (
                                    <div>
                                        <Skeleton
                                            variant="text"
                                            width="100%"
                                            className={classes.descriptionSkeleton}
                                        />
                                        <Skeleton
                                            variant="text"
                                            width="60%"
                                            className={classes.descriptionSkeleton}
                                        />
                                    </div>
                                ) : (
                                    objectDescription
                                )}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Divider className={classes.divider} />
                <Grid container direction="column">
                    <MetadataField
                        title={METADATA_FIELD_TYPE}
                        value={formatObjectSchema(activeObject.schema)}
                    />
                    <MetadataField
                        title={METADATA_FIELD_CREATED_BY}
                        value={activeObject.created_by?.name}
                    />
                    <MetadataField
                        title={METADATA_FIELD_CREATED_ON}
                        value={
                            <FormattedDate
                                value={activeObject.created_at}
                                month="short"
                                year="numeric"
                                day="2-digit"
                            />
                        }
                    />
                    <MetadataField
                        title={METADATA_FIELD_MODIFIED_BY}
                        value={activeObject.modified_by?.name}
                    />

                    <MetadataField
                        title={METADATA_FIELD_LAST_MODIFIED}
                        value={
                            <FormattedDate
                                value={activeObject.modified_at}
                                month="short"
                                year="numeric"
                                day="2-digit"
                            />
                        }
                    />

                    {activeObject.workspace_name && (
                        <Grid item container direction="row" wrap="nowrap">
                            <Grid item xs={4} flexShrink={0}>
                                <Typography className={classes.metadataFieldText}>
                                    {METADATA_FIELD_WORKSPACE}:
                                </Typography>
                            </Grid>
                            <Grid item flexGrow={1} wrap="nowrap" overflow="hidden">
                                <Link
                                    style={{
                                        textDecoration: 'underline',
                                        textDecorationThickness: '1px',
                                        textDecorationColor: 'white',
                                    }}
                                    href={`/workspaces/${hub.code}/${activeObject.workspace_id}/overview?id=${activeObject.object_id}&name=${activeObject.name}`}
                                >
                                    <Typography className={classes.metadataFieldText} noWrap>
                                        {activeObject.workspace_name}
                                    </Typography>
                                </Link>
                            </Grid>
                        </Grid>
                    )}

                    {!isFetching && (
                        <MetadataField
                            title={METADATA_FIELD_CRS}
                            value={
                                gooseData?.object?.coordinate_reference_system.epsg_code
                                    ? `EPSG ${gooseData.object.coordinate_reference_system.epsg_code}`
                                    : 'Local'
                            }
                        />
                    )}
                </Grid>
                <Button
                    fullWidth
                    size="large"
                    variant="contained"
                    classes={{ root: classes.zoomButton }}
                    onClick={zoomToExtents}
                >
                    <Typography className={classes.zoomButtonText}>Zoom to extents</Typography>
                </Button>
            </Grid>
            {activeObject.workspace_access &&
                gooseData &&
                isObjectViewable(gooseData.object, featureFlags) && (
                    <Button
                        fullWidth
                        size="large"
                        variant="contained"
                        classes={{ root: classes.webvizButton }}
                        onClick={() => {
                            navigate(`../${getRelativeEvoViewerPath(activeObject)}`);
                        }}
                        automation-id="object-metadata-panel-evo-viewer-button"
                    >
                        <Open className={classes.evoViewerButtonIcon} />
                        <Typography className={classes.evoViewerButtonText}>
                            {WEBVIZ_BUTTON_DISCOVERY}
                        </Typography>
                    </Button>
                )}
        </Grid>
    );
}

import { useMessagesContext } from '@local/messages-wds2/dist/MessagesContext';
import { trackError } from '@local/metrics/dist/src/metrics';
import {
    getOrgUuidFromParams,
    getSelectedWorkspaceFromParams,
} from '@local/workspaces/dist/components/OrgRouteGuard/OrgRouteGuard';
import type { SerializedError } from '@reduxjs/toolkit';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { useParams } from 'react-router';
import { v4 as uuid } from 'uuid';

import {
    useCustomUploadFileByIdMutation,
    useCustomUpsertFileByPathMutation,
} from 'src/apiClients/customFileEndpoints';
import type { DownloadFileResponse } from 'src/apiClients/GENERATED_fileClientEndpoints';
import { useFileUploadContainer } from 'src/hooks/useFileUploadContainer';
import { CANCEL_UPLOADING_FILE, ERROR_UPLOADING_FILE } from 'src/strings';
import type { FileStatus } from 'src/types/files';
import { UploadStatus } from 'src/types/files';

export const useFileHandler = () => {
    const params = useParams();
    const organisationId = getOrgUuidFromParams(params);
    const workspaceId = getSelectedWorkspaceFromParams(params);

    const { addMessage } = useMessagesContext();
    const [updateFileByPath] = useCustomUpsertFileByPathMutation();
    const [updateFileById] = useCustomUploadFileByIdMutation();
    const { upsertFileStatus } = useFileUploadContainer({ workspaceId });

    const handleFileStatus = (
        currentFileStatus: FileStatus,
        status: UploadStatus,
        percent: number,
    ) => {
        upsertFileStatus({
            ...currentFileStatus,
            uploadStatus: status,
            percentCompleted: percent,
        });
    };

    const handleUploadFile = async (
        file: File,
        currentFileStatus: FileStatus,
        oldFile: DownloadFileResponse | null = null,
    ) => {
        const response = oldFile
            ? await updateFileById({
                  workspaceId,
                  organisationId,
                  fileId: oldFile.file_id,
                  updatedVersion: file as File,
                  updateFileStatus: (status, percent) =>
                      handleFileStatus(currentFileStatus, status, percent),
                  abortSignal: currentFileStatus.abortController.signal,
              })
            : await updateFileByPath({
                  workspaceId,
                  organisationId,
                  filePath: `${file.name}`,
                  uploadFile: file,
                  updateFileStatus: (status, percent) =>
                      handleFileStatus(currentFileStatus, status, percent),
                  abortSignal: currentFileStatus.abortController.signal,
              });

        if (!currentFileStatus.abortController?.signal.aborted) {
            upsertFileStatus({
                ...currentFileStatus,
                uploadStatus: 'error' in response ? UploadStatus.Failed : UploadStatus.Uploaded,
                percentCompleted: 100,
            });
        }

        return response;
    };

    const handleError = async (
        error: FetchBaseQueryError | SerializedError,
        file: File,
        currentFileStatus: FileStatus,
    ) => {
        upsertFileStatus({
            ...currentFileStatus,
            uploadStatus: UploadStatus.Failed,
        });
        addMessage({
            message: ERROR_UPLOADING_FILE,
            severity: 'error',
        });
        trackError(`Error: ${error} uploading file "${file.name}"`);
        return Promise.reject(error);
    };

    const handleCancel = () => {
        addMessage({
            message: CANCEL_UPLOADING_FILE,
            severity: 'success',
            color: 'info',
        });
        return Promise.resolve();
    };

    const setupFileStatus = (file: File) => {
        const fileId = uuid();
        const currentFileStatus: FileStatus = {
            fileName: file.name,
            size: file.size,
            uploadStatus: UploadStatus.Uploading,
            fileId,
            percentCompleted: 0,
            abortController: new AbortController(),
        };

        upsertFileStatus(currentFileStatus);

        return currentFileStatus;
    };

    const handleResponse = async (
        response: any,
        currentFileStatus: FileStatus,
        currentFile: File,
    ) => {
        if (currentFileStatus.abortController.signal.aborted) {
            return handleCancel();
        }
        if ('error' in response && response.error) {
            return handleError(response.error, currentFile, currentFileStatus);
        }

        return Promise.resolve(response);
    };

    const uploadFileById = async (
        currentFile: File,
        oldFile: DownloadFileResponse,
        uploadFieldRef?: React.RefObject<HTMLInputElement>,
    ) => {
        const currentFileStatus = setupFileStatus(currentFile);

        if (uploadFieldRef && uploadFieldRef.current) {
            const uploadField = uploadFieldRef.current;
            uploadField.value = '';
        }

        const response = await handleUploadFile(currentFile, currentFileStatus, oldFile);

        handleResponse(response, currentFileStatus, currentFile);
    };

    const upsertFilesByPath = async (
        currentFiles: FileList,
        uploadFieldRef?: React.RefObject<HTMLInputElement>,
    ) =>
        Array.from(currentFiles).forEach(async (file) => {
            const currentFileStatus = setupFileStatus(file);

            if (uploadFieldRef && uploadFieldRef.current) {
                const uploadField = uploadFieldRef.current;
                uploadField.value = '';
            }

            const response = await handleUploadFile(file, currentFileStatus);

            handleResponse(response, currentFileStatus, file);
        });

    return { uploadFileById, upsertFilesByPath };
};

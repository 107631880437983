import { SidebarProvider } from '@local/content-area/dist/Sidebar/SidebarContext';
import type { LandingPageProps } from '@local/login';
import { LoginHandler } from '@local/login';
import { setLoginError } from '@local/login/dist/store/sessionStorageHelpers/loginErrorHelper';
import { Messages as WDS2Messages } from '@local/messages-wds2/dist/Messages';
import { Messages } from '@local/messages/dist/Messages';
import { trackError } from '@local/metrics';
import { ErrorScreen } from '@local/svgs/dist/pageState/ErrorScreen';
import { NotFoundSvg } from '@local/svgs/dist/svg/NotFoundSvg';
import { UnsupportedBrowserOverlay } from '@local/unsupported-browser/dist/UnsupportedBrowserOverlay';
import EmptyState from '@local/web-design-system-2/dist/components/EmptyState/EmptyState';
import { WDSThemeProvider } from '@local/web-design-system-2/dist/theme';
import { OrgRouteGuard } from '@local/workspaces/dist/components/OrgRouteGuard/OrgRouteGuard';
import { workspaceHandler } from '@local/workspaces/dist/components/WorkspaceHandler';
import { WORKSPACES_LIST_PAGE } from '@local/workspaces/dist/urls';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Outlet, useLocation, useRoutes } from 'react-router';

import { Visualization } from 'src/visualization/Visualization/Visualization';

import LogoTitle from './assets/logoTitle';
import ContentScaffolding from './components/contentScaffolding/ContentScaffolding';
import FirewallGaurd from './components/firewallGaurd/firewallGaurd';
import { LicenseGuard } from './components/licenseGuard/LicenseGuard';
import { RoutedTabs } from './components/routedTabs/RoutedTabs';
import WorkspaceContentContainer from './components/workspaceContentContainer/WorkspaceContentContainer';
import { withDiscoverContext } from './contexts/DiscoverContext';
import { SearchParamsProvider } from './contexts/SearchParamsContext';
import { SnackBarProvider } from './contexts/SnackBarContext';
import { startWorker } from './mockApi/mock';
import { AdminSettingsPage } from './pages/adminSettings/AdminSettingsPage';
import { BentleyEditUserPage } from './pages/editUserPage/BentleyEditUserPage';
import { LandingPage } from './pages/landingPage/LandingPage';
import { ListUsersPage } from './pages/listUsersPage/ListUsersPage';
import { RecycleBinPage } from './pages/recycleBinPage/RecycleBinPage';
import { CesiumSearchPage } from './pages/searchPage/CesiumSearchPage';
import { SearchPage } from './pages/searchPage/SearchPage';
import { WorkspaceFilesPage } from './pages/workspaceFilesPage/WorkspaceFilesPage';
import { WorkspacePage } from './pages/workspacePage/WorkspacePage';
import { store } from './store/store';
import {
    ERROR_FORBIDDEN_CONNECTION,
    ERROR_FORBIDDEN_CONNECTION_MESSAGE,
    FILES,
    GEOSCIENCE_OBJECTS,
    LOG_IN_AGAIN,
    LOGIN_FAILED,
    NOT_FOUND_MESSAGE,
    NOT_FOUND_TITLE,
    RECYCLE_BIN_TITLE,
} from './strings';
import {
    ADMIN_SETTINGS,
    CESIUM_SEARCH_PAGE,
    LEAFLET_SEARCH_PAGE,
    USER_MANAGEMENT_PAGE,
    WEBVIZ_VIEWER,
    WORKSPACE_FILES,
    WORKSPACE_OVERVIEW,
    WORKSPACE_RECYCLE_BIN,
} from './urls';

export function WrappedLandingPage({ getSignInUrl }: LandingPageProps) {
    const { search } = useLocation();
    const query = new URLSearchParams(search);
    const error = query.get('error');

    if (error) {
        trackError('Error on login', 'error from login in <App>', {
            error,
            description: query.get('description') || undefined,
        });
        setLoginError(error ? `${LOGIN_FAILED} ${LOG_IN_AGAIN}` : '');
    }

    return <LandingPage getSignInUrl={getSignInUrl} />;
}

const evoServices = [
    'evo.discovery',
    'evo.workspace',
    'evo.file',
    'evo.object',
    'evo.blocksync',
    'evo.users:read',
    'offline_access',
];

export function EvoRoutes() {
    const { evouiEnableDiscover, evouiEnableDelete, evouiEnableCesium, evouiEnableAdminSettings } =
        useFlags();

    const extraTabDefinitions = [
        { key: WORKSPACE_OVERVIEW, label: GEOSCIENCE_OBJECTS, path: WORKSPACE_OVERVIEW },
        { key: WORKSPACE_FILES, label: FILES, path: WORKSPACE_FILES },
    ];

    const errorScreen = () => (
        <ErrorScreen
            msg={ERROR_FORBIDDEN_CONNECTION_MESSAGE}
            details={ERROR_FORBIDDEN_CONNECTION}
        />
    );

    const extraTabDefinitionsEnd = [];

    if (evouiEnableDelete) {
        extraTabDefinitionsEnd.push({
            key: WORKSPACE_RECYCLE_BIN,
            label: RECYCLE_BIN_TITLE,
            path: WORKSPACE_RECYCLE_BIN,
        });
    }

    const routes = useRoutes([
        {
            path: '/',
            element: (
                <OrgRouteGuard defaultRoute={CESIUM_SEARCH_PAGE}>
                    {/* TODO: Use defaultThemeMode instead of themeMode when all dark components are correctly displayed */}
                    <WDSThemeProvider themeMode="light">
                        <LicenseGuard licensedServices={evoServices}>
                            <FirewallGaurd errorComponent={errorScreen()}>
                                <SearchParamsProvider>
                                    <SnackBarProvider>
                                        <SidebarProvider>
                                            <Outlet />
                                            <Messages queue="global" />
                                            <WDS2Messages queue="global" />
                                            <UnsupportedBrowserOverlay />
                                        </SidebarProvider>
                                    </SnackBarProvider>
                                </SearchParamsProvider>
                            </FirewallGaurd>
                        </LicenseGuard>
                    </WDSThemeProvider>
                </OrgRouteGuard>
            ),
            children: [
                {
                    path: ':orgUuid',
                    element: <ContentScaffolding />,
                    children: [
                        workspaceHandler({
                            appRoutes: (tabs: JSX.Element) => [
                                {
                                    path: WORKSPACE_OVERVIEW,
                                    element: <WorkspacePage tabs={tabs} />,
                                },
                                {
                                    path: WORKSPACE_FILES,
                                    element: <WorkspaceFilesPage tabs={tabs} />,
                                },
                                {
                                    ...(evouiEnableDelete && {
                                        path: WORKSPACE_RECYCLE_BIN,
                                        element: <RecycleBinPage tabs={tabs} />,
                                    }),
                                },
                                {
                                    path: WEBVIZ_VIEWER,
                                    element: <Visualization />,
                                },
                            ],
                            canNavigateToWorkspace: true,
                            extraTabDefinitions,
                            extraTabDefinitionsEnd,
                            workspaceElement: <WorkspaceContentContainer />,
                            TabComponent: RoutedTabs,
                        }),
                        {
                            ...(evouiEnableCesium && {
                                path: CESIUM_SEARCH_PAGE,
                                element: withDiscoverContext(<CesiumSearchPage />),
                            }),
                        },
                        {
                            path: USER_MANAGEMENT_PAGE,
                            children: [
                                {
                                    index: true,
                                    element: <ListUsersPage />,
                                },
                                {
                                    path: ':userUuid',
                                    element: <BentleyEditUserPage />,
                                },
                            ],
                        },
                        {
                            ...(evouiEnableDiscover && {
                                path: LEAFLET_SEARCH_PAGE,
                                children: [
                                    {
                                        index: true,
                                        element: withDiscoverContext(<SearchPage />),
                                    },
                                ],
                            }),
                        },
                        {
                            ...(evouiEnableAdminSettings && {
                                path: ADMIN_SETTINGS,
                                element: <AdminSettingsPage />,
                            }),
                        },
                        {
                            path: '*',
                            element: (
                                <EmptyState
                                    sx={{
                                        marginTop: (theme) => theme.spacing(15),
                                        marginLeft: 0,
                                        padding: 2,
                                        paddingTop: 0,
                                        svg: {
                                            width: '50%',
                                        },
                                    }}
                                    bodySx={{
                                        marginTop: (theme) => theme.spacing(4),
                                        marginLeft: (theme) => theme.spacing(-4),
                                    }}
                                    image={<NotFoundSvg />}
                                    title={NOT_FOUND_TITLE}
                                    message={NOT_FOUND_MESSAGE}
                                />
                            ),
                        },
                    ],
                },
            ],
        },
    ]);
    return routes;
}

export function App() {
    const { evouiEnableCesium } = useFlags();

    return (
        <LoginHandler
            guardedRoutes={<EvoRoutes />}
            store={store}
            LandingPage={WrappedLandingPage}
            LandingPageLogo={LogoTitle}
            service="evo"
            homePagePath={evouiEnableCesium ? CESIUM_SEARCH_PAGE : WORKSPACES_LIST_PAGE}
            useOrgSelector
            additionalScopes={['openid', 'profile'].concat(evoServices)}
            bentleyIdEnabled
        />
    );
}

// Exclude our msw mocks from running in our Jest tests.
if (typeof process !== 'object') {
    startWorker();
}

import { ContentArea } from '@local/content-area';
import { BreadcrumbsProvider } from '@local/content-area/dist/Breadcrumbs/BreadcrumbsContext';
import { getAdminFromBentleyAccessToken } from '@local/login';
import { getCurrentUserDetails } from '@local/login/dist/store/sessionStorageHelpers/entitlementsHelper/entitlementsHelper';
import { MaintenanceState } from '@local/svgs/dist/pageState';
import { ConnectivityCheck } from '@local/web-design-system-2/dist/components/ConnectivityCheck/ConnectivityCheck';
import { Map, Settings, User, Workspaces } from '@local/web-design-system-2/dist/icons';
import { WORKSPACES_LIST_PAGE } from '@local/workspaces/dist/urls';
import { setDocumentTitle } from '@local/workspaces/dist/utils/setDocumentTitle';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import classNames from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useCallback, useEffect } from 'react';
import { Outlet, useLocation, useParams } from 'react-router';

import type { NavDrawerItem } from 'src/types/NavDrawerItem';
import {
    ADMIN_SETTINGS,
    CESIUM_SEARCH_PAGE,
    LEAFLET_SEARCH_PAGE,
    USER_MANAGEMENT_PAGE,
    WEBVIZ_VIEWER,
} from 'src/urls';

import { EvoIcon } from '../../assets/EvoBadge';
import {
    NO_INTERNET_CONNECTION,
    SCAFFOLDING_TITLE,
    TAGLINE,
    USER_COOKIE_SETTINGS,
    VERSION_LABEL,
} from '../../strings';
import { useStyles } from './ContentScaffolding.styles';
import { HubSelector } from './HubSelector/HubSelector';

export const ContentScaffolding = () => {
    const {
        evouiEnableDiscover,
        evouiMaintenancePage,
        evouiEnableCesium,
        evouiEnableAdminSettings,
    } = useFlags();
    const { classes } = useStyles();
    const location = useLocation();
    const params = useParams();

    const user = getCurrentUserDetails();
    const isAdmin = getAdminFromBentleyAccessToken();

    setDocumentTitle(SCAFFOLDING_TITLE);

    const navItems: NavDrawerItem[] = [
        {
            key: 'workspaces',
            text: 'Workspaces',
            location: `/${params.orgUuid}/${WORKSPACES_LIST_PAGE}`,
            iconElement: <Workspaces />,
        },
    ];

    if (evouiEnableDiscover) {
        navItems.push({
            key: 'leaflet-search',
            text: 'leaflet-search',
            location: `/${params.orgUuid}/${LEAFLET_SEARCH_PAGE}`,
            iconElement: <Map />,
        });
    }

    if (evouiEnableCesium) {
        navItems.unshift({
            key: 'search',
            text: 'Search',
            location: `/${params.orgUuid}/${CESIUM_SEARCH_PAGE}`,
            iconElement: <Map />,
        });
    }

    navItems.push({
        key: 'administrators',
        text: 'Administrators',
        location: `/${params.orgUuid}/${USER_MANAGEMENT_PAGE}`,
        iconElement: <User />,
    });

    if (evouiEnableAdminSettings && isAdmin) {
        navItems.push({
            key: 'settings',
            text: 'Settings',
            location: `/${params.orgUuid}/${ADMIN_SETTINGS}`,
            iconElement: <Settings />,
        });
    }

    const openOneTrust = useCallback(() => {
        const shield = document.getElementById('ot-sdk-btn-floating');
        if (shield?.firstChild?.firstChild) {
            (shield.firstChild.firstChild as HTMLElement)?.click();
        }
    }, []);

    const footerComponent = !location.pathname.includes(WEBVIZ_VIEWER) ? (
        <Stack
            direction="row"
            divider={<span>•</span>}
            spacing={1}
            automation-id="user-portal-version"
        >
            <span>{TAGLINE}</span>
            <span>{VERSION_LABEL}</span>
        </Stack>
    ) : undefined;

    useEffect(() => {
        function unloadHandler() {
            const shield = document.getElementById('ot-sdk-btn-floating');
            if (shield) {
                shield.style.display = 'block';
            }
        }
        const shield = document.getElementById('ot-sdk-btn-floating');
        if (shield) {
            shield.style.display = 'none';
        }
        return () => {
            unloadHandler();
        };
    }, []);

    return (
        <>
            {evouiMaintenancePage && <MaintenanceState appName="Evo" />}
            <div style={{ display: evouiMaintenancePage ? 'none' : undefined }}>
                <BreadcrumbsProvider>
                    <ContentArea
                        helpUrl="https://help.seequent.com/Evo/en-GB/Content/intro.htm"
                        useWDS2
                        sidebarSx={{
                            // These need to have !important otherwise we cant override the base style classes.
                            padding: '40px !important',
                            width: '400px !important',
                            maxHeight: 'calc(100vh - 88px) !important',
                        }}
                        currentRoute={location.pathname}
                        evoSelector={<HubSelector />}
                        logoOnly={
                            <Box
                                sx={(theme) => ({
                                    width: theme.spacing(4.5),
                                    margin: '-8px 0px',
                                })}
                            >
                                <EvoIcon />
                            </Box>
                        }
                        logoWithTitle={
                            <Box
                                sx={(theme) => ({
                                    height: theme.spacing(8),
                                    width: theme.spacing(24),
                                    margin: '-23px 0px',
                                })}
                            >
                                <img src="/SeequentEvoWhite.svg" alt="SeequentEvo" />
                            </Box>
                        }
                        footerComponent={footerComponent}
                        navItems={navItems}
                        service="evo"
                        // TODO: Change the type of userResult to User instead of LoadingHookUser
                        userResult={user}
                        isAdmin={Boolean(isAdmin)}
                        exactMatch={false}
                        variant="light"
                        footerLinks={[
                            {
                                id: 'cookie-settings',
                                text: USER_COOKIE_SETTINGS,
                                action: openOneTrust,
                            },
                        ]}
                    >
                        <div
                            className={classNames(classes.contentInner, {
                                [classes.webVizContent]: location.pathname.includes(WEBVIZ_VIEWER),
                            })}
                        >
                            <ConnectivityCheck offlineMessage={NO_INTERNET_CONNECTION} />
                            <Outlet />
                        </div>
                    </ContentArea>
                </BreadcrumbsProvider>
            </div>
        </>
    );
};

export default ContentScaffolding;

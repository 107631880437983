import { makeStyles } from 'tss-react/mui';

// Leaflet needs to know these values to render the markers correctly.
// By making them constants, we can ensure they are consistent in leaflet and react.

const MARKER_ICON_PADDING = 5;
const MARKER_ICON_BORDER_WIDTH = 3;
export const MARKER_ICON_BASE_WIDTH = 20;
const MARKER_ICON_TOTAL_WIDTH =
    MARKER_ICON_BASE_WIDTH + MARKER_ICON_PADDING * 2 + MARKER_ICON_BORDER_WIDTH * 2;

const GROUP_PADDING = 4;
const GROUP_RADIUS = (MARKER_ICON_TOTAL_WIDTH + GROUP_PADDING * 2) / 2;
const GROUP_HEIGHT = MARKER_ICON_TOTAL_WIDTH + GROUP_PADDING * 2;

export const useStyles = makeStyles()(() => ({
    groupClusterBase: {
        position: 'absolute',
        top: `-${GROUP_HEIGHT}px`,
        display: 'flex',
        alignItems: 'center',
        width: 'max-content',
        backgroundColor: 'white',
        borderRadius: `${GROUP_RADIUS}px ${GROUP_RADIUS}px ${GROUP_RADIUS}px 0px`,
        padding: `${GROUP_PADDING}px`,
    },
    objectIconMarkerBase: {
        position: 'absolute',
        top: `-${MARKER_ICON_TOTAL_WIDTH / 2}px`,
        left: `-${MARKER_ICON_TOTAL_WIDTH / 2}px`,
    },
}));

const iconBorderColour = (active: boolean, hovered: boolean) => {
    if (active) {
        return '#234b66';
    }

    if (hovered) {
        return '#317ba1';
    }

    return '#55b8d9';
};

export const useObjectIconStyles = makeStyles<{ active?: boolean; hovered?: boolean }>()(
    (_theme, { active = false, hovered = false }) => ({
        markerIconBase: {
            color: '#4a4a4c',
            flexWrap: 'nowrap',
            height: '34px',
            width: 'auto',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'row',
            position: 'relative',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#ffffff',
            borderRadius: `${MARKER_ICON_TOTAL_WIDTH / 2}px`,
            border: `solid ${MARKER_ICON_BORDER_WIDTH}px ${iconBorderColour(active, hovered)}`,
            '&:hover': {
                border: `solid ${MARKER_ICON_BORDER_WIDTH}px ${iconBorderColour(active, true)}`,
                '& #objectMarkerText': {
                    maxWidth: 'unset',
                },
            },
            padding: `${MARKER_ICON_PADDING}px`,
            '.MuiSvgIcon-root': {
                width: '1rem',
                height: '1rem',
            },
        },
        objectMarkerText: {
            overflow: 'hidden',
            maxWidth: active || hovered ? 'unset' : 0,
            fontFamily: 'Inter',
            fontSize: '12px',
            fontWeight: 500,
            lineHeight: 'normal',
            color: '#4a4a4c',
        },
        objectMarkerTypography: {
            margin: 0,
            fontFamily: 'Inter',
            fontSize: '12px',
            fontWeight: 500,
            lineHeight: 'normal',
            color: '#4a4a4c',
        },
    }),
);

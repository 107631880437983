import type { Params } from 'react-router';

import {
    getHubUrlForCurrentOrg,
    getOrgUuidFromParams,
    getSelectedWorkspaceFromParams,
} from '../components/OrgRouteGuard/OrgRouteGuard';
import { useListWorkspacesAdminQuery, useListWorkspacesQuery } from './enhancedWorkspaceMiddleware';
import type {
    GetWorkspaceApiArg,
    ListWorkspacesApiArg,
} from './GENERATED_workspaceClientEndpoints';
import {
    useGetWorkspaceAdminQuery,
    useGetWorkspaceQuery,
    useListUserRolesAdminQuery,
    useListUserRolesQuery,
} from './GENERATED_workspaceClientEndpoints';
import { workspaceClient } from './workspaceClient';

type Condition = 'equals' | 'lessThan' | 'greaterThan' | 'contains' | 'like' | 'in' | 'matches'; // etc

export interface Filter {
    property: string;
    condition: Condition;
    comparison: string[];
}

export interface Folder {
    id: string;
    name: string;
    description?: string;
    filters: Filter[];
    subFolders: Folder[];
}

export interface Schema {
    name: string;
    description?: string;
    lastUpdated: string;
    folders: Folder[];
}

export type WorkspaceAffinitySchemaResponse = {
    links: {
        [key: string]: string;
    };
    schemas: Schema[];
};

/** This function is intended as a temporary addition until the real thing is implemented in the open api spec
 */
const workspaceClientEndpoints = workspaceClient.injectEndpoints({
    endpoints: (build) => ({
        getWorkspaceAffinitySchema: build.query<
            WorkspaceAffinitySchemaResponse,
            GetWorkspaceApiArg
        >({
            query: (queryArg) => ({
                url: `${queryArg.hubUrl}/workspace/orgs/${queryArg.orgId}/workspaces/${queryArg.workspaceId}/affinity-schema`,
            }),
        }),
    }),
});

export interface FetchWorkspacesArgs extends ListWorkspacesApiArg {
    isAdmin: boolean;
}

export const fetchWorkspaces = ({
    isAdmin,
    hubUrl,
    orgId,
    limit,
    offset,
    filter,
    orderBy,
}: FetchWorkspacesArgs) => {
    const query = isAdmin ? useListWorkspacesAdminQuery : useListWorkspacesQuery;
    const result = query({
        hubUrl,
        orgId,
        limit,
        offset,
        filter,
        orderBy,
    });
    return {
        ...result,
    };
};

export const fetchWorkSpaceData = (params: Params, isAdmin: boolean) => {
    if (isAdmin) {
        return useGetWorkspaceAdminQuery({
            hubUrl: getHubUrlForCurrentOrg(),
            orgId: getOrgUuidFromParams(params),
            workspaceId: getSelectedWorkspaceFromParams(params),
        });
    }
    return useGetWorkspaceQuery({
        hubUrl: getHubUrlForCurrentOrg(),
        orgId: getOrgUuidFromParams(params),
        workspaceId: getSelectedWorkspaceFromParams(params),
    });
};

export const fetchUserRolesData = (params: Params, isAdmin: boolean) => {
    if (isAdmin) {
        return useListUserRolesAdminQuery({
            hubUrl: getHubUrlForCurrentOrg(),
            orgId: getOrgUuidFromParams(params),
            workspaceId: getSelectedWorkspaceFromParams(params),
        });
    }
    return useListUserRolesQuery({
        hubUrl: getHubUrlForCurrentOrg(),
        orgId: getOrgUuidFromParams(params),
        workspaceId: getSelectedWorkspaceFromParams(params),
    });
};

export const { useGetWorkspaceAffinitySchemaQuery } = workspaceClientEndpoints;

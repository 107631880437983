import type { ListedObject } from '@api/goose/dist/enhancedGooseClient';
import { useEnhancedUpdateObjectByIdMutation } from '@api/goose/dist/enhancedGooseClient';
import { extractObjectSchemaName } from '@api/goose/dist/utils/extractObjectSchemaName';
import { useMessagesContext } from '@local/messages-wds2/dist/MessagesContext';
import { ListItem } from '@local/web-design-system-2/dist/components/GenericListing/ListItem';
import type { FieldDefinition } from '@local/web-design-system-2/dist/components/GenericListing/types';
import OverflowTooltip from '@local/web-design-system-2/dist/components/OverflowTooltip/OverflowTooltip';
import FileRestoreIcon from '@local/web-design-system-2/dist/icons/FileRestore';
import {
    getOrgUuidFromParams,
    getSelectedWorkspaceFromParams,
} from '@local/workspaces/dist/components/OrgRouteGuard/OrgRouteGuard';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle/DialogTitle';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import type { SerializedError } from '@reduxjs/toolkit';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router';

import {
    BLOCKSYNC_SCHEMA,
    RESTORE_ACTION_TOOLTIP,
    RESTORE_DIALOG_CANCEL,
    RESTORE_DIALOG_CONFIRM,
    RESTORE_DIALOG_CONTENT,
    RESTORE_OBJECT_DIALOG_TITLE,
    RESTORE_OBJECT_DIALOG_WARNING,
    RESTORE_OBJECT_TOAST_FAILURE,
    RESTORE_OBJECT_TOAST_SUCCESS,
    VIEW_RESTORED_OBJECT,
} from 'src/strings';
import { formatObjectName } from 'src/utils/objectUtils';

const RestoreAction = ({ onRestore, canRestore, shouldRender = true }: RecycleActionProps) =>
    shouldRender && (
        <Grid
            gap={2}
            container
            alignItems="center"
            justifyContent="center"
            item
            xs
            automation-id="object-row-restore-action"
        >
            <Tooltip
                title={RESTORE_ACTION_TOOLTIP}
                placement="bottom"
                onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                    event.stopPropagation();
                }}
            >
                <span>
                    <IconButton
                        disabled={!canRestore}
                        onClick={onRestore}
                        color="secondary"
                        automation-id="object-row-restore-button"
                    >
                        <FileRestoreIcon fontSize="small" />
                    </IconButton>
                </span>
            </Tooltip>
        </Grid>
    );

interface RecycleActionProps {
    onRestore: () => void;
    canRestore?: boolean;
    shouldRender?: boolean;
}

interface RecycledObjectRowProps {
    object: ListedObject;
    fields: FieldDefinition[];
    canRestore: boolean;
}

interface ExtendedError extends SerializedError {
    status?: number;
}

interface ConfirmRestoreDialogProps {
    open: boolean;
    object: ListedObject;
    onClose: (confirm: boolean) => void;
}

const ConfirmRestoreDialog = ({ open, object, onClose }: ConfirmRestoreDialogProps) => (
    <Dialog open={open} onClose={() => onClose(false)} sx={{ maxWidth: '444px', margin: 'auto' }}>
        <DialogTitle automation-id="dialog-title">{RESTORE_OBJECT_DIALOG_TITLE}</DialogTitle>
        <DialogContent>
            <DialogContentText automation-id="dialog-description">
                <OverflowTooltip title={`"${formatObjectName(object.name)}"?`} maxLines={2}>
                    {`${RESTORE_DIALOG_CONTENT} "${formatObjectName(object.name)}"?`}
                </OverflowTooltip>
                {RESTORE_OBJECT_DIALOG_WARNING}
            </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ padding: 2 }}>
            <Button
                onClick={() => onClose(false)}
                variant="outlined"
                automation-id="dialog-cancel-button"
            >
                {RESTORE_DIALOG_CANCEL}
            </Button>
            <Button
                onClick={() => onClose(true)}
                variant="contained"
                automation-id="dialog-confirm-button"
            >
                {RESTORE_DIALOG_CONFIRM}
            </Button>
        </DialogActions>
    </Dialog>
);

export const RecycledObjectRow = ({ object, fields, canRestore }: RecycledObjectRowProps) => {
    const params = useParams();
    const { addMessage } = useMessagesContext();
    const [updateObjectsById] = useEnhancedUpdateObjectByIdMutation();
    const [restoreDialogToggle, setRestoreDialogToggle] = useState(false);
    const navigate = useNavigate();

    const orgId = getOrgUuidFromParams(params);
    const workspaceId = getSelectedWorkspaceFromParams(params);

    const onRestore = async (confirm: boolean | null) => {
        setRestoreDialogToggle(false);
        if (confirm) {
            const response = await updateObjectsById({
                orgId,
                workspaceId,
                objectId: object.object_id,
                deleted: false,
                geoscienceObject: null,
            });
            const responseError = response?.error as ExtendedError;
            const status = responseError?.status;
            if (status === 303 || !responseError) {
                addMessage({
                    message: RESTORE_OBJECT_TOAST_SUCCESS,
                    severity: 'success',
                    inlineChildren: true,
                    action: (
                        <Grid container alignItems="center">
                            <Button
                                color="secondary"
                                size="small"
                                sx={{ whiteSpace: 'nowrap', textTransform: 'underline' }}
                                onClick={() => navigate('../overview')}
                                automation-id="notification-view-object"
                            >
                                {VIEW_RESTORED_OBJECT}
                            </Button>
                        </Grid>
                    ),
                });
            } else {
                addMessage({
                    message: RESTORE_OBJECT_TOAST_FAILURE,
                    severity: 'error',
                });
            }
        }
    };

    return (
        <>
            <ListItem
                item={object}
                key={object.object_id}
                fields={fields}
                automation-id="recycled-object-row"
                actions={
                    <RestoreAction
                        onRestore={() => setRestoreDialogToggle(true)}
                        canRestore={canRestore}
                        shouldRender={extractObjectSchemaName(object.schema) !== BLOCKSYNC_SCHEMA}
                    />
                }
                hasDisabledState
            />

            <ConfirmRestoreDialog open={restoreDialogToggle} object={object} onClose={onRestore} />
        </>
    );
};

import { LinearProgressFaked } from '@local/web-design-system/dist/components/Progress/LinearProgressFaked';
import { PlusIcon } from '@local/web-design-system/dist/icons/Actions/PlusIcon';
import { RefreshIcon } from '@local/web-design-system/dist/icons/Actions/RefreshIcon';
import { ErrorIcon } from '@local/web-design-system/dist/icons/Alerts/ErrorIcon';
import { useTrace } from '@local/web-design-system/dist/utils/trace';
import { parsePrefixUid } from '@local/webviz/dist/utilities/uuidGenerators';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import classnames from 'classnames';
import { useEffect, forwardRef } from 'react';
import { useSearchParams } from 'react-router';

import { SchemaIcon } from 'src/pages/workspacePage/workspaceContent/SchemaIcon';
import { fileNameExtensionRemover } from 'src/pages/workspacePage/workspaceContent/utils';
import { useAppDispatch, useAppSelector } from 'src/store/store';
import {
    getFirstGMMOutputVolumeId,
    isSelectedInProjectTree,
    isSelectedInScenePanel,
    selectionListProjectTree,
} from 'src/store/visualization/selectors';
import { clearSelectedObjectsProjectTree } from 'src/store/visualization/visualizationSlice';
import type { TreeStructure } from 'src/store/visualization/visualizationSlice.types';
import { ID_PARAM } from 'src/strings';
import { Schemas } from 'src/visualization/constants';
import { useDrag } from 'src/visualization/context/hooks/useDragAndDrop';
import { useObjectManager } from 'src/visualization/context/hooks/useObjectManager';

import { APPROXIMATE_MAX_LOAD_TIME, OBJECT_TOO_LARGE } from './ObjectListItemControl.constants';
import { useStyles } from './ObjectListItemControl.styles';

export const ObjectListItemControl = forwardRef<HTMLDivElement, TreeStructure>((treeItem, ref) => {
    const { classes } = useStyles();
    const applyTrace = useTrace('object-list-item');
    const dispatch = useAppDispatch();

    const [searchParams] = useSearchParams();
    const objectIdQueryParam = searchParams.get(ID_PARAM) ?? '';
    const { treeId: objectId, name, schema, parentId } = treeItem;
    const {
        loadObject,
        loadSelectedObjects,
        reloadObject,
        errorMessage,
        isLoading,
        isObjectLoaded,
    } = useObjectManager(objectId);

    const firstGMMOutputFolderId = useAppSelector(
        getFirstGMMOutputVolumeId(parsePrefixUid(objectId), schema),
    );

    useEffect(() => {
        if (
            [objectId, parentId].includes(objectIdQueryParam) ||
            (objectId.includes(objectIdQueryParam) && objectIdQueryParam.length > 0)
        ) {
            if (schema === Schemas.GeologicalModelMeshesSchema && firstGMMOutputFolderId) {
                if (objectId.includes(firstGMMOutputFolderId)) {
                    loadObject();
                }
            } else {
                loadObject();
            }
        }
    }, []);

    const selectedObjectIds = useAppSelector(selectionListProjectTree);
    const handleAddObject = () => {
        if (selectedObjectIds.includes(objectId)) {
            loadSelectedObjects();
            dispatch(clearSelectedObjectsProjectTree());
        } else {
            loadObject();
        }
    };
    const onKeyUp = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            handleAddObject();
        }
    };
    const handleFailedObject = () => {
        reloadObject();
    };

    const { onClick, onDoubleClick, onDragStart } = useDrag(objectId);
    const isSelectedScenePanel = useAppSelector(isSelectedInScenePanel(objectId));
    const isSelectedProjectTree = useAppSelector(isSelectedInProjectTree(objectId));
    const isObjectOnPlot = isObjectLoaded(objectId);
    const Icon = SchemaIcon(schema);

    return (
        <Grid item container className={classes.root} ref={ref}>
            <Grid
                className={classnames(classes.background, {
                    [classes.backgroundPending]: isLoading,
                    [classes.backgroundClear]: isObjectOnPlot && !errorMessage,
                    [classes.backgroundSelected]: isSelectedScenePanel,
                    [classes.backgroundSelectedForLoading]:
                        isSelectedProjectTree && (!isObjectOnPlot || errorMessage),
                })}
            />
            <Grid
                item
                container
                className={classes.objectListItemControl}
                wrap="nowrap"
                alignItems="center"
                justifyContent="center"
            >
                <Grid
                    item
                    container
                    zeroMinWidth
                    className={classnames(classes.mainContainer, {
                        [classes.cursor]: !isObjectOnPlot,
                    })}
                    alignItems="center"
                    draggable
                    onClick={onClick}
                    onDragStart={onDragStart}
                    onDoubleClick={onDoubleClick}
                    onKeyUp={onKeyUp}
                    tabIndex={isObjectOnPlot ? undefined : 0}
                >
                    <Grid
                        item
                        container
                        xs
                        alignItems="center"
                        wrap="nowrap"
                        className={classes.nameContainer}
                        {...applyTrace(name)}
                    >
                        <Grid item className={classes.icon}>
                            <Icon fontSize="small" />
                        </Grid>
                        <Grid item xs>
                            <Tooltip title={fileNameExtensionRemover(name)}>
                                <Typography
                                    variant="body2"
                                    color={errorMessage ? 'error' : 'inherit'}
                                    className={classes.name}
                                >
                                    {fileNameExtensionRemover(name)}
                                </Typography>
                            </Tooltip>
                        </Grid>
                    </Grid>
                    {isLoading && !errorMessage && (
                        <Grid
                            className={classes.progressContainer}
                            {...applyTrace(`${name}-progress-bar`)}
                        >
                            <LinearProgressFaked
                                isLoading={isLoading}
                                milliseconds={APPROXIMATE_MAX_LOAD_TIME}
                                hideWhenNotLoading
                                classes={{ root: classes.loadingProgressContainer }}
                            />
                        </Grid>
                    )}
                </Grid>
                <Grid item className={classes.loadButtonContainer}>
                    {!errorMessage && !isObjectOnPlot && (
                        <IconButton
                            onClick={handleAddObject}
                            className={classnames(classes.loadIconButton)}
                            {...applyTrace(`${name}-add`)}
                        >
                            <PlusIcon fontSize="inherit" />
                        </IconButton>
                    )}
                    {errorMessage && errorMessage !== OBJECT_TOO_LARGE && (
                        <IconButton
                            onClick={handleFailedObject}
                            className={classnames(classes.loadIconButton)}
                            {...applyTrace(`${name}-error-button`)}
                        >
                            <RefreshIcon fontSize="inherit" />
                        </IconButton>
                    )}
                </Grid>
            </Grid>

            {errorMessage && (isSelectedProjectTree || isSelectedScenePanel) && (
                <Grid container alignItems="center" zIndex={1}>
                    <Grid container item className={classes.errorIcon}>
                        <ErrorIcon fontSize="small" />
                    </Grid>
                    <Grid item xs>
                        <Typography variant="caption" color="error">
                            {errorMessage}
                        </Typography>
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
});

import type { ListCellComponentProps } from '@local/web-design-system-2/dist/components/GenericListing/types';
import OverflowTooltip from '@local/web-design-system-2/dist/components/OverflowTooltip/OverflowTooltip';
import Typography from '@mui/material/Typography';

export const UserCell = ({ item, itemKey }: ListCellComponentProps) => {
    const user = item[itemKey];

    return (
        <Typography variant="body2">
            <OverflowTooltip title={user?.name} maxLines={1}>
                {user?.name}
            </OverflowTooltip>
        </Typography>
    );
};

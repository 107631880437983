import Overflow from '@local/web-design-system-2/dist/icons/Overflow';
import User from '@local/web-design-system-2/dist/icons/User';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { useSearchParams } from 'react-router';

import { usePersistedState } from 'src/hooks/usePersistedState';

export const AffinityFolderRowActions = ({ folderId }: { folderId: string }) => {
    const [, setSearchParams] = useSearchParams();
    const [, setFolderID] = usePersistedState('folderID');
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton size="large" onClick={handleClick} automation-id="overflow-button">
                <Overflow fontSize="small" />
            </IconButton>
            <Menu
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorEl={anchorEl}
                disableAutoFocusItem
                elevation={4}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <MenuItem
                    onClick={() => {
                        setFolderID(folderId);
                        setSearchParams({ propertiesSidebar: 'open' });
                    }}
                    automation-id="edit-users-workspace-overflow-menu-item"
                >
                    <Grid container gap={2} alignItems="center" justifyContent="flex-start">
                        <User fontSize="small" />
                        <Typography automation-id="item-label">Properties</Typography>
                    </Grid>
                </MenuItem>
            </Menu>
        </>
    );
};

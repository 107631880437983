import type { GetObjectByIdApiArg } from '@api/goose/dist/enhancedGooseClient';
import { trackError } from '@local/metrics';

import type {
    AttributeSchema,
    AttributeValues,
    BlocksyncReferenceType,
    Item,
    Regular2DGridType,
    Tileset,
    TriangleMeshType,
} from 'src/visualization/types';

import { BlocksyncAttributeTypesMap } from '../constants';

export function extractTilesetUri(tileset: Tileset, name: string): string | undefined {
    const {
        schema,
        groups,
        root: { children },
    } = tileset;
    const classes = schema?.classes;

    if (!classes || !groups) {
        trackError('3D tile schema validation failed');
        return undefined;
    }

    const selectedClassName = Object.keys(classes).find((object) => classes[object].name === name);

    if (!selectedClassName) {
        trackError('3D tile content not found');
        return undefined;
    }

    const objectIndex = groups.findIndex(({ class: className }) => className === selectedClassName);
    const objectToLoad = children[objectIndex];

    if (!objectToLoad.content) {
        trackError('3D tile content not found');
        return undefined;
    }

    const { uri } = objectToLoad.content;
    return uri;
}

export function getTriangleMeshAttributes(meshObject: TriangleMeshType) {
    const verticesAttributes =
        (meshObject.triangles.vertices.attributes || meshObject.vertex_attributes) ?? [];
    const triangleAttributes =
        (meshObject.triangles.indices.attributes || meshObject.face_attributes) ?? [];
    const attributes = [...verticesAttributes, ...triangleAttributes];
    return attributes;
}

export function getGridAttributes(object: Regular2DGridType) {
    const cells = object.cell_attributes ?? [];
    const vertices = object.vertex_attributes ?? [];
    const attributes = [...cells, ...vertices];
    return attributes;
}

export function getBlocksyncReferenceAttributes(object: BlocksyncReferenceType) {
    const atts = object.attributes ?? [];
    const attributes: AttributeSchema[] = [];
    atts.forEach((attribute) =>
        attributes.push({
            ...attribute,
            attribute_type: BlocksyncAttributeTypesMap[attribute.attribute_type],
            values: { data: attribute.blocksync_column_uuid } as AttributeValues,
        }),
    );
    return attributes;
}

export function generateVisualizationServiceUrl(baseUrl: string, params: GetObjectByIdApiArg) {
    return `${baseUrl}/visualization/orgs/${params.orgId}/workspaces/${params.workspaceId}/geoscience-object/${params.objectId}`;
}

export function createAttributeId(id: string) {
    return `attribute_${id}`;
}

// convert a 32 bit unsigned integer to an RGBA array
export function convertDecimalToRGBA(color: number) {
    if (color > 4294967295 || color < 0) {
        trackError('Invalid color value');
        return undefined;
    }
    /* eslint-disable no-bitwise */
    const a = ((color >> 24) & 0xff) / 255;
    const b = (color >> 16) & 0xff;
    const g = (color >> 8) & 0xff;
    const r = color & 0xff;
    /* eslint-enable no-bitwise */
    return [r, g, b, a];
}

export function findGMMItemInFolders(folders: Item[], path: string[]) {
    const key = path.shift();
    if (folders.length > 0) {
        const object = folders.find((folder) => folder.name === key);
        if (path.length === 0) {
            return object;
        }
        return findGMMItemInFolders(object?.items as Item[], path);
    }
    return undefined;
}

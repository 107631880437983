import ArrowDown from '@local/web-design-system-2/dist/icons/ArrowDown';
import ArrowUp from '@local/web-design-system-2/dist/icons/ArrowUp';
import { useTrace } from '@local/web-design-system-2/dist/utils/trace';
import type { RoleEnum } from '@local/workspaces/dist/apiClients/GENERATED_workspaceClientEndpoints';
import Box from '@mui/material/Box/Box';
import Button from '@mui/material/Button/Button';
import ListItemText from '@mui/material/ListItemText/ListItemText';
import Menu from '@mui/material/Menu/Menu';
import MenuItem from '@mui/material/MenuItem/MenuItem';
import { useState } from 'react';

export interface BaseWorkspaceRoleCellProps {
    defaultSelectedRow: RoleEnum;
    onRoleUpdate: (selectedRole: RoleEnum) => void;
}

export const BaseWorkspaceRoleCell = ({
    defaultSelectedRow,
    onRoleUpdate,
}: BaseWorkspaceRoleCellProps) => {
    const applyTrace = useTrace('role-assigner');
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const isOpen = Boolean(anchorEl);
    const [menuWidth, setMenuWidth] = useState(0);
    const [selectedRole, setSelectedRole] = useState<RoleEnum>(defaultSelectedRow);
    const Icon = isOpen ? ArrowUp : ArrowDown;

    const roleOptionsMap: { [key in RoleEnum]: string } = {
        viewer: 'Viewer',
        editor: 'Editor',
        owner: 'Owner',
    };

    const roleOptions = Object.entries(roleOptionsMap).map(([key, value]) => ({
        key: key as RoleEnum,
        value,
    }));

    const toggleMenu = (event: React.MouseEvent<HTMLElement>) => {
        if (isOpen) {
            closeMenu();
        } else {
            openMenu(event);
        }
    };

    const openMenu = (event: React.MouseEvent<HTMLElement>) => {
        setMenuWidth(event.currentTarget.clientWidth);
        setAnchorEl(event.currentTarget);
    };

    const closeMenu = () => {
        setAnchorEl(null);
    };

    const onChange = async (role: RoleEnum) => {
        setSelectedRole(role);
        onRoleUpdate(role);
    };

    return (
        <Box>
            <Button
                size="small"
                variant="text"
                color="secondary"
                endIcon={<Icon fontSize="small" />}
                onClick={toggleMenu}
                automation-id={applyTrace(`menu-btn`)}
            >
                {roleOptionsMap[selectedRole]}
            </Button>
            <Menu
                automation-id={applyTrace('menu')}
                anchorEl={anchorEl}
                open={isOpen}
                onClose={closeMenu}
                MenuListProps={{
                    sx: { width: menuWidth },
                }}
            >
                {roleOptions.map((option) => {
                    const { key, value } = option;
                    return (
                        <MenuItem
                            automation-id={applyTrace(`menu-menu-item-${key}`)}
                            key={key}
                            selected={key === selectedRole}
                            onClick={() => {
                                onChange(key);
                                closeMenu();
                            }}
                        >
                            <ListItemText>{value}</ListItemText>
                        </MenuItem>
                    );
                })}
            </Menu>
        </Box>
    );
};

import { useMessagesContext } from '@local/messages-wds2/dist/MessagesContext';
import { trackError } from '@local/metrics/dist/src/metrics';
import { useTrace } from '@local/web-design-system-2/dist/utils/trace';
import type {
    RoleEnum,
    UserWorkspaceResponse,
} from '@local/workspaces/dist/apiClients/GENERATED_workspaceClientEndpoints';
import { useBulkAssignRolesAdminMutation } from '@local/workspaces/dist/apiClients/GENERATED_workspaceClientEndpoints';
import {
    getHubUrlForCurrentOrg,
    getOrgUuidFromParams,
} from '@local/workspaces/dist/components/OrgRouteGuard/OrgRouteGuard';
import Button from '@mui/material/Button/Button';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { useState } from 'react';
import { useParams } from 'react-router';

import {
    ASSIGN_WORKSPACES,
    FAILED_TO_ADD_WORKSPACES,
    FAILED_TO_LOAD_WORKSPACES,
} from 'src/strings';

import { BaseWorkspaceRoleCell } from './BaseWorkspaceRoleCell';
import { InfiniteScrollWorkspaceAdminMultiSelector } from './InfiniteScrollWorkspaceAdminMultiSelector';

export function WorkspaceRoleAssigner({
    workspaces,
}: {
    workspaces: UserWorkspaceResponse[] | undefined;
}) {
    const applyTrace = useTrace('role-assigner');
    const params = useParams();
    const currentUserUuid = params.userUuid ?? '';
    const { addMessage } = useMessagesContext();

    const [searchActive, setSearchActive] = useState(false);
    const [selectedWorkspaceList, setSelectedWorkspaceList] = useState<UserWorkspaceResponse[]>([]);
    const [selectedRole, setSelectedRole] = useState<RoleEnum>('viewer');
    const [isError, setIsError] = useState(false);

    const [roleAssignerAdmin, { isLoading }] = useBulkAssignRolesAdminMutation();

    const onChange = (selectedOptions: any) => {
        setSearchActive(selectedOptions.length !== 0);
        setSelectedWorkspaceList(selectedOptions);
    };

    const onSubmit = async () => {
        try {
            roleAssignerAdmin({
                orgId: getOrgUuidFromParams(params),
                hubUrl: getHubUrlForCurrentOrg(),
                bulkUserRoleAssignmentsRequest: {
                    role_assignments: selectedWorkspaceList.map((workspace) => ({
                        workspace_id: workspace.id,
                        user_id: currentUserUuid,
                        role: selectedRole,
                    })),
                },
            }).unwrap();

            // reset selection
            setSelectedWorkspaceList([]);
            setSearchActive(false);
        } catch (assignError) {
            trackError('Error adding user to workspace', JSON.stringify(assignError));
            addMessage({
                message: FAILED_TO_ADD_WORKSPACES,
                severity: 'error',
            });
        }
    };

    const handleError = (error: any) => {
        addMessage({
            message: FAILED_TO_LOAD_WORKSPACES,
            severity: 'error',
        });
        trackError('Unable to load workspace list', 'Request to idp failed', { error });
        setIsError(true);
    };

    if (isError) {
        return null;
    }

    return (
        <TableRow key="postHeader1">
            <TableCell colSpan={1} style={{ paddingRight: 0 }} sx={{ width: '55%' }}>
                <InfiniteScrollWorkspaceAdminMultiSelector
                    onChange={onChange}
                    selectedWorkspaceList={selectedWorkspaceList}
                    onError={handleError}
                    excludeWorkspaces={workspaces}
                />
            </TableCell>
            <TableCell>
                <BaseWorkspaceRoleCell
                    defaultSelectedRow={selectedRole}
                    onRoleUpdate={setSelectedRole}
                />
            </TableCell>
            <TableCell>
                <Button
                    variant="outlined"
                    size="small"
                    color="secondary"
                    disabled={!searchActive || isLoading}
                    onClick={onSubmit}
                    style={!searchActive ? { pointerEvents: 'none' } : {}}
                    automation-id={applyTrace('btn-assign')}
                    sx={{ whiteSpace: 'nowrap' }}
                >
                    {ASSIGN_WORKSPACES}
                </Button>
            </TableCell>
        </TableRow>
    );
}

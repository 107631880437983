import { Outlet } from 'react-router';

import { WorkspaceProvider } from 'src/contexts/WorkspaceContext';

export function WorkspaceContentContainer() {
    return (
        <WorkspaceProvider>
            <Outlet />
        </WorkspaceProvider>
    );
}

export default WorkspaceContentContainer;

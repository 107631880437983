import type { BreadcrumbSegment } from '@local/content-area/dist/Breadcrumbs/BreadcrumbsContext';
import { useBreadcrumbs } from '@local/content-area/dist/Breadcrumbs/BreadcrumbsContext';
import { checkIsAdmin } from '@local/login/dist/util/checkIsAdmin';
import { ErrorScreen } from '@local/svgs/dist/pageState';
import { useGetCurrentUserRoleQuery } from '@local/workspaces/dist/apiClients/GENERATED_workspaceClientEndpoints';
import { fetchWorkSpaceData } from '@local/workspaces/dist/apiClients/workspaceClientEndpoints';
import {
    getHubUrlForCurrentOrg,
    getOrgUuidFromParams,
    getSelectedWorkspaceFromParams,
} from '@local/workspaces/dist/components/OrgRouteGuard/OrgRouteGuard';
import { WORKSPACES_OVERVIEW_PAGE } from '@local/workspaces/dist/urls';
import { hasRoleOrHigher } from '@local/workspaces/dist/utils/permissions';
import Stack from '@mui/material/Stack';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect } from 'react';
import { useParams } from 'react-router';

import { PageContent } from 'src/components/pageContent/PageContent';
import { recycleBinContentModeSelector } from 'src/store/features/displaySettingSlice';
import { useAppSelector } from 'src/store/store';
import { NETWORK_ERROR_DESCR, NETWORK_ERROR_TITLE } from 'src/strings';
import {
    RECYCLE_BIN_BREADCRUMB,
    workspaceListingWithLinkBreadcrumb,
    workspacesPath,
} from 'src/utils/breadcrumbs';

import { RecycleBinFileContent } from './recycledFilesContent/RecycleBinFileContent';
import { RecycleBinObjectContent } from './recycledObjectsContent/RecycleBinObjectContent';

export function RecycleBinPage({ tabs }: { tabs: JSX.Element }) {
    const params = useParams();
    const { evouiEnableDelete } = useFlags();

    const isAdmin = checkIsAdmin();
    const orgId = getOrgUuidFromParams(params);
    const workspaceId = getSelectedWorkspaceFromParams(params);
    const {
        data: workspace,
        isLoading: isLoadingWorkspace,
        isError: isErrorWorkspace,
    } = fetchWorkSpaceData(params, isAdmin);

    const recycleBinContentMode = useAppSelector(recycleBinContentModeSelector);

    const { setBreadcrumbs } = useBreadcrumbs();
    useEffect(() => {
        if (!isLoadingWorkspace && workspace) {
            const segments: BreadcrumbSegment[] = [
                ...workspaceListingWithLinkBreadcrumb(params),
                {
                    name: workspace.name,
                    path: `${workspacesPath(params)}/${params.hubCode}/${params.workspaceUuid}/${WORKSPACES_OVERVIEW_PAGE}`,
                },
                { name: RECYCLE_BIN_BREADCRUMB },
            ];
            setBreadcrumbs(segments);
        }
    }, [isLoadingWorkspace, recycleBinContentMode]);

    const { data: currentUserRoleData, isLoading: isLoadingRole } = useGetCurrentUserRoleQuery({
        hubUrl: getHubUrlForCurrentOrg(),
        orgId,
        workspaceId,
    });

    const canRestore = hasRoleOrHigher(currentUserRoleData?.role ?? null, 'editor');

    const isLoading = isLoadingWorkspace || isLoadingRole;

    if (!evouiEnableDelete) {
        return null;
    }

    if (isErrorWorkspace || (!isLoadingWorkspace && !workspace)) {
        return <ErrorScreen msg={NETWORK_ERROR_TITLE} details={NETWORK_ERROR_DESCR} />;
    }

    return (
        <PageContent
            titleActions={tabs}
            pageTitle={workspace?.name ?? ''}
            pageTitleLoading={isLoading}
        >
            {!isLoading && (
                <Stack sx={{ width: '100%' }}>
                    {recycleBinContentMode === 'objects' ? (
                        <RecycleBinObjectContent
                            orgId={orgId}
                            workspaceId={workspaceId}
                            canRestore={canRestore}
                        />
                    ) : (
                        <RecycleBinFileContent
                            orgId={orgId}
                            workspaceId={workspaceId}
                            canRestore={canRestore}
                        />
                    )}
                </Stack>
            )}
        </PageContent>
    );
}

import { Upload } from '@local/web-design-system-2';
import { hasRoleOrHigher } from '@local/workspaces/dist/utils/permissions';
import Button from '@mui/material/Button/Button';
import Grid from '@mui/material/Grid/Grid';
import type { SxProps } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import { useRef } from 'react';

import { useWorkspaceContext } from 'src/contexts/WorkspaceContext';
import { INSUFFICIENT_WORKSPACE_PERMISSION } from 'src/strings';

import { useFileHandler } from '../../hooks/useFileHandler';
import { useStyles } from './UploadFilesButton.styles';

export const UploadFilesButton = ({
    buttonText,
    disabled,
    secondary = false,
    sx = {},
    variant = 'contained',
}: {
    buttonText: string;
    disabled?: boolean;
    secondary?: boolean;
    sx?: SxProps;
    variant?: 'contained' | 'outlined';
}) => {
    const { classes } = useStyles();
    const uploadFieldRef = useRef<HTMLInputElement>(null);
    const { workspaceUserRole } = useWorkspaceContext();

    const isViewer = workspaceUserRole ? !hasRoleOrHigher(workspaceUserRole, 'editor') : false;
    const isDisabled = disabled || isViewer;
    const { upsertFilesByPath } = useFileHandler();

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const currentFiles = event.currentTarget.files;
        if (!currentFiles) {
            return;
        }

        await upsertFilesByPath(currentFiles, uploadFieldRef);
    };

    return (
        <Tooltip title={isViewer ? INSUFFICIENT_WORKSPACE_PERMISSION : ''}>
            <Button
                component="label"
                disabled={isDisabled}
                color={secondary ? 'inherit' : 'primary'}
                variant={variant}
                automation-id="upload-files-button"
                fullWidth
                sx={sx}
            >
                <Grid container>
                    <Grid className={classes.buttonText}>
                        <Upload fontSize="small" />
                        {buttonText}
                    </Grid>
                    <input
                        ref={uploadFieldRef}
                        type="file"
                        style={{ display: 'none' }}
                        multiple
                        onChange={handleFileChange}
                    />
                </Grid>
            </Button>
        </Tooltip>
    );
};

import { REGISTER_INTEREST, REQUEST_A_DEMO, REQUEST_A_TRIAL } from 'src/strings';

export const products = [
    {
        name: 'BlockSync',
        logoName: 'BlockSyncLogoRGB',
        tags: ['Evo application'],
        isIntegration: false,
        loginLink: 'https://blocksync.seequent.com/',
        status: 'eap',
        descriptionPrimary: 'Block model data management',
        descriptionSecondary:
            'BlockSync offers an open, auditable system of record where you can collaborate on block model data from any source.',
        learnMore: 'https://www.seequent.com/products-solutions/seequent-evo/',
        cta: {
            label: REGISTER_INTEREST,
            link: 'https://seequent.qualtrics.com/jfe/form/SV_2mDGAqRaeuY2qea',
        },
    },
    {
        name: 'MxDeposit',
        logoName: 'MXDepositLogoWhite',
        tags: ['Evo integration'],
        isIntegration: false,
        loginLink: 'https://app.mxdeposit.net/login?next=http:%2F%2Fapp.mxdeposit.net%2F',
        status: 'active',
        descriptionPrimary: 'Real-time collaboration and decision-making',
        descriptionSecondary:
            'Collect, manage, share, and access your drillhole and point sample data in the cloud with MX Deposit.',
        learnMore: 'https://www.seequent.com/products-solutions/seequent-evo/',
        cta: {
            label: REQUEST_A_DEMO,
            link: 'https://www.seequent.com/products-solutions/mx-deposit/enquire/',
        },
    },
    {
        name: 'Imago',
        logoName: 'ImagoLogoWhite',
        tags: ['Mining'],
        isIntegration: false,
        loginLink: 'https://imago.seequent.com/login',
        status: 'active',
        descriptionPrimary: 'Validate data insights with geoscientific imagery',
        descriptionSecondary:
            'Easily access, collaborate, and validate drillhole and sample data using your high-quality geoscientific imagery.',
        learnMore: 'https://www.seequent.com/products-solutions/imago/',
        cta: {
            label: REQUEST_A_TRIAL,
            link: 'https://www.seequent.com/products-solutions/imago/enquire/',
        },
    },
    {
        name: 'Central',
        logoName: 'CentralLogoWhite',
        tags: ['Cloud Service', 'Collaboration'],
        isIntegration: false,
        loginLink: 'https://central.seequent.com/',
        status: 'active',
        descriptionPrimary: 'Bring teams and data together with Seequent Central',
        descriptionSecondary:
            'Seequent Central enables connected workflows, real time collaboration, and effective data management.',
        learnMore: 'https://www.seequent.com/products-solutions/seequent-central/',
        cta: {
            label: REQUEST_A_TRIAL,
            link: 'https://www.seequent.com/products-solutions/seequent-central/trial/',
        },
    },
];

import type { OrgListedObject } from '@api/goose/dist/enhancedGooseClient';
import type { Billboard, CesiumWidget, Scene } from '@cesium/engine';
import { useEffect, useState } from 'react';

import { useDiscoverContext } from 'src/contexts/DiscoverContext';

import type { ClusterProps } from '../../Types/CustomTypes';
import { ObjectIconGroup } from './ObjectIconGroup';

interface ClusterInfoProps {
    cluster: ClusterProps;
    scene: Scene; // Add the scene as a prop
    cesiumWidget: CesiumWidget;
}

export const ClusterInfo = ({ cluster, scene, cesiumWidget }: ClusterInfoProps) => {
    const { objects, activeObject } = useDiscoverContext();
    const [canvasPosition, setCanvasPosition] = useState<{ x: number; y: number } | null>(null);

    const matchedObjects = cluster.points
        .map((point: Billboard) => objects.find((obj: any) => obj.object_id === point.id.id))
        .filter((obj: any): obj is OrgListedObject => obj !== undefined);

    useEffect(() => {
        let animationFrameId: number;

        const updatePosition = () => {
            if (scene && cluster.center) {
                const position = scene.cartesianToCanvasCoordinates(cluster.center);
                setCanvasPosition({ x: position.x, y: position.y });
            }
            animationFrameId = requestAnimationFrame(updatePosition);
        };
        updatePosition();

        return () => cancelAnimationFrame(animationFrameId);
    }, [scene, cluster.center]);

    return (
        <div
            style={{
                position: 'absolute',
                zIndex: 100,
                left: canvasPosition?.x,
                top: canvasPosition?.y,
            }}
        >
            <ObjectIconGroup
                objects={matchedObjects}
                activeObject={activeObject}
                cesiumWidget={cesiumWidget}
            />
        </div>
    );
};

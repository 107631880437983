import { fileClientEndpoint } from './GENERATED_fileClientEndpoints';

export const enhancedFileClient = fileClientEndpoint.enhanceEndpoints({
    addTagTypes: ['File'],
    endpoints: {
        listFiles: {
            providesTags: ['File'],
        },
        getFileById: {
            providesTags: (file) => [{ type: 'File', id: file?.file_id }],
        },
        updateFileById: {
            invalidatesTags: ['File'],
        },
        deleteFileById: {
            invalidatesTags: ['File'],
        },
        upsertFileByPath: {
            invalidatesTags: ['File'],
        },
    },
});

export const {
    useListFilesQuery,
    useLazyGetFileByIdQuery,
    useUpdateFileByIdMutation,
    useDeleteFileByIdMutation,
    useUpsertFileByPathMutation,
} = enhancedFileClient;

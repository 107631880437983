import DefaultObject from '@local/web-design-system-2/dist/icons/DefaultObject';
import DesignModelRendered from '@local/web-design-system-2/dist/icons/DesignModelRendered';
import Drillhole from '@local/web-design-system-2/dist/icons/Drillhole';
import GeologicalModel from '@local/web-design-system-2/dist/icons/GeologicalModel';
import GlobalEllpsoids from '@local/web-design-system-2/dist/icons/GlobalEllpsoids';
import Grid2D from '@local/web-design-system-2/dist/icons/Grid2D';
import Lineations from '@local/web-design-system-2/dist/icons/Lineations';
import LineSegments from '@local/web-design-system-2/dist/icons/LineSegments';
import LocalEllpsoids from '@local/web-design-system-2/dist/icons/LocalEllpsoids';
import Locations from '@local/web-design-system-2/dist/icons/Locations';
import MergedPointset from '@local/web-design-system-2/dist/icons/MergedPointset';
import Mesh from '@local/web-design-system-2/dist/icons/Mesh';
import Structural from '@local/web-design-system-2/dist/icons/Structural';
import TensorGridBlockTree from '@local/web-design-system-2/dist/icons/TensorGridBlockTree';
import TransformValues from '@local/web-design-system-2/dist/icons/TransformValues';
import Variogram from '@local/web-design-system-2/dist/icons/Variogram';

import { extractSchema } from 'src/utils/schemaUtils';
import { Schemas } from 'src/visualization/constants';

export const SchemaIcon = (schema?: string) => {
    const iconsDictionary = {
        [Schemas.DesignGeometrySchema]: DesignModelRendered,
        [Schemas.DownholeCollectionSchema]: Drillhole,
        [Schemas.DownholeIntervalsSchema]: Drillhole,
        [Schemas.ExploratoryLocationSchema]: Locations,
        [Schemas.GeologicalModelMeshesSchema]: GeologicalModel,
        [Schemas.GeophysicalRecords1DSchema]: GeologicalModel,
        [Schemas.GlobalEllipsoidSchema]: GlobalEllpsoids,
        [Schemas.IntervalDownholesSchema]: Drillhole,
        [Schemas.LineSegmentsSchema]: LineSegments,
        [Schemas.LineationsDataPointsetSchema]: Lineations,
        [Schemas.LocalEllipsoidsSchema]: LocalEllpsoids,
        [Schemas.NonParametricContinuousCumulativeDistributionSchema]: TransformValues,
        [Schemas.PlanarDataPointsetSchema]: Structural,
        [Schemas.PointsetSchema]: MergedPointset,
        [Schemas.Regular2DGridSchema]: Grid2D,
        [Schemas.Regular3DGridSchema]: Grid2D,
        [Schemas.RegularMasked3DGridSchema]: Grid2D,
        [Schemas.Tensor2DGridSchema]: TensorGridBlockTree,
        [Schemas.Tensor3DGridSchema]: TensorGridBlockTree,
        [Schemas.TriangleMeshSchema]: Mesh,
        [Schemas.UnstructuredGridSchema]: Grid2D,
        [Schemas.UnstructuredHexGridSchema]: Grid2D,
        [Schemas.UnstructuredQuadGridSchema]: Grid2D,
        [Schemas.UnstructuredTetGridSchema]: Grid2D,
        [Schemas.VariogramSchema]: Variogram,
    };

    const gooseType = schema && extractSchema(schema);
    if (gooseType && gooseType in iconsDictionary) {
        return iconsDictionary[gooseType as keyof typeof iconsDictionary];
    }

    return DefaultObject;
};

import type { RestError } from '@azure/storage-blob';
import { BlockBlobClient } from '@azure/storage-blob';
import type { SerializedError } from '@reduxjs/toolkit';
import type { BaseQueryApi, FetchBaseQueryError } from '@reduxjs/toolkit/query';

import { RETRIES, WAIT_TIME_MS } from 'src/constants';
import {
    FILE_API_ERROR_DELETED,
    FILE_API_ERROR_INITIAL_UPLOAD_FAILED,
    FILE_API_ERROR_WORKSPACE_NOT_FOUND,
} from 'src/strings';
import { UploadStatus } from 'src/types/files';

import { enhancedFileClient } from './enhancedFileMiddleware';
import { fileClient } from './fileClient';
import type {
    GetFileByIdApiResponse,
    GetFileByPathApiResponse,
    UpdateFileByIdApiArg,
    UploadFileResponse,
    UpsertFileByPathApiArg,
} from './GENERATED_fileClientEndpoints';
import { waitForMs } from './utils';

const MIN_BLOCKS = 5;
const MAX_BLOCKS = 50_000;
const UPLOAD_CONCURRENCY = 5;
const DEFAULT_BLOCK_SIZE = 200 * 1024; // 200KiB;

interface CustomUpdateFilesByIdApiArgs extends UpdateFileByIdApiArg {
    updatedVersion: File;
    updateFileStatus: (status: UploadStatus, percent: number) => void;
    abortSignal: AbortSignal;
}

interface CustomUpsertFileByPathApiArgs extends UpsertFileByPathApiArg {
    uploadFile: File;
    updateFileStatus: (status: UploadStatus, percent: number) => void;
    abortSignal: AbortSignal;
}

interface SeequentApiError {
    status: number;
    data: {
        status: number;
        title?: string;
        type?: string;
        detail?: string;
        upstream_type?: string;
    };
}

const isSeequentApiError = (
    err: FetchBaseQueryError | SerializedError,
): err is SeequentApiError => {
    if ('status' in err) {
        const data = err.data as object;
        return data !== undefined && 'status' in data && 'title' in data && 'type' in data;
    }
    return false;
};

const errorFromFetchError = (err: FetchBaseQueryError | SerializedError): Error | undefined => {
    if (isSeequentApiError(err)) {
        if (err.status === 410) {
            return new Error(FILE_API_ERROR_DELETED);
        }
        if (err.status === 404 && err.data.upstream_type?.endsWith('/workspace/not-found')) {
            return new Error(FILE_API_ERROR_WORKSPACE_NOT_FOUND);
        }
    }
    return undefined;
};

class ChunkedBlobUploader {
    private blockBlobClient: BlockBlobClient;

    private refreshUploadUrl: () => Promise<string | null>;

    private updateFileStatus: (status: UploadStatus, percent: number) => void;

    private abortSignal: AbortSignal;

    private uploadQueue: Promise<void>[] = [];

    private bytesUploaded = 0;

    private fileSize = 0;

    constructor(
        uploadURL: string,
        refreshUploadUrl: () => Promise<string | null>,
        updateFileStatus: (status: UploadStatus, percent: number) => void,
        abortSignal: AbortSignal,
    ) {
        this.blockBlobClient = new BlockBlobClient(uploadURL);
        this.refreshUploadUrl = refreshUploadUrl;
        this.updateFileStatus = updateFileStatus;
        this.abortSignal = abortSignal;
    }

    public async uploadFile(file: File) {
        let blockIndex = 0;
        this.fileSize = file.size;
        const blockIds: string[] = [];

        // Calculate preferred block size based on file size.
        const maxBlockSize = this.fileSize / MIN_BLOCKS;
        const minBlockSize = this.fileSize / MAX_BLOCKS;
        const blockSize = Math.max(minBlockSize, Math.min(DEFAULT_BLOCK_SIZE, maxBlockSize));

        // Upload the file in chunks of blockSize. Update the progress bar after each chunk.
        /* eslint-disable no-await-in-loop */
        for (let offset = 0; offset < this.fileSize; offset += blockSize) {
            const chunkSize = Math.min(blockSize, this.fileSize - offset);
            const chunk = file.slice(offset, offset + chunkSize);
            const blockId = btoa(`block-${blockIndex.toString().padStart(6, '0')}`);
            blockIds.push(blockId);
            blockIndex += 1;

            // Add the upload promise to the queue.
            const promise = this.stageBlock(blockId, chunk, chunkSize).then(() => {
                this.bytesUploaded += chunkSize;
                this.updateStatus(UploadStatus.Uploading);
                this.uploadQueue.splice(this.uploadQueue.indexOf(promise), 1);
            });
            this.uploadQueue.push(promise);

            // Ensure we don't exceed the maximum concurrency.
            if (this.uploadQueue.length >= UPLOAD_CONCURRENCY) {
                await Promise.race(this.uploadQueue);
            }
        }

        // Commit the block list to Azure.
        await Promise.all(this.uploadQueue);
        await this.blockBlobClient.commitBlockList(blockIds);
    }

    private updateStatus(uploadStatus: UploadStatus) {
        this.updateFileStatus(
            uploadStatus,
            Math.min((this.bytesUploaded / this.fileSize) * 100, 99),
        );
    }

    /**
     * Upload the block to Azure. Refresh upload url if we receive a 403.
     */
    private async stageBlock(blockId: string, chunk: Blob, chunkSize: number) {
        try {
            return await this.blockBlobClient.stageBlock(blockId, chunk, chunkSize, {
                abortSignal: this.abortSignal,
            });
        } catch (error) {
            // Check if the upload has been aborted.
            if (this.abortSignal.aborted) {
                this.updateStatus(UploadStatus.Cancelled);
                throw new Error('Upload aborted');
            }

            // Only retry if the error is a 403 Forbidden error. This indicates expired signature.
            if ((error as RestError).statusCode !== 403) {
                console.error('Error uploading blob:', error);
                throw error;
            }

            // Try to refresh the upload URL.
            const newUploadURL = await this.refreshUploadUrl();
            if (newUploadURL) {
                this.blockBlobClient = new BlockBlobClient(newUploadURL);
            } else {
                console.error('Error uploading blob:', error);
                throw error;
            }

            // Try upload one more time with new upload url.
            return await this.blockBlobClient.stageBlock(blockId, chunk, chunkSize, {
                abortSignal: this.abortSignal,
            });
        }
    }
}

async function fetchUploadUrl(
    args: CustomUpsertFileByPathApiArgs | CustomUpdateFilesByIdApiArgs,
    queryApi: BaseQueryApi,
    versionID?: string | null,
): Promise<{ result: UploadFileResponse | null; error: FetchBaseQueryError | null }> {
    const { workspaceId, organisationId } = args;
    let response;
    if ('fileId' in args) {
        response = await queryApi.dispatch(
            enhancedFileClient.endpoints.updateFileById.initiate({
                fileId: args.fileId,
                organisationId,
                workspaceId,
                versionId: versionID,
            }),
        );
    } else {
        response = await queryApi.dispatch(
            enhancedFileClient.endpoints.upsertFileByPath.initiate({
                filePath: encodeURIComponent(args.filePath),
                organisationId,
                workspaceId,
                versionId: versionID,
            }),
        );
    }

    if (response.error) {
        const message =
            'status' in response.error && response.error.status === 410
                ? FILE_API_ERROR_DELETED
                : FILE_API_ERROR_INITIAL_UPLOAD_FAILED;
        const error = new Error(message) as unknown as FetchBaseQueryError;
        return { result: null, error };
    }

    return { result: response.data as UploadFileResponse, error: null };
}

const uploadWithPolling = async (
    args: CustomUpsertFileByPathApiArgs | CustomUpdateFilesByIdApiArgs,
    queryApi: BaseQueryApi,
) => {
    const { workspaceId, organisationId, abortSignal } = args;
    const { result: uploadResult, error: errorResult } = await fetchUploadUrl(args, queryApi);

    if (!uploadResult) {
        return { error: errorResult as unknown as FetchBaseQueryError };
    }
    const { file_id: fileId, upload, version_id: versionId } = uploadResult;

    // Define a function to refresh the upload URL for the given version.
    const refreshUploadUrl = async () => {
        const { result: refreshResult, error: refreshError } = await fetchUploadUrl(
            args,
            queryApi,
            versionId,
        );
        if (!refreshResult) {
            console.error('Failed to refresh the upload URL:', refreshError);
            return null;
        }
        return refreshResult.upload;
    };

    // Upload the file to blob storage in chunks.
    const fetchBody = 'fileId' in args ? args.updatedVersion : args.uploadFile;
    const uploader = new ChunkedBlobUploader(
        upload,
        refreshUploadUrl,
        args.updateFileStatus,
        abortSignal,
    );
    try {
        await uploader.uploadFile(fetchBody);
    } catch (error: unknown) {
        return { error: error as FetchBaseQueryError };
    }

    // We need to wait for the backend to validate that the file has been uploaded to Azure.
    // Since the chunks have been uploaded, we can no longer cancel the upload (immutable status).
    /* eslint-disable no-await-in-loop */
    let fetchCount = 0;
    args.updateFileStatus(UploadStatus.Immutable, 99);

    while (fetchCount < RETRIES) {
        fetchCount += 1;

        await waitForMs(WAIT_TIME_MS);

        const {
            data: newFile,
            isError,
            error: fetchError,
        } = await queryApi.dispatch(
            enhancedFileClient.endpoints.getFileById.initiate(
                {
                    organisationId,
                    workspaceId,
                    fileId,
                    versionId,
                    includeVersions: true,
                },
                { forceRefetch: true, subscribe: false },
            ),
        );

        if (isError) {
            const newError = errorFromFetchError(fetchError);
            if (newError) {
                return { error: newError as unknown as FetchBaseQueryError };
            }
        }

        // When the new file version is available we can invalidate the "get file by ID" cache for
        // this file, triggering a re-fetch (which shows the new version), then return the new file
        if (newFile) {
            return { data: newFile };
        }
    }

    const error = new Error(
        `File upload failed after ${(RETRIES * WAIT_TIME_MS) / 1000} seconds`,
    ) as unknown as FetchBaseQueryError;
    return { error };
};

export const injectedFileClient = fileClient.injectEndpoints({
    endpoints: (build) => ({
        customUploadFileById: build.mutation<GetFileByIdApiResponse, CustomUpdateFilesByIdApiArgs>({
            /**
             * Upsert a file by id. Will register the new file with the File API, upload it to Azure blob storage,
             * poll until it's finished uploading, then return the new file at the end.
             */
            queryFn: uploadWithPolling,
            invalidatesTags: (file) => ['File', { type: 'File', id: file?.file_id }],
        }),
        customUpsertFileByPath: build.mutation<
            GetFileByPathApiResponse,
            CustomUpsertFileByPathApiArgs
        >({
            /**
             * Upsert a file by path. Will register the new file with the File API, upload it to Azure blob storage,
             * poll until it's finished uploading, then return the new file at the end.
             */
            queryFn: uploadWithPolling,
            invalidatesTags: (file) => ['File', { type: 'File', id: file?.file_id }],
        }),
    }),
});

export const { useCustomUploadFileByIdMutation, useCustomUpsertFileByPathMutation } =
    injectedFileClient;

import { makeStyles } from 'tss-react/mui';

const styles = () =>
    ({
        overlayDialog: {
            position: 'absolute',
            height: '100%',
        },
    }) as const;

export const useStyles = makeStyles()(styles);

import { FilePropertiesEmpty } from '@local/svgs/dist/svg/FilePropertiesEmpty';
import { hasRoleOrHigher } from '@local/workspaces/dist/utils/permissions';
import Alert from '@mui/material/Alert';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { PanelDragAndDrop } from 'src/components/dragAndDrop/PanelDragAndDrop';
import { useWorkspaceContext } from 'src/contexts/WorkspaceContext';
import { useFileDragAndDrop } from 'src/hooks/useFileDragAndDrop';
import { FILE_PROPERTIES, NO_SELECTED_FILE, UPLOAD_FILES } from 'src/strings';

import { useStyles } from './Empty.styles';

interface Props {
    error?: string;
}

export const Empty = ({ error }: Props) => {
    const { classes } = useStyles();
    const { isHighlighted, ...dragProps } = useFileDragAndDrop();
    const { workspaceUserRole } = useWorkspaceContext();

    const isEditor = workspaceUserRole ? hasRoleOrHigher(workspaceUserRole, 'editor') : false;

    return (
        <Stack spacing={2}>
            <Typography variant="h5">{FILE_PROPERTIES}</Typography>
            <Divider />
            <Grid container className={classes.container} automation-id="empty-sidebar">
                {error && <Alert severity="error">{error}</Alert>}
                <div>
                    <FilePropertiesEmpty />
                </div>
                <Typography variant="body2">{NO_SELECTED_FILE}</Typography>
            </Grid>
            {isEditor && (
                <>
                    <Typography variant="h5">{UPLOAD_FILES}</Typography>
                    <Divider />
                    <Grid
                        {...dragProps}
                        className={isHighlighted ? classes.highlight : classes.dragAndDropContainer}
                    >
                        <div>
                            <PanelDragAndDrop isHighlighted={isHighlighted} />
                        </div>
                    </Grid>
                </>
            )}
        </Stack>
    );
};

import type { BoundingBox } from '../../Types/CustomTypes';

export const convertBoundingBox = (boundingBox: BoundingBox) => {
    const { minLat, minLng, maxLat, maxLng } = boundingBox;

    return {
        nw: {
            lng: minLng,
            lat: maxLat,
        },
        ne: {
            lng: maxLng,
            lat: maxLat,
        },
        se: {
            lng: maxLng,
            lat: minLat,
        },
        sw: {
            lng: minLng,
            lat: minLat,
        },
    };
};

export const SEARCH_BOUNDS = {
    minLat: -90,
    minLng: -180,
    maxLat: 90,
    maxLng: 180,
};

import {
    getAdminFromBentleyAccessToken,
    isLoggedInWithBentleyToken,
} from '@local/login/dist/store/sessionStorageHelpers/accessTokenHelper/accessTokenHelper';
import { getAdminFromToken } from '@local/login/dist/store/sessionStorageHelpers/entitlementsHelper/entitlementsHelper';
import { getOrgUuidFromParams } from '@local/workspaces/dist/components/OrgRouteGuard/OrgRouteGuard';
import { fetchWorkspaces } from '@local/workspaces/src/apiClients/workspaceClientEndpoints';
import { getHubUrlForCurrentOrg } from '@local/workspaces/src/components/OrgRouteGuard/OrgRouteGuard';
import { useParams } from 'react-router';

export interface FirewallGaurdProps {
    children: JSX.Element;
    errorComponent: JSX.Element;
}

export default function FirewallGaurd({ children, errorComponent }: FirewallGaurdProps) {
    let isAdmin;
    if (isLoggedInWithBentleyToken()) {
        isAdmin = getAdminFromBentleyAccessToken() ?? false;
    } else {
        isAdmin = getAdminFromToken();
    }
    const params = useParams();
    const rowsPerPage = 20;
    const page = 0;
    const searchTerm = '';
    const orderByString = 'name';

    const { isError, error } = fetchWorkspaces({
        isAdmin,
        hubUrl: getHubUrlForCurrentOrg(),
        orgId: getOrgUuidFromParams(params),
        limit: rowsPerPage,
        offset: page * rowsPerPage,
        filter: {
            name: searchTerm,
        },
        orderBy: orderByString,
    });

    if (isError && (error as any).status === 403) {
        return errorComponent;
    }

    return children;
}

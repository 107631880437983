import Typography from '@mui/material/Typography';

import { SchemaIcon } from 'src/pages/workspacePage/workspaceContent/SchemaIcon';

import { useObjectIconStyles, MARKER_ICON_BASE_WIDTH } from './Clustering.styles';

interface ObjectIconProps {
    name: string;
    schema: string;
    objectID: string;
    active?: boolean;
    hovered?: boolean;
    onClick?: (objectID: string) => void;
}

// TODO Figure out why the classnames aren't working, but direct styles are, wtf.
// I think this is because of renderToString
export function ObjectIcon({
    name,
    schema,
    onClick,
    objectID,
    active = false,
    hovered = false,
}: ObjectIconProps) {
    const { classes } = useObjectIconStyles({ active, hovered });
    const Icon = SchemaIcon(schema);
    const onMarkerClick = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        onClick?.(objectID);
    };

    /* eslint-disable jsx-a11y/click-events-have-key-events */
    /* eslint-disable jsx-a11y/no-static-element-interactions */
    return (
        <div className={classes.markerIconBase} onClick={onMarkerClick}>
            <Icon
                style={{
                    minWidth: `${MARKER_ICON_BASE_WIDTH}px`,
                    minHeight: `${MARKER_ICON_BASE_WIDTH}px`,
                    maxWidth: `${MARKER_ICON_BASE_WIDTH}px`,
                    maxHeight: `${MARKER_ICON_BASE_WIDTH}px`,
                }}
            />
            <div id="objectMarkerText" className={classes.objectMarkerText}>
                <Typography
                    className={classes.objectMarkerTypography}
                    style={
                        {
                            margin: 0,
                            paddingLeft: '9px',
                            textWrap: 'noWrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            maxWidth: '25ch',
                        } as any
                    }
                >
                    {name}
                </Typography>
            </div>
        </div>
    );
}

import Check from '@local/web-design-system-2/dist/icons/Check';
import Error from '@local/web-design-system-2/dist/icons/Error';

import { UploadStatus } from 'src/types/files';

interface Props {
    status: UploadStatus;
}

export const UploadStatusIcon = ({ status }: Props) => {
    if (status === UploadStatus.Uploaded) {
        return <Check color="success" />;
    }
    if (status === UploadStatus.Failed) {
        return <Error color="error" />;
    }
    if (status === UploadStatus.Cancelled) {
        return <Check color="info" />;
    }
    return null;
};

import { workspaceClient as api } from './workspaceClient';

const injectedRtkApi = api.injectEndpoints({
    endpoints: (build) => ({
        bulkAssignRolesAdmin: build.mutation<
            BulkAssignRolesAdminApiResponse,
            BulkAssignRolesAdminApiArg
        >({
            query: (queryArg) => ({
                url: `${queryArg.hubUrl}/workspace/admin/orgs/${queryArg.orgId}/action/bulk_assign_roles`,
                method: 'POST',
                body: queryArg.bulkUserRoleAssignmentsRequest,
            }),
        }),
        updateMlEnablementAdmin: build.mutation<
            UpdateMlEnablementAdminApiResponse,
            UpdateMlEnablementAdminApiArg
        >({
            query: (queryArg) => ({
                url: `${queryArg.hubUrl}/workspace/admin/orgs/${queryArg.orgId}/action/update_ml_enablement`,
                method: 'POST',
                body: queryArg.mlEnablementRequest,
                headers: { 'preview-api': 'opt-in' },
            }),
        }),
        getOrganizationSettings: build.query<
            GetOrganizationSettingsApiResponse,
            GetOrganizationSettingsApiArg
        >({
            query: (queryArg) => ({
                url: `${queryArg.hubUrl}/workspace/admin/orgs/${queryArg.orgId}/settings`,
            }),
        }),
        listUserWorkspacesAdmin: build.query<
            ListUserWorkspacesAdminApiResponse,
            ListUserWorkspacesAdminApiArg
        >({
            query: (queryArg) => ({
                url: `${queryArg.hubUrl}/workspace/admin/orgs/${queryArg.orgId}/users/${queryArg.userId}/workspaces`,
                params: {
                    limit: queryArg.limit,
                    offset: queryArg.offset,
                    sort: queryArg.sort,
                    order_by: queryArg.orderBy,
                    filter: queryArg.filter,
                    created_at: queryArg.createdAt,
                    updated_at: queryArg.updatedAt,
                    name: queryArg.name,
                    deleted: queryArg.deleted,
                },
            }),
        }),
        listWorkspacesAdmin: build.query<ListWorkspacesAdminApiResponse, ListWorkspacesAdminApiArg>(
            {
                query: (queryArg) => ({
                    url: `${queryArg.hubUrl}/workspace/admin/orgs/${queryArg.orgId}/workspaces`,
                    params: {
                        limit: queryArg.limit,
                        offset: queryArg.offset,
                        sort: queryArg.sort,
                        order_by: queryArg.orderBy,
                        filter: queryArg.filter,
                        created_at: queryArg.createdAt,
                        updated_at: queryArg.updatedAt,
                        name: queryArg.name,
                        deleted: queryArg.deleted,
                    },
                }),
            },
        ),
        getWorkspaceAdmin: build.query<GetWorkspaceAdminApiResponse, GetWorkspaceAdminApiArg>({
            query: (queryArg) => ({
                url: `${queryArg.hubUrl}/workspace/admin/orgs/${queryArg.orgId}/workspaces/${queryArg.workspaceId}`,
                params: { deleted: queryArg.deleted },
            }),
        }),
        getThumbnailAdmin: build.query<GetThumbnailAdminApiResponse, GetThumbnailAdminApiArg>({
            query: (queryArg) => ({
                url: `${queryArg.hubUrl}/workspace/admin/orgs/${queryArg.orgId}/workspaces/${queryArg.workspaceId}/thumbnail`,
            }),
        }),
        listUserRolesAdmin: build.query<ListUserRolesAdminApiResponse, ListUserRolesAdminApiArg>({
            query: (queryArg) => ({
                url: `${queryArg.hubUrl}/workspace/admin/orgs/${queryArg.orgId}/workspaces/${queryArg.workspaceId}/users`,
                params: { filter: queryArg.filter },
            }),
        }),
        assignUserRoleAdmin: build.mutation<
            AssignUserRoleAdminApiResponse,
            AssignUserRoleAdminApiArg
        >({
            query: (queryArg) => ({
                url: `${queryArg.hubUrl}/workspace/admin/orgs/${queryArg.orgId}/workspaces/${queryArg.workspaceId}/users`,
                method: 'POST',
                body: queryArg.assignRoleRequest,
                headers: { 'preview-api': 'opt-in' },
            }),
        }),
        deleteUserRoleAdmin: build.mutation<
            DeleteUserRoleAdminApiResponse,
            DeleteUserRoleAdminApiArg
        >({
            query: (queryArg) => ({
                url: `${queryArg.hubUrl}/workspace/admin/orgs/${queryArg.orgId}/workspaces/${queryArg.workspaceId}/users/${queryArg.userId}`,
                method: 'DELETE',
            }),
        }),
        listCoordinateSystems: build.query<
            ListCoordinateSystemsApiResponse,
            ListCoordinateSystemsApiArg
        >({
            query: (queryArg) => ({
                url: `${queryArg.hubUrl}/workspace/orgs/${queryArg.orgId}/coordinate_systems`,
            }),
        }),
        listWorkspaces: build.query<ListWorkspacesApiResponse, ListWorkspacesApiArg>({
            query: (queryArg) => ({
                url: `${queryArg.hubUrl}/workspace/orgs/${queryArg.orgId}/workspaces`,
                params: {
                    limit: queryArg.limit,
                    offset: queryArg.offset,
                    sort: queryArg.sort,
                    order_by: queryArg.orderBy,
                    filter: queryArg.filter,
                    created_at: queryArg.createdAt,
                    updated_at: queryArg.updatedAt,
                    name: queryArg.name,
                    deleted: queryArg.deleted,
                },
            }),
        }),
        createWorkspace: build.mutation<CreateWorkspaceApiResponse, CreateWorkspaceApiArg>({
            query: (queryArg) => ({
                url: `${queryArg.hubUrl}/workspace/orgs/${queryArg.orgId}/workspaces`,
                method: 'POST',
                body: queryArg.createWorkspaceRequest,
            }),
        }),
        deleteWorkspace: build.mutation<DeleteWorkspaceApiResponse, DeleteWorkspaceApiArg>({
            query: (queryArg) => ({
                url: `${queryArg.hubUrl}/workspace/orgs/${queryArg.orgId}/workspaces/${queryArg.workspaceId}`,
                method: 'DELETE',
            }),
        }),
        getWorkspace: build.query<GetWorkspaceApiResponse, GetWorkspaceApiArg>({
            query: (queryArg) => ({
                url: `${queryArg.hubUrl}/workspace/orgs/${queryArg.orgId}/workspaces/${queryArg.workspaceId}`,
                params: { deleted: queryArg.deleted },
            }),
        }),
        updateWorkspace: build.mutation<UpdateWorkspaceApiResponse, UpdateWorkspaceApiArg>({
            query: (queryArg) => ({
                url: `${queryArg.hubUrl}/workspace/orgs/${queryArg.orgId}/workspaces/${queryArg.workspaceId}`,
                method: 'PATCH',
                body: queryArg.updateWorkspaceRequest,
            }),
        }),
        restoreSoftDeletedWorkspace: build.mutation<
            RestoreSoftDeletedWorkspaceApiResponse,
            RestoreSoftDeletedWorkspaceApiArg
        >({
            query: (queryArg) => ({
                url: `${queryArg.hubUrl}/workspace/orgs/${queryArg.orgId}/workspaces/${queryArg.workspaceId}`,
                method: 'POST',
                params: { deleted: queryArg.deleted },
            }),
        }),
        getCurrentUserRole: build.query<GetCurrentUserRoleApiResponse, GetCurrentUserRoleApiArg>({
            query: (queryArg) => ({
                url: `${queryArg.hubUrl}/workspace/orgs/${queryArg.orgId}/workspaces/${queryArg.workspaceId}/current-user-role`,
            }),
        }),
        deleteWorkspaceThumbnail: build.mutation<
            DeleteWorkspaceThumbnailApiResponse,
            DeleteWorkspaceThumbnailApiArg
        >({
            query: (queryArg) => ({
                url: `${queryArg.hubUrl}/workspace/orgs/${queryArg.orgId}/workspaces/${queryArg.workspaceId}/thumbnail`,
                method: 'DELETE',
            }),
        }),
        getThumbnail: build.query<GetThumbnailApiResponse, GetThumbnailApiArg>({
            query: (queryArg) => ({
                url: `${queryArg.hubUrl}/workspace/orgs/${queryArg.orgId}/workspaces/${queryArg.workspaceId}/thumbnail`,
            }),
        }),
        putThumbnail: build.mutation<PutThumbnailApiResponse, PutThumbnailApiArg>({
            query: (queryArg) => ({
                url: `${queryArg.hubUrl}/workspace/orgs/${queryArg.orgId}/workspaces/${queryArg.workspaceId}/thumbnail`,
                method: 'PUT',
                body: queryArg.body,
            }),
        }),
        listUserRoles: build.query<ListUserRolesApiResponse, ListUserRolesApiArg>({
            query: (queryArg) => ({
                url: `${queryArg.hubUrl}/workspace/orgs/${queryArg.orgId}/workspaces/${queryArg.workspaceId}/users`,
                params: { filter: queryArg.filter },
            }),
        }),
        assignUserRole: build.mutation<AssignUserRoleApiResponse, AssignUserRoleApiArg>({
            query: (queryArg) => ({
                url: `${queryArg.hubUrl}/workspace/orgs/${queryArg.orgId}/workspaces/${queryArg.workspaceId}/users`,
                method: 'POST',
                body: queryArg.assignRoleRequest,
                headers: { 'preview-api': 'opt-in' },
            }),
        }),
        deleteUserRole: build.mutation<DeleteUserRoleApiResponse, DeleteUserRoleApiArg>({
            query: (queryArg) => ({
                url: `${queryArg.hubUrl}/workspace/orgs/${queryArg.orgId}/workspaces/${queryArg.workspaceId}/users/${queryArg.userId}`,
                method: 'DELETE',
            }),
        }),
    }),
    overrideExisting: false,
});
export { injectedRtkApi as workspaceClientEndpoints };
export type BulkAssignRolesAdminApiResponse = /** status 200 Successful Response */ any;
export type BulkAssignRolesAdminApiArg = {
    orgId: string;
    hubUrl: string;
    bulkUserRoleAssignmentsRequest: BulkUserRoleAssignmentsRequest;
};
export type UpdateMlEnablementAdminApiResponse =
    /** status 200 Successful Response */ MlEnablementRequest;
export type UpdateMlEnablementAdminApiArg = {
    orgId: string;
    /** Set to "opt-in" to enable adding user by email */
    'preview-api'?: string | null;
    hubUrl: string;
    mlEnablementRequest: MlEnablementRequest;
};
export type GetOrganizationSettingsApiResponse =
    /** status 200 Successful Response */ OrganizationSettingsResponse;
export type GetOrganizationSettingsApiArg = {
    orgId: string;
    hubUrl: string;
};
export type ListUserWorkspacesAdminApiResponse =
    /** status 200 Successful Response */ ListUserWorkspacesResponse;
export type ListUserWorkspacesAdminApiArg = {
    userId: string;
    orgId: string;
    /** The maximum number of results to return. */
    limit?: number | null;
    /** The (zero-based) offset of the first item returned in the collection. */
    offset?: number | null;
    /** An optional comma separated list of fields to sort the results by. Options are: `name`, `-name`, `created_at`, `-created_at`, `updated_at`, `-updated_at`, `user_role`, `-user_role`. */
    sort?: string | null;
    /** An optional, comma-separated list of fields by which to order the results. Each field could be prefixed with an order operator: `asc:` for ascending order or `desc:` for descending order, default is ascending order. The sortable fields are: `name`, `created_at`, `updated_at`, and `user_role`. */
    orderBy?: string | null;
    filter?: {
        created_by?: string | null;
        name?: string | null;
    };
    /** Filter by the time workspace has created. */
    createdAt?: string | null;
    /** Filter by the latest time workspace was updated. */
    updatedAt?: string | null;
    /** Filter by workspace name. */
    name?: string | null;
    /** Include workspaces that have been deleted. */
    deleted?: boolean | null;
    hubUrl: string;
};
export type ListWorkspacesAdminApiResponse =
    /** status 200 Successful Response */ ListWorkspacesResponse;
export type ListWorkspacesAdminApiArg = {
    orgId: string;
    /** The maximum number of results to return. */
    limit?: number | null;
    /** The (zero-based) offset of the first item returned in the collection. */
    offset?: number | null;
    /** An optional comma separated list of fields to sort the results by. Options are: `name`, `-name`, `created_at`, `-created_at`, `updated_at`, `-updated_at`, `user_role`, `-user_role`. */
    sort?: string | null;
    /** An optional, comma-separated list of fields by which to order the results. Each field could be prefixed with an order operator: `asc:` for ascending order or `desc:` for descending order, default is ascending order. The sortable fields are: `name`, `created_at`, `updated_at`, and `user_role`. */
    orderBy?: string | null;
    filter?: {
        created_by?: string | null;
        name?: string | null;
        user_id?: string | null;
    };
    /** Filter by the time workspace has created. */
    createdAt?: string | null;
    /** Filter by the latest time workspace was updated. */
    updatedAt?: string | null;
    /** Filter by workspace name. */
    name?: string | null;
    /** Include workspaces that have been deleted. */
    deleted?: boolean | null;
    hubUrl: string;
};
export type GetWorkspaceAdminApiResponse =
    /** status 200 Successful Response */ WorkspaceRoleOptionalResponse;
export type GetWorkspaceAdminApiArg = {
    workspaceId: string;
    orgId: string;
    /** Only list workspaces that have been deleted. */
    deleted?: boolean;
    hubUrl: string;
};
export type GetThumbnailAdminApiResponse = /** status 200 Successful Response */ Blob;
export type GetThumbnailAdminApiArg = {
    workspaceId: string;
    orgId: string;
    hubUrl: string;
};
export type ListUserRolesAdminApiResponse =
    /** status 200 Successful Response */ ListUserRoleResponse;
export type ListUserRolesAdminApiArg = {
    workspaceId: string;
    orgId: string;
    filter?: {
        user_id?: string | null;
    };
    hubUrl: string;
};
export type AssignUserRoleAdminApiResponse = /** status 201 Successful Response */ UserRole;
export type AssignUserRoleAdminApiArg = {
    workspaceId: string;
    orgId: string;
    /** Set to "opt-in" to enable adding user by email */
    'preview-api'?: string | null;
    hubUrl: string;
    assignRoleRequest: UserRole | UserRoleViaEmail;
};
export type DeleteUserRoleAdminApiResponse = /** status 204 Successful Response */ void;
export type DeleteUserRoleAdminApiArg = {
    workspaceId: string;
    userId: string;
    orgId: string;
    hubUrl: string;
};
export type ListCoordinateSystemsApiResponse =
    /** status 200 Successful Response */ ListCoordinateSystemsResponse;
export type ListCoordinateSystemsApiArg = {
    orgId: string;
    hubUrl: string;
};
export type ListWorkspacesApiResponse =
    /** status 200 Successful Response */ ListWorkspacesResponse;
export type ListWorkspacesApiArg = {
    orgId: string;
    /** The maximum number of results to return. */
    limit?: number | null;
    /** The (zero-based) offset of the first item returned in the collection. */
    offset?: number | null;
    /** An optional comma separated list of fields to sort the results by. Options are: `name`, `-name`, `created_at`, `-created_at`, `updated_at`, `-updated_at`, `user_role`, `-user_role`. */
    sort?: string | null;
    /** An optional, comma-separated list of fields by which to order the results. Each field could be prefixed with an order operator: `asc:` for ascending order or `desc:` for descending order, default is ascending order. The sortable fields are: `name`, `created_at`, `updated_at`, and `user_role`. */
    orderBy?: string | null;
    filter?: {
        created_by?: string | null;
        name?: string | null;
        user_id?: string | null;
    };
    /** Filter by the time workspace has created. */
    createdAt?: string | null;
    /** Filter by the latest time workspace was updated. */
    updatedAt?: string | null;
    /** Filter by workspace name. */
    name?: string | null;
    /** Include workspaces that have been deleted. */
    deleted?: boolean | null;
    hubUrl: string;
};
export type CreateWorkspaceApiResponse =
    /** status 201 Successful Response */ WorkspaceRoleRequiredResponse;
export type CreateWorkspaceApiArg = {
    orgId: string;
    hubUrl: string;
    createWorkspaceRequest: CreateWorkspaceRequest;
};
export type DeleteWorkspaceApiResponse = /** status 204 Successful Response */ void;
export type DeleteWorkspaceApiArg = {
    workspaceId: string;
    orgId: string;
    hubUrl: string;
};
export type GetWorkspaceApiResponse =
    /** status 200 Successful Response */ WorkspaceRoleRequiredResponse;
export type GetWorkspaceApiArg = {
    workspaceId: string;
    orgId: string;
    /** Only list workspaces that have been deleted. */
    deleted?: boolean;
    hubUrl: string;
};
export type UpdateWorkspaceApiResponse =
    /** status 200 Successful Response */ WorkspaceRoleRequiredResponse;
export type UpdateWorkspaceApiArg = {
    workspaceId: string;
    orgId: string;
    hubUrl: string;
    updateWorkspaceRequest: UpdateWorkspaceRequest;
};
export type RestoreSoftDeletedWorkspaceApiResponse = /** status 204 Successful Response */ void;
export type RestoreSoftDeletedWorkspaceApiArg = {
    workspaceId: string;
    orgId: string;
    deleted?: string | null;
    hubUrl: string;
};
export type GetCurrentUserRoleApiResponse = /** status 200 Successful Response */ UserRole;
export type GetCurrentUserRoleApiArg = {
    workspaceId: string;
    orgId: string;
    hubUrl: string;
};
export type DeleteWorkspaceThumbnailApiResponse = /** status 204 Successful Response */ void;
export type DeleteWorkspaceThumbnailApiArg = {
    workspaceId: string;
    orgId: string;
    hubUrl: string;
};
export type GetThumbnailApiResponse = /** status 200 Successful Response */ Blob;
export type GetThumbnailApiArg = {
    workspaceId: string;
    orgId: string;
    hubUrl: string;
};
export type PutThumbnailApiResponse = /** status 204 Successful Response */ void;
export type PutThumbnailApiArg = {
    workspaceId: string;
    orgId: string;
    hubUrl: string;
    body: Blob;
};
export type ListUserRolesApiResponse = /** status 200 Successful Response */ ListUserRoleResponse;
export type ListUserRolesApiArg = {
    workspaceId: string;
    orgId: string;
    filter?: {
        user_id?: string | null;
    };
    hubUrl: string;
};
export type AssignUserRoleApiResponse = /** status 201 Successful Response */ UserRole;
export type AssignUserRoleApiArg = {
    workspaceId: string;
    orgId: string;
    /** Set to "opt-in" to enable adding user by email */
    'preview-api'?: string | null;
    hubUrl: string;
    assignRoleRequest: UserRole | UserRoleViaEmail;
};
export type DeleteUserRoleApiResponse = /** status 204 Successful Response */ void;
export type DeleteUserRoleApiArg = {
    workspaceId: string;
    userId: string;
    orgId: string;
    hubUrl: string;
};
export type ErrorInvalidParam = {
    name: string;
    reason: string;
};
export type ErrorResponse = {
    detail: string | null;
    'invalid-params'?: ErrorInvalidParam[] | null;
    status: number;
    title: string;
    type: string;
};
export type RoleEnum = 'owner' | 'editor' | 'viewer';
export type UserRoleAssignmentRequest = {
    role: RoleEnum;
    user_id: string;
    workspace_id: string;
};
export type BulkUserRoleAssignmentsRequest = {
    role_assignments: UserRoleAssignmentRequest[];
};
export type WorkspaceMlEnablementRequest = {
    ml_enabled: boolean;
    workspace_id: string;
};
export type MlEnablementRequest = {
    ml_enablements: WorkspaceMlEnablementRequest[];
};
export type OrganizationSettingsFieldResponse = {
    ml_enabled?: boolean;
};
export type OrganizationSettingsResponse = {
    created_at?: string | null;
    created_by?: string | null;
    id: string;
    settings?: OrganizationSettingsFieldResponse;
    updated_at?: string | null;
    updated_by?: string | null;
};
export type PaginationLinks = {
    count: number;
    first: string;
    last: string;
    next?: string | null;
    previous?: string | null;
    total: number;
};
export type GeometryTypeEnum = 'Polygon';
export type BoundingBox = {
    coordinates: (number | number)[][][];
    type: GeometryTypeEnum;
};
export type UserModel = {
    email?: string | null;
    id: string;
    name?: string | null;
};
export type UserWorkspaceResponse = {
    bounding_box?: BoundingBox | null;
    created_at: string;
    created_by: UserModel;
    default_coordinate_system?: string;
    description?: string;
    id: string;
    labels?: string[];
    ml_enabled?: boolean;
    name: string;
    self_link: string;
    updated_at: string;
    updated_by: UserModel;
    user_role: RoleEnum;
};
export type ListUserWorkspacesResponse = {
    links: PaginationLinks;
    results: UserWorkspaceResponse[];
};
export type WorkspaceRoleRequiredResponse = {
    bounding_box?: BoundingBox | null;
    created_at: string;
    created_by: UserModel;
    current_user_role: RoleEnum;
    default_coordinate_system?: string;
    description?: string;
    id: string;
    labels?: string[];
    ml_enabled?: boolean;
    name: string;
    self_link: string;
    updated_at: string;
    updated_by: UserModel;
};
export type WorkspaceRoleOptionalResponse = {
    bounding_box?: BoundingBox | null;
    created_at: string;
    created_by: UserModel;
    current_user_role?: RoleEnum | null;
    default_coordinate_system?: string;
    description?: string;
    id: string;
    labels?: string[];
    ml_enabled?: boolean;
    name: string;
    self_link: string;
    updated_at: string;
    updated_by: UserModel;
};
export type ListWorkspacesResponse = {
    links: PaginationLinks;
    results: WorkspaceRoleRequiredResponse[] | WorkspaceRoleOptionalResponse[];
};
export type User = {
    email?: string | null;
    full_name?: string | null;
    role: RoleEnum;
    user_id: string;
};
export type ListUserRoleResponse = {
    links: {
        [key: string]: string | null;
    };
    results: User[];
};
export type UserRole = {
    role: RoleEnum;
    user_id: string;
};
export type UserRoleViaEmail = {
    email: string;
    role: RoleEnum;
};
export type CoordinateSystemEntry = {
    title: string;
    well_known_id: string;
};
export type CoordinateSystemCategory = {
    items: (CoordinateSystemCategory | CoordinateSystemEntry)[];
    title: string;
};
export type ListCoordinateSystemsResponse = {
    links: {
        [key: string]: string | null;
    };
    results: CoordinateSystemCategory[];
};
export type CreateWorkspaceRequest = {
    bounding_box?: BoundingBox | null;
    default_coordinate_system?: string;
    description?: string;
    labels?: string[] | null;
    name: string;
};
export type UpdateWorkspaceRequest = {
    bounding_box?: BoundingBox | null;
    default_coordinate_system?: string | null;
    description?: string | null;
    labels?: string[] | null;
    name?: string | null;
};
export const {
    useBulkAssignRolesAdminMutation,
    useUpdateMlEnablementAdminMutation,
    useGetOrganizationSettingsQuery,
    useLazyGetOrganizationSettingsQuery,
    useListUserWorkspacesAdminQuery,
    useLazyListUserWorkspacesAdminQuery,
    useListWorkspacesAdminQuery,
    useLazyListWorkspacesAdminQuery,
    useGetWorkspaceAdminQuery,
    useLazyGetWorkspaceAdminQuery,
    useGetThumbnailAdminQuery,
    useLazyGetThumbnailAdminQuery,
    useListUserRolesAdminQuery,
    useLazyListUserRolesAdminQuery,
    useAssignUserRoleAdminMutation,
    useDeleteUserRoleAdminMutation,
    useListCoordinateSystemsQuery,
    useLazyListCoordinateSystemsQuery,
    useListWorkspacesQuery,
    useLazyListWorkspacesQuery,
    useCreateWorkspaceMutation,
    useDeleteWorkspaceMutation,
    useGetWorkspaceQuery,
    useLazyGetWorkspaceQuery,
    useUpdateWorkspaceMutation,
    useRestoreSoftDeletedWorkspaceMutation,
    useGetCurrentUserRoleQuery,
    useLazyGetCurrentUserRoleQuery,
    useDeleteWorkspaceThumbnailMutation,
    useGetThumbnailQuery,
    useLazyGetThumbnailQuery,
    usePutThumbnailMutation,
    useListUserRolesQuery,
    useLazyListUserRolesQuery,
    useAssignUserRoleMutation,
    useDeleteUserRoleMutation,
} = injectedRtkApi;

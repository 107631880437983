import type { ListedObject } from '@api/goose/dist/enhancedGooseClient';
import { trackError } from '@local/metrics';
import { toSuffixUid } from '@local/webviz/dist/xyz';
import capitalize from 'lodash-es/capitalize';

import { fileNameExtensionRemover } from 'src/pages/workspacePage/workspaceContent/utils';
import type { store } from 'src/store/store';
import {
    addTreeItemChildren,
    addTreeItems,
    addTreeItemsChildren,
    updateTree,
} from 'src/store/visualization/visualizationSlice';
import type {
    TreeItemChildrenPayload,
    TreeStructure,
} from 'src/store/visualization/visualizationSlice.types';
import { ROOT_TREE_ID } from 'src/strings';
import { extractSchema } from 'src/utils/schemaUtils';
import { Schemas } from 'src/visualization/constants';
import type {
    DownholeCollectionType,
    GeologicalModelMeshesType,
    Item,
} from 'src/visualization/types';

import { INTERVAL, POINTSET } from '../ObjectsPanel/ProjectTree/ProjectTree.constants';

function extractFolderChildren(items: Item[], treeId: string) {
    return items
        .filter((item) => !item.items || item.items.length)
        .map((item) => toSuffixUid(treeId, item.name ?? ''));
}

function flushDictionary(
    treeStateUpdate: { [key: string]: TreeStructure },
    dispatch: typeof store.dispatch,
) {
    dispatch(
        updateTree({
            treeDictionary: { ...treeStateUpdate },
        }),
    );
}

export function processDownholeCollection(
    downholeCollection: DownholeCollectionType,
    dispatch: typeof store.dispatch,
) {
    if (!downholeCollection) {
        return;
    }

    const treeStateUpdate: { [key: string]: TreeStructure } = {};

    const folderChildren: string[] = [];
    const { location, name: downholeName, collections } = downholeCollection;
    const treeId = downholeCollection.uuid;
    if (!treeId) {
        trackError('Downhole collection does not have a uuid');
        return;
    }
    if (location) {
        const folderId = toSuffixUid(treeId, POINTSET);

        treeStateUpdate[folderId] = {
            treeId: folderId,
            name: `${fileNameExtensionRemover(downholeName)} ${capitalize(POINTSET)}`,
            parentId: treeId,
            schema: Schemas.PointsetSchema,
        };

        folderChildren.push(folderId);
    }

    collections.forEach(({ name, collection_type }) => {
        const isCollectionInterval = collection_type === INTERVAL;
        const folderId = toSuffixUid(treeId, name);
        if (isCollectionInterval) {
            treeStateUpdate[folderId] = {
                treeId: folderId,
                name,
                parentId: treeId,
                schema: Schemas.DownholeIntervalsSchema,
            };
        } else {
            // eslint-disable-next-line no-console
            console.warn(`Unsupported collection type: ${collection_type}`);
            return;
        }
        folderChildren.push(folderId);
    });

    if (folderChildren.length) {
        dispatch(addTreeItemChildren({ treeId, children: folderChildren }));
    }
    flushDictionary(treeStateUpdate, dispatch);
}

export function processGeologicalModelMesh(
    geologicalModelMesh: GeologicalModelMeshesType,
    dispatch: typeof store.dispatch,
) {
    const treeStateUpdate: { [key: string]: TreeStructure } = {};

    function processFolders(parentItems: Item[], parentId: string) {
        const folderItems: TreeStructure[] = [];
        const folderChildrenPayloads: TreeItemChildrenPayload[] = [];

        parentItems.forEach((parentItem) => {
            const { items, name: parentItemName } = parentItem;
            const name = parentItemName ?? '';
            const itemTreeId = toSuffixUid(parentId, name);
            const isFolder = items?.length;

            if (isFolder) {
                const folderItem = {
                    treeId: itemTreeId,
                    name,
                    parentId,
                    schema: '' as Schemas,
                    children: extractFolderChildren(items, itemTreeId),
                };
                folderItems.push(folderItem);
                folderChildrenPayloads.push({ treeId: parentId, children: [itemTreeId] });
                processFolders(items, itemTreeId);
            } else {
                treeStateUpdate[itemTreeId] = {
                    treeId: itemTreeId,
                    name,
                    parentId,
                    isLegendShown: false,
                    schema: Schemas.GeologicalModelMeshesSchema,
                };
            }
        });

        if (folderItems.length) {
            dispatch(addTreeItems(folderItems));
        }
        if (folderChildrenPayloads.length) {
            dispatch(addTreeItemsChildren(folderChildrenPayloads));
        }
    }

    const { uuid: treeId } = geologicalModelMesh;
    if (!treeId) {
        trackError('Geological Model Mesh does not have a uuid');
        return;
    }

    if (geologicalModelMesh?.folders.length && geologicalModelMesh?.folders[0]?.items) {
        processFolders(geologicalModelMesh.folders[0].items, treeId);
    } else {
        // eslint-disable-next-line no-console
        console.warn(`Geological Model Mesh does not have items`);
        return;
    }
    flushDictionary(treeStateUpdate, dispatch);
}

interface GenerateProjectTreeProps {
    objects: ListedObject[];
    enqueue: (objectToLoad: string) => void;
    dispatch: typeof store.dispatch;
}
export function generateProjectTree({ objects, enqueue, dispatch }: GenerateProjectTreeProps) {
    if (!objects.length) {
        return;
    }

    let treeStateUpdate: { [key: string]: TreeStructure } = {};
    processObjects();

    function addChildrenForTopLevelFolders(objectList: ListedObject[]) {
        objectList.forEach(({ object_id, name, schema: fullSchema }) => {
            const schema = extractSchema(fullSchema);
            switch (schema) {
                case Schemas.GeologicalModelMeshesSchema:
                case Schemas.DownholeCollectionSchema:
                    treeStateUpdate[object_id] = {
                        treeId: object_id,
                        name: fileNameExtensionRemover(name),
                        parentId: ROOT_TREE_ID,
                        schema,
                        isLegendShown: false,
                        children: [],
                    };
                    enqueue(object_id);
                    break;
                default:
                    treeStateUpdate[object_id] = {
                        treeId: object_id,
                        name: fileNameExtensionRemover(name),
                        parentId: ROOT_TREE_ID,
                        schema,
                        isLegendShown: false,
                    };
            }
        });
    }

    function addTopLevelFolders(rootLevelChildren: string[]) {
        treeStateUpdate[ROOT_TREE_ID] = {
            treeId: ROOT_TREE_ID,
            name: ROOT_TREE_ID,
            parentId: '',
            schema: '' as Schemas,
            children: rootLevelChildren,
        };
    }

    function processObjects() {
        treeStateUpdate = {};
        const allObjectIds = objects.map((object) => object.object_id);
        addTopLevelFolders(allObjectIds);
        addChildrenForTopLevelFolders(objects);
        flushDictionary(treeStateUpdate, dispatch);
    }
}

import type { GeoscienceObject } from '@api/goose/dist/enhancedGooseClient';
import type { LDFlagSet } from 'launchdarkly-js-sdk-common';

import {
    SUPPORTED_SCHEMAS,
    DRIVER_SUPPORTED_SCHEMAS,
    BLOCKSYNC_SUPPORTED_SCHEMAS,
    BLOCKSYNC_SUPPORTED_MODEL_TYPES,
} from 'src/utils/supportedTypes';
import { Schemas } from 'src/visualization/constants';

export const extractSchema = (fullSchema: string) => {
    if (!fullSchema) {
        return '' as Schemas;
    }

    return fullSchema.substring(fullSchema.lastIndexOf('/') + 1).toLowerCase() as Schemas;
};

export function isObjectViewable(object: GeoscienceObject | undefined, flags: LDFlagSet) {
    if (!object) {
        return false;
    }
    const schema = extractSchema(object.schema);
    if (!schema) {
        return false;
    }
    const localSupportedSchemas = [...SUPPORTED_SCHEMAS];
    const {
        evouiRegularGridRendering,
        evouiGmmRendering,
        evouiRegularBmRendering,
        evouiStructuralDiskRenderin,
    } = flags;
    if (evouiRegularGridRendering) {
        localSupportedSchemas.push(Schemas.Regular2DGridSchema);
    }
    if (evouiGmmRendering) {
        localSupportedSchemas.push(Schemas.GeologicalModelMeshesSchema);
    }
    if (
        evouiRegularBmRendering &&
        BLOCKSYNC_SUPPORTED_MODEL_TYPES.includes(object.geometry?.model_type)
    ) {
        localSupportedSchemas.push(Schemas.BlockSyncReferenceSchema);
    }
    if (evouiStructuralDiskRenderin) {
        localSupportedSchemas.push(Schemas.PlanarDataPointsetSchema);
    }
    return localSupportedSchemas.includes(schema);
}

export function isObjectDriverViewable(fullSchema: string) {
    const schema = extractSchema(fullSchema);
    return DRIVER_SUPPORTED_SCHEMAS.includes(schema);
}

export function isObjectBlockSyncViewable(fullSchema: string) {
    const schema = extractSchema(fullSchema);
    return BLOCKSYNC_SUPPORTED_SCHEMAS.includes(schema);
}
